$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_booking_table' && a === 'list') {

            $(document).on("click", ".booking-table-delete-button", function () {
                let bookingTableId = $(this).attr('data-booking-table-id');
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_table/delete",
                    data: {
                        booking_table_id: bookingTableId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-table-list-sortable').empty();
                            $('#booking-table-list-sortable').append(data.html);
                            popupAlert();
                        }

                        if (data.has_deactivated_booking_tables !== undefined && data.has_deactivated_booking_tables !== null && data.has_deactivated_booking_tables == true) {
                            $('#show-deactivated-booking-table-button').removeClass('d-none');
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);