$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_promo_code' && a == 'list') {
            $(document).on("click", "#show-deactivated-promo-code-button", function () {
                $('#deactivated-promo-codes').removeClass('d-none');
                $(this).remove();
            });
        }

    });