$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "organizer_product_category_self_service" && a === "list") {
    $(document).on(
      "click",
      "#show-deactivated-product-category-button",
      function () {
        if (
          !$("#show-deactivated-product-category-button").hasClass("d-none")
        ) {
          $("#show-deactivated-product-category-button").addClass("d-none");
        }
        if ($("#deactivated-product-category").hasClass("d-none")) {
          $("#deactivated-product-category").removeClass("d-none");
        }

        let productCategory = document.getElementById(
          "product-category-list-sortable"
        );
        Sortable.create(productCategory, {
          handle: ".product-category-reorder",
          animation: 150,
          sort: true,
          delay: 0,
          touchStartThreshold: 0,
          disabled: false,
          draggable: ".product-category",
          direction: "horizontal",
          ghostClass: "sortable-ghost",
          dataIdAttr: "data-product-category-id",
          removeCloneOnHide: true,
          store: {
            set: function (sortable) {
              let order = sortable.toArray();
              ajaxListOrder(order);
            },
          },
        });
      }
    );
  }
});
