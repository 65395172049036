$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_slot_settings' && a == 'list') {


            let booking_slot_settings_new_ajax_lock = false;

            $(document).on("submit", "#booking-slot-settings-registration-form", function () {

                if (!booking_slot_settings_new_ajax_lock) {

                    booking_slot_settings_new_ajax_lock = true;

                    let url;
                    if ($("#booking-slot-settings-registration-form input[name=booking_slot_settings_id]").length) {
                        url = "/ajax/organizer_booking_slot_settings/update";
                    } else {
                        url = "/ajax/organizer_booking_slot_settings/new";
                    }
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#booking-slot-settings-list').empty();
                                $('#booking-slot-settings-list').append(data.html);
                                $("#booking-slot-settings-registration-form-modal").modal('hide');
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#booking-slot-settings-registration-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-slot-settings-registration-form')
                            }

                            booking_slot_settings_new_ajax_lock = false;

                        })
                        .always(function () {
                            booking_slot_settings_new_ajax_lock = false;
                        });

                }

                return false;

            });


        }

    }
);