$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'events') {
            $(document).on("click", "#location-information-modal-button", function () {
                if ($("#location-information-modal").length) {
                    $("#location-information-modal").remove();
                }
                let locationId = $(this).attr('data-location-id');
                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/get_modal",
                    data: {
                        location_id: locationId
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $("#event-public-list-organizer").after(data.html);
                            $("#location-information-modal").modal('show');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            });
        }
    });