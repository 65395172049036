$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_area' && a == 'list') {

            let booking_area_modal_lock = false;

            $(document).on("click", "#booking-area-new-button", function () {
                let locationId = $("body").attr("event_id");
                getBookingAreaModal(locationId);
            });

            $(document).on("click", ".booking-area-update-button", function () {
                let locationId = $("body").attr("event_id");
                let bookingAreaId = $(this).attr("data-booking-area-id");
                getBookingAreaModal(locationId, bookingAreaId);
            });

            function getBookingAreaModal(locationId, bookingAreaId = null) {

                if ($('#booking-area-registration-form-modal').length) {
                    $('#booking-area-registration-form-modal').remove();
                }

                if (!booking_area_modal_lock) {
                    booking_area_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_booking_area/get_modal",
                        data: {
                            location_id: locationId,
                            booking_area_id: bookingAreaId
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#content').append(data.html);
                                $('#booking-area-registration-form-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            booking_area_modal_lock = false;

                        })
                        .always(function () {
                            booking_area_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);