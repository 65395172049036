$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_event_ticket_category' && a == 'new') {

            function addMaxPurchaseQty() {
                if ($('#max_purchase_qty').val() == '' && typeof ($('#max_tickets').val() * 1) == 'number' && ($('#max_tickets').val() * 1) > 0) {
                    let maxPurchaseQty = $('#max_purchase_qty').attr('max') * 1;
                    if (typeof maxPurchaseQty == 'number' && $('#max_tickets').val() >= maxPurchaseQty) {
                        $('#max_purchase_qty').val(maxPurchaseQty);
                    } else {
                        $('#max_purchase_qty').val($('#max_tickets').val());
                    }
                }
            }

            $(document).on("focusout ", '#max_tickets', function () {
                addMaxPurchaseQty();
            });

            $(document.body).on('click', ".event-ticket-category-area-zone", function () {
                let max_tickets = 0;
                $('.event-ticket-category-area-zone').each(function () {
                    if ($(this).prop("checked") == true) {
                        max_tickets += $(this).attr('data-people-limit') * 1;
                    }
                });
                $('#max_tickets').val(max_tickets);
                addMaxPurchaseQty();
            });

        }


    });