$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_slot_settings' && a == 'list') {

            let booking_slot_settings_modal_lock = false;

            $(document).on("click", ".booking-slot-settings-new-button", function () {
                let locationId = $("body").attr("event_id");
                let slotType = $(this).attr("data-slot-type");
                getBookingSlotSettingsModal(locationId, null, slotType);
            });

            $(document).on("click", ".booking-slot-settings-update-button", function () {
                let locationId = $("body").attr("event_id");
                let bookingSlotSettingsId = $(this).attr("data-booking-slot-settings-id");
                getBookingSlotSettingsModal(locationId, bookingSlotSettingsId);
            });

            function getBookingSlotSettingsModal(locationId, bookingSlotSettingsId = null, slotType = null) {

                if ($('#booking-slot-settings-registration-form-modal').length) {
                    $('#booking-slot-settings-registration-form-modal').remove();
                }

                if (!booking_slot_settings_modal_lock) {
                    booking_slot_settings_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_booking_slot_settings/get_modal",
                        data: {
                            location_id: locationId,
                            booking_slot_settings_id: bookingSlotSettingsId,
                            slot_type: slotType
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#content').append(data.html);
                                $('#booking-slot-settings-registration-form-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            booking_slot_settings_modal_lock = false;

                        })
                        .always(function () {
                            booking_slot_settings_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);