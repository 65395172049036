$(document).ready(function () {
  let p = $("body").attr("p");
  if (p === "organizer") {
    $("select[name=city_id]").select2({
      theme: "bootstrap4",
    });
  }

  if (p === "organizer_settings_users") {
    $(".selectpicker").selectpicker({
      countSelectedText: function (numSelected, numTotal) {
        return numSelected + " " + "moduler";
      },
    });
  }
});
