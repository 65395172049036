$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_extra_template' && a == 'list') {

            let product_extra_template_new_ajax_lock = false;

            $(document).on("submit", "#product-extra-template-registration-form", function () {

                if (!product_extra_template_new_ajax_lock) {

                    product_extra_template_new_ajax_lock = true;

                    let url;
                    if ($("#product-extra-template-registration-form input[name=product_extra_template_id]").length) {
                        url = "/ajax/organizer_product_extra_template/update";
                    } else {
                        url = "/ajax/organizer_product_extra_template/new";
                    }
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#product-extra-template-list').empty();
                                $('#product-extra-template-list').append(data.html);
                                if ($('#show-deactivated-product-extra-template-button').hasClass('d-none')) {
                                    $('#show-deactivated-product-extra-template-button').removeClass('d-none');
                                }
                                $("#product-extra-template-registration-form-modal").modal('hide');
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#product-extra-template-registration-button-wrapper', data.responseJSON.error_message, 'danger', null, '#product-extra-template-registration-form')
                            }

                            product_extra_template_new_ajax_lock = false;

                        })
                        .always(function () {
                            product_extra_template_new_ajax_lock = false;
                        });

                }

                return false;

            });


        }

    }
);