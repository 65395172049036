$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers') {


            $(document).on("click", "#alert-lightbulb-button", function () {
                let location_id = $(this).attr("data-location-id");
                getNewTakeAwayOrders(location_id)
            });

            function getNewTakeAwayOrders(location_id) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/get_takeaway_orders_in_next_30_minutes",
                    data: {
                        location_id
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#alert-lightbulb-button').attr('data-content', data.html);
                            $('#alert-lightbulb-button').popover('show');
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })
            }

        }

    }
);

