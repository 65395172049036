$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  const status = $("body").attr("status");
  let url = "";
  if (p === "top_product_group" || p === "deactivated_top_product_group") {
    //new
    $(document).on("submit", "#create-form", function (e) {
      e.preventDefault();

      const formData = new FormData($(this)[0]);
      formData.append("location_id", locationId);
      url = "/ajax/top_product_group/new";
      saveTopProductGroup(formData, url);
    });

    //update
    $(document).on("submit", "#update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("location_id", locationId);
      formData.append("status", status);
      url = "/ajax/top_product_group/update";
      saveTopProductGroup(formData, url);
    });

    //delete
    $(document).on("click", ".top-product-group-delete-btn", function (e) {
      e.preventDefault();
      let idToDelete = $(this).attr("data-delete-id");

      $("#delete-top-product-group-confirm").attr("data-delete-id", idToDelete);
    });

    $(document).on("click", "#delete-top-product-group-confirm", function (e) {
      e.preventDefault();
      const topProductGroupId = $(this).attr("data-delete-id");
      url = "/ajax/top_product_group/delete";
      const formData = new FormData();
      formData.append("status", 0);
      formData.append("top_product_group_id", topProductGroupId);
      formData.append("location_id", locationId);
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.alert) {
            alert(
              "Kunne ikke slette Varegruppe . Varegruppe  ligger mest sannsynlig i en Vare"
            );

            return;
          }
          if (
            data.message !== undefined &&
            data.message !== null &&
            !data.alert
          ) {
            prependAlert("#message", data.message, "success", null);
            $("#top-product-group-list-view").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (data && data["message"] && !data["alert"]) {
              $("tr#top-product-group-" + topProductGroupId).fadeOut(1000);
              setTimeout(function () {
                $("tr#top-product-group-" + topProductGroupId).remove();
              }, 1000);
              popupAlert();
            }

            if (data && data["message"] && data["alert"]) {
              alert(data["message"]);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-update-form"
            );
          }
        });
      $(".modal").modal("hide");
    });

    function saveTopProductGroup(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#create-form").hide();
            $("#update-form").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (p === "deactivated_top_product_group") {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/top_product_group/deactivated";
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/top_product_group";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-registration-form"
            );
          }
        });
    }

    // unpublish
    $(document).on("click", ".top-product-group-unpublish", function (e) {
      e.preventDefault();
      let topProductGroupId = $(this).attr("data-top-product-group-id");
      let formData = new FormData();
      let page = $(this).attr("data-page");
      formData.append("top_product_group_id", topProductGroupId);
      formData.append("location_id", locationId);
      formData.append("status", 2);
      formData.append("page", page);

      $.ajax({
        type: "POST",
        url: "/ajax/top_product_group/unpublish",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data && data["message"] && !data["alert"]) {
            $("tr#top-product-group-" + topProductGroupId).fadeOut(1000);
            setTimeout(function () {
              $("tr#top-product-group-" + topProductGroupId).remove();
            }, 1000);
            popupAlert();
          }

          if (data && data["message"] && data["alert"]) {
            alert(data["message"]);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", ".top-product-group-publish", function (e) {
      e.preventDefault();
      let topProductGroupId = $(this).attr("data-top-product-group-id");
      let formData = new FormData();
      let page = $(this).attr("data-page");
      formData.append("top_product_group_id", topProductGroupId);
      formData.append("location_id", locationId);
      formData.append("status", 1);
      formData.append("page", page);

      $.ajax({
        type: "POST",
        url: "/ajax/top_product_group/unpublish",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data && data["message"] && !data["alert"]) {
            $("tr#top-product-group-" + topProductGroupId).fadeOut(1000);
            setTimeout(function () {
              $("tr#top-product-group-" + topProductGroupId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
