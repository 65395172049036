$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_group_link' && a === 'list') {

            $('select[name=product_id]').select2({
                theme: 'bootstrap4',
            });

        }

    }
);