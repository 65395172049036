$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_group_link' && a === 'list') {

            let product_group_link_register_ajax_lock = false;

            $(document).on("click", "#product-group-link-register-button", function () {
                let productGroupId = $(this).attr('data-product-group-id');
                let productId = $("select[name='product_id'] option:selected").val();
                let locationId = $("body").attr("event_id");

                if (!product_group_link_register_ajax_lock) {

                    product_group_link_register_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_group_link/new",
                        data: {
                            product_group_id: productGroupId,
                            product_id: productId,
                            location_id: locationId
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                $("select[name='product_id'] option:selected").remove();
                                $('#product-group-link-list-sortable').empty();
                                $('#product-group-link-list-sortable').append(data.html);
                                popupAlert();
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            product_group_link_register_ajax_lock = false;

                        }).always(function () {
                        product_group_link_register_ajax_lock = false;
                    });

                }

            });


        }

    }
);