$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const location_id = $("body").attr("event_id");

  if (p === "customer_group" && a === "list") {
    let order_by = "name";
    let order_by_direction = "asc";

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();

      let page = parseInt($(this).attr("href").substring(6));
      let search_string = $("#search_string").val();

      getList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        "customer-group"
      );
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      let page = $("#customer-group-table").attr("data-current-page");

      let search_string = $("#search_string").val();
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");

      getList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        "customer-group"
      );
    });
  }

  function getList(
    location_id = null,
    page = 1,
    order_by,
    order_by_direction,
    search_string = "",
    type = ""
  ) {
    $.ajax({
      type: "POST",
      url: "/ajax/customer_group/search",
      data: {
        location_id,
        page,
        search_string,
        order_by,
        order_by_direction,
        type,
      },
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          $("#customer-group-list-container").empty();
          $("#customer-group-list-container").append(data.html);
        }

        $("html,body").scrollTop(0);
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }
});
