$(document).ready(
    function () {

        let organizer_employer_token_ajax_lock = false;
        let choose_employer_token_ajax_lock = false;

        $(document).on("click", "#employer-token-button", function () {
            let locationId = $(this).attr('data-location-id');
            if (!organizer_employer_token_ajax_lock) {
                organizer_employer_token_ajax_lock = true;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings/get_employer_token_modal",
                    data: {
                        location_id: locationId
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#content').append(data.html);
                            $('#modal-employer-token').modal('show');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        organizer_employer_token_ajax_lock = false;
                    })
                    .always(function () {
                        organizer_employer_token_ajax_lock = false;
                    });
            }
            return false;
        });


        $(document).on("click", "#choose-employer-for-employer-generation-button", function () {
            $('#modal-employer-token .alert-danger').remove();
            let locationId = $(this).attr('data-location-id');
            let userId = $("#modal-employer-token select[name='user_id'] option:selected").val();
            if (!choose_employer_token_ajax_lock) {
                choose_employer_token_ajax_lock = true;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_settings/get_employer_token",
                    data: {
                        user_id: userId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.employer_token !== undefined && data.employer_token !== null) {
                            $('.employer-token-text').removeClass('d-none');
                            $('#modal-employer-token .alert').removeClass('d-none');
                            $('#modal-employer-token .alert').append(data.employer_token);
                            $('.employer-token-choose-employer').remove();
                            $('#choose-employer-for-employer-generation-button-wrapper').remove();
                            $("#modal-employer-token select[name='user_id']").remove();
                            $('#employer-token-url-wrapper').removeClass('d-none');
                            $('a#employer-token-url').attr('href',data.employer_token);
                        }
                        if (data.employer_token_qr_code_image_url !== undefined && data.employer_token_qr_code_image_url !== null) {
                            $('#employer-token-qr-code-image-wrapper').removeClass('d-none');
                            $('img#employer-token-qr-code-image').attr('src',data.employer_token_qr_code_image_url);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#choose-employer-for-employer-generation-button-wrapper', data.responseJSON.error_message, 'danger', null, '#modal-employer-token')
                        }
                        choose_employer_token_ajax_lock = false;
                    })
                    .always(function () {
                        choose_employer_token_ajax_lock = false;
                    });

            }

            return false;
        });


    }
);