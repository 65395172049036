$(document).ready(function () {
  $(document).on("submit", "#user_login_form", function () {
    $.ajax({
      type: "POST",
      url: "/ajax/user/login",
      data: {
        serialized_post:
          $(this).serialize() + "&is_android=" + !!window.ReactNativeWebView,
      },
    })
      .done(function (data) {
        if (data.isAdmin === true) {
          document.location.href = "/admin/dashboard";
        } else if (data.isOrganizer === true) {
          document.location.href = "/settings";
        } else if (data.isSeller === true) {
          document.location.href = "/seller/organizers";
        } else if (data.isDealer === true) {
          document.location.href = "/dealer/organizers";
        } else if (data.isEmployer === true) {
          document.location.href = data.employer_redirect_url;
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.error_message !== undefined &&
          data.responseJSON.error_message !== null
        ) {
          prependAlert(
            "#div_user_login_button",
            data.responseJSON.error_message,
            "danger",
            null,
            "#user_login_form"
          );
        }
      });

    return false;
  });
});
