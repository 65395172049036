$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'organizer_settings') {

            $(document).on("click", ".delete-event-image", function () {

                let location_id = $(this).attr("data-location-id");
                let confirm_message = $(this).data("confirm-message");

                if (!confirm(confirm_message)) {
                    return false;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/delete_image",
                    data: {
                        event_id: location_id
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            popupAlert();
                            setTimeout(function () {
                                document.location.href = "/settings";
                            }, 1500);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

                return false;

            });

        }

    }
);