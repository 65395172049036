$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_extra' && a === 'list') {

            if ($("#product-extra-list-sortable").length) {

                let productExtra = document.getElementById("product-extra-list-sortable");

                Sortable.create(productExtra, {
                    handle: ".product-extra-reorder",
                    animation: 150,
                    sort: true,
                    delay: 0,
                    touchStartThreshold: 0,
                    disabled: false,
                    draggable: ".product-extra",
                    direction: "horizontal",
                    ghostClass: "sortable-ghost",
                    dataIdAttr: "data-product-extra-id",
                    removeCloneOnHide: true,
                    store: {
                        set: function (sortable) {
                            let order = sortable.toArray();
                            ajaxListOrder(order)
                        }
                    }
                });

            }

            function ajaxListOrder($order) {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra/list_sort",
                    data: {
                        serialized_post: JSON.stringify($order)
                    }
                })
                    .done(function (data) {

                        if (data === true) {
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

            }

        }

    });