$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && (a === 'new' || a === 'update')) {

            let isAltPriceInclVat = false;
            let altPriceExVat = parseFloat($('#alt_price_ex_vat').val());
            let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
            if (!isNaN(altPriceExVat)) {
                calculateAltPriceInclVat(altPriceExVat, altVatPercent);
            }

            $(document).on("change", "#alt_price_ex_vat", function () {
                isAltPriceInclVat = false;
                let altPriceExVat = $(this).val().replace(",", ".");
                altPriceExVat = parseFloat(altPriceExVat);
                let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
                calculateAltPriceInclVat(altPriceExVat, altVatPercent);
                addPriceExVat(altPriceExVat);
            });

            $(document).on("change", "#alt_price_incl_vat", function () {
                isAltPriceInclVat = true;
                let altPriceInclVat = $(this).val().replace(",", ".");
                altPriceInclVat = parseFloat(altPriceInclVat);
                let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
                calculateAltPriceExVat(altPriceInclVat, altVatPercent);
                addPriceInclVat(altPriceInclVat);
            });

            $(document).on("change", "#alt_vat_code", function () {
                let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
                if (isAltPriceInclVat) {
                    let altPriceInclVat = $('#alt_price_incl_vat').val().replace(",", ".");
                    altPriceInclVat = parseFloat(altPriceInclVat);
                    calculateAltPriceExVat(altPriceInclVat, altVatPercent);
                } else {
                    let altPriceExVat = $('#alt_price_ex_vat').val().replace(",", ".");
                    altPriceExVat = parseFloat(altPriceExVat);
                    calculateAltPriceInclVat(altPriceExVat, altVatPercent);
                }
            });

            function calculateAltPriceExVat(altPriceInclVat, altVatPercent) {
                let altPriceExVat = altPriceInclVat / (1 + (altVatPercent / 100));
                $('#alt_price_ex_vat').val(altPriceExVat.toFixed(2));
            }

            function calculateAltPriceInclVat(altPriceExVat, altVatPercent) {
                let altPriceInclVat = altPriceExVat * (1 + (altVatPercent / 100));
                $('#alt_price_incl_vat').val(altPriceInclVat.toFixed(2));
            }

            function addPriceExVat(altPriceExVat) {
                let priceExVat = parseFloat($('#ticket_price_ex_vat').val());
                let vatPercent = parseInt($('#vat_code option:selected').text());
                if (isNaN(priceExVat)) {
                    $('#ticket_price_ex_vat').val(altPriceExVat.toFixed(2));
                    let priceInclVat = altPriceExVat * (1 + (vatPercent / 100));
                    $('#ticket_price_incl_vat').val(priceInclVat.toFixed(2));
                }
            }

            function addPriceInclVat(altPriceInclVat) {
                let priceInclVat = parseFloat($('#ticket_price_incl_vat').val());
                let vatPercent = parseInt($('#vat_code option:selected').text());
                if (isNaN(priceInclVat)) {
                    $('#ticket_price_incl_vat').val(altPriceInclVat.toFixed(2));
                    let priceExVat = altPriceInclVat / (1 + (vatPercent / 100));
                    $('#ticket_price_ex_vat').val(priceExVat.toFixed(2));
                }
            }

        }

    });