$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_settings') {

            let opening_hours_exception_delete_ajax_lock = false;

            $(document).on("click", ".location-opening-hours-exceptions-delete-button", function () {
                $('.alert').remove();
                let locationOpeningHoursExceptionId = $(this).attr('data-location-opening-hours-exceptions-id');

                if (!opening_hours_exception_delete_ajax_lock) {
                    opening_hours_exception_delete_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/opening_hours_execution/delete",
                        data: {
                            location_opening_hours_exception_id: locationOpeningHoursExceptionId,
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#location-opening-hours-exception-list').empty();
                                $('#location-opening-hours-exception-list').append(data.html);
                            }
                            opening_hours_exception_delete_ajax_lock = false;
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            opening_hours_exception_delete_ajax_lock = false;
                        })

                    return false;

                }

            });


        }

    });