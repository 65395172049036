let orderStorageIds = null;

$(document).ready(function () {
  const body = $("body");
  const p = body.attr("p");
  let posType = $("#location-products").data("pos-type");

  if (p === "events" || p === "events_self_service") {
    const organizerId = $(body).attr("organizer_id");
    const locationId = $("#location").data("location-id");

    let orderStorageIdentifierFormSubmitAjaxLock = false;
  }
});
