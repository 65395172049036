$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_settings') {

            $(document).on("click", "#opening-hours-exception-closed", function () {
                if ($('#opening-hours-exception-closed').is(":checked")) {
                    $('#opening-hours-exception-opening-time').val('');
                    $('#opening-hours-exception-closing-time').val('');
                    if (!$('#opening-hours-exception-time-wrapper').hasClass('d-none')) {
                        $('#opening-hours-exception-time-wrapper').addClass('d-none')
                    }
                } else {
                    if ($('#opening-hours-exception-time-wrapper').hasClass('d-none')) {
                        $('#opening-hours-exception-time-wrapper').removeClass('d-none')
                    }
                }
            });

            let opening_hours_exception_ajax_lock = false;
            $(document).on("click", "#opening-hours-exception-registration-button", function () {
                $('.alert').remove();
                if (!opening_hours_exception_ajax_lock) {
                    opening_hours_exception_ajax_lock = true;

                    let executionDate = $('#opening-hours-exception-exception-date').val();
                    let openingTime = $('#opening-hours-exception-opening-time').val();
                    let closingTime = $('#opening-hours-exception-closing-time').val();
                    let closed = 0;
                    if ($('#opening-hours-exception-closed').is(":checked")) {
                        closed = 1;
                    }
                    let locationId = $(this).attr('data-location-id');

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/opening_hours_execution/new",
                        data: {
                            exception_date: executionDate,
                            opening_time: openingTime,
                            closing_time: closingTime,
                            closed: closed,
                            location_id: locationId
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#location-opening-hours-exception-list').empty();
                                $('#location-opening-hours-exception-list').append(data.html);
                            }
                            opening_hours_exception_ajax_lock = false;
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#opening-hours-exception-registration-error', data.responseJSON.error_message, 'danger', null, '#opening-hours-exception-registration-error-wrapper');
                            }
                            opening_hours_exception_ajax_lock = false;
                        })
                }

                return false;

            });

        }

    });