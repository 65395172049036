$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p === "product_order" && a === "list") {
    let order_by = "name";
    let order_by_direction = "asc";
    let isAutoFill = false;

    $(document).on(
      "click",
      ".product-order-pagination .pagination a",
      function (e) {
        e.preventDefault();
        let isChecked = $("#auto-fill-switch").prop("checked");
        isAutoFill = isChecked ? true : false;
        let page = parseInt($(this).attr("href").substring(6));
        let location_id = $("body").attr("event_id");
        let search_string = $("#search_string").val();
        let supplier_id = $("#product-order-table").attr("data-supplier-id");
        let product_order_status = $("#product_order_status").val();
        let product_type = $("#product_type").val();

        getProductList(
          location_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          product_type,
          supplier_id,
          product_order_status,
          p,
          isAutoFill
        );
      }
    );

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      let isChecked = $("#auto-fill-switch").prop("checked");
      isAutoFill = isChecked ? true : false;
      let page = $("#product-order-table").attr("data-page");
      let location_id = $("body").attr("event_id");
      let search_string = $("#search_string").val();
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      let supplier_id = $("#product-order-table").attr("data-supplier-id");
      let product_order_status = $("#product_order_status").val();
      let product_type = $("#product_type").val();

      getProductList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        product_type,
        supplier_id,
        product_order_status,
        p,
        isAutoFill
      );
    });

    function getProductList(
      location_id = null,
      page = 1,
      order_by,
      order_by_direction,
      search_string = "",
      product_type,
      supplier_id = null,
      product_order_status,
      current_page = "",
      isAutoFill = false
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: {
          location_id,
          page,
          search_string,
          order_by,
          order_by_direction,
          product_type,
          supplier_id,
          product_order_status,
          current_page,
          isAutoFill,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          if (isAutoFill) {
            $("#auto-fill-switch").prop("checked", true);
          } else {
            $("#auto-fill-switch").prop("checked", false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
