$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_settings') {

            $(document).on("click", ".location-opening-hours-closed-switch", function () {
                let day = $(this).attr('data-day');
                if ($(this).prop('checked')) {
                    openLocationOpeningHoursWrapper(day);
                } else {
                    closeLocationOpeningHoursWrapper(day);
                }
            });


            $('.location-opening-hours-closed-switch').each(function () {
                let day = $(this).attr('data-day');
                if ($(this).prop('checked')) {
                    openLocationOpeningHoursWrapper(day);
                } else {
                    closeLocationOpeningHoursWrapper(day);
                }
            });

            function openLocationOpeningHoursWrapper(day) {
                $("input[type='text'][name='opening_time[" + day + "]']").val('');
                $("input[type='text'][name='closing_time[" + day + "]']").val('');
                if (!$('#location-opening-hours-wrapper-day-' + day).hasClass('d-none')) {
                    $('#location-opening-hours-wrapper-day-' + day).addClass('d-none')
                }
            }

            function closeLocationOpeningHoursWrapper(day) {
                if ($('#location-opening-hours-wrapper-day-' + day).hasClass('d-none')) {
                    $('#location-opening-hours-wrapper-day-' + day).removeClass('d-none')
                }
            }


        }

    });