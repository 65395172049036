$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_booking_area_table' && a === 'list') {

            $(document).on("click", ".booking-table-delete-button", function () {
                let bookingAreaId = $(this).attr('data-booking-area-id');
                let bookingAreaTableId = $(this).attr('data-booking-area-table-id');
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_area_table/delete",
                    data: {
                        booking_area_id: bookingAreaId,
                        booking_area_table_id: bookingAreaTableId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-area-table-list-wrapper').empty();
                            $('#booking-area-table-list-wrapper').append(data.html);
                            popupAlert();
                        }

                        if (data.table_name !== undefined && data.table_name !== null && data.table_id !== undefined && data.table_id !== null) {
                            if (!$("select[name='booking_table_id'] option[value=" + data.table_id + "]").length) {
                                $("select[name='booking_table_id']").prepend($('<option>', {
                                    value: data.table_id,
                                    text: data.table_name
                                }));
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);