$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "product_order" && a === "list") {
    let isAutoFill = false;
    $(document).on("submit", "#product-search-form", function (e) {
      e.preventDefault();
      let isChecked = $("#auto-fill-switch").prop("checked");
      isAutoFill = isChecked ? true : false;
      const search_string = $("#search_string").val();
      let location_id = $("body").attr("event_id");
      let product_type = $("#product_type").val();
      let supplier_id = $("#product-order-table").attr("data-supplier-id");
      let product_order_status = $("#product-order-table").attr(
        "data-order-filter"
      );

      getProductList(
        location_id,
        search_string,
        product_type,
        supplier_id,
        product_order_status,
        p
      );
    });

    function getProductList(
      location_id,
      search_string,
      product_type,
      supplier_id = null,
      product_order_status,
      current_page = ""
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: {
          location_id,
          search_string,
          product_type,
          supplier_id,
          product_order_status,
          current_page,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-search-button").attr(
              "data-search-string",
              search_string
            );
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          if (isAutoFill) {
            $("#auto-fill-switch").prop("checked", true);
          } else {
            $("#auto-fill-switch").prop("checked", false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
