$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let url = "";
  let status = 1;

  if ((p === "supplier" || p === "deactivated_suppliers") && a === "list") {
    $(document).on("submit", "#product-search-form", function (e) {
      e.preventDefault();

      let search_string = $("#search_string").val();
      let location_id = $("body").attr("event_id");
      if (p === "supplier") {
        url = "/ajax/supplier/search";
        status = 1;
      }
      if (p === "deactivated_suppliers") {
        url = "/ajax/deactivated_suppliers/search";
        status = 2;
      }
      getSupplierList(location_id, search_string, url, status);
    });

    function getSupplierList(location_id, search_string, url, status) {
      $.ajax({
        type: "POST",
        url,
        data: {
          location_id,
          search_string,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-search-button").attr(
              "data-search-string",
              search_string
            );
            $("#supplier-list-view").empty();
            $("#supplier-list-view").append(data.html);
          }
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
