$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'admin_dashboard' || p === 'admin_fees' || p === 'admin_fees_pos') {

            $('select[name=organizer_id]').select2({
                theme: 'bootstrap4',
            });

        }

    }
);