$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  const locationId = $("body").attr("event_id");
  if (p === "product_management" && a === "list") {
    $(document).on("change", ".product-min-amount-input", function (e) {
      e.preventDefault();
      $("#save-product-min-max-amount").prop("disabled", false);
      const productId = $(this).attr("data-product-id");
      const minAmount = parseFloat($(this).val()) || 0;

      const formData = new FormData();
      formData.append("product_id", productId);
      formData.append("location_id", locationId);
      formData.append("min_amount", minAmount);
      saveProductManagement(formData);
    });

    $(document).on("change", ".product-max-amount-input", function (e) {
      e.preventDefault();
      $("#save-product-min-max-amount").prop("disabled", false);
      const productId = $(this).attr("data-product-id");
      const maxAmount = $(this).val();

      const formData = new FormData();
      formData.append("product_id", productId);
      formData.append("location_id", locationId);
      formData.append("max_amount", maxAmount);
      saveProductManagement(formData);
    });
  }

  //update product min/max amount

  $("#email-checkbox").click(function () {
    const email_accepted = this.checked ? 1 : 0;
    const formData = new FormData();
    formData.append("location_id", locationId);
    formData.append("email_accepted", email_accepted);
    updateNotification(formData);
  });

  function saveProductManagement(formData) {
    $.ajax({
      type: "POST",
      url: "/ajax/product_management/save_product_management",
      data: formData,
      enctype: "multipart/form-data",
      async: true,
      cache: false,
      processData: false,
      contentType: false,
    })
      .done(function (data) {
        popupAlert();
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }

  function updateNotification(formData) {
    $.ajax({
      type: "POST",
      url: "/ajax/product_management/notification",
      data: formData,
      enctype: "multipart/form-data",
      async: true,
      cache: false,
      processData: false,
      contentType: false,
    })
      .done(function (data) {})
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }
});
