$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers') {

            $(document).on("click", ".change-order-product-status", function (e) {
                e.preventDefault();
                let location_id = $("#location_id").val();
                let order_product_id = $(this).attr("data-order-product-id");
                let order_id = $(this).attr("data-order-id");
                let status = $(this).val();

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/order_product/update",
                    data: {
                        location_id,
                        order_product_id,
                        order_id,
                        status,
                        employer_token
                    }
                })
                    .done(function (data) {

                        if (data.order_id !== undefined && data.order_id !== null && data.order_product_id !== undefined && data.order_product_id !== null && data.status !== undefined && data.status !== null) {
                            let buttonsForOrderProducts = $('tr#order-product-order-id-' + data.order_id).find('button.change-order-product-status');
                            buttonsForOrderProducts.each(function () {
                                if ($(this).val() != data.status && $(this).hasClass('btn-primary') && $(this).attr('data-order-product-id') == data.order_product_id) {
                                    $(this).removeClass('btn-primary');
                                    $(this).addClass('btn-secondary');
                                }
                                if ($(this).val() == data.status && !$(this).hasClass('btn-primary') && $(this).attr('data-order-product-id') == data.order_product_id) {
                                    $(this).removeClass('btn-secondary');
                                    $(this).addClass('btn-primary');
                                }
                            });
                        }

                        if (data.all_order_products_have_same_status !== undefined && data.all_order_products_have_same_status !== null) {
                            let status = $("#status option:selected").val();
                            if (data.all_order_products_have_same_status == true && $.isNumeric(status) && data.status != status && (data.status == 4 || status == 2)) {
                                $('tr#order-product-order-id-' + data.order_id).fadeOut(1000);
                                setTimeout(function () {
                                    $('tr#order-product-order-id-' + data.order_id).remove();
                                }, 1000);
                            } else {
                                let buttonsForOrder = $('tr#order-product-order-id-' + data.order_id).find('button.change-order-status');
                                buttonsForOrder.each(function () {
                                    if ($(this).val() != data.status && $(this).hasClass('btn-primary') && $(this).attr('data-order-id') == data.order_id) {
                                        $(this).removeClass('btn-primary');
                                        $(this).addClass('btn-secondary');
                                    }
                                    if ($(this).val() == data.status && !$(this).hasClass('btn-primary') && $(this).attr('data-order-id') == data.order_id) {
                                        $(this).removeClass('btn-secondary');
                                        $(this).addClass('btn-primary');
                                    }
                                });
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#order-product-status-' + order_product_id, data.responseJSON.error_message, 'danger', null, '#order-list-table')
                        }

                    })

            });

        }


    }
);

