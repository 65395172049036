$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p === "events_self_service") {
    let organizerId = $("body").attr("organizer_id");
    let city = $("body").attr("city");
    let organizerName = $("body").attr("organizer_name");
    let shop_type = $("body").attr("shop_type");
    $(".navbar-brand").attr(
      "data-single-tap-href",
      `/l/${organizerId}/${city}/${organizerName}/${shop_type}`
    );
  }

  if (p === "organizer_product_category_self_service_link" && a === "list") {
    $("select[name=product_id]").select2({
      theme: "bootstrap4",
    });
  }

  $("body").scrollspy({
    target: "#navbar-product-category-self-service",
    offset: 60,
  });
});
