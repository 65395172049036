$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_promo_code' && a == 'list') {

            let promo_code_delete_modal_lock = false;

            $(document).on("click", ".promo-code-delete-button", function () {
                let locationId = $("body").attr("event_id");
                let promoCodeId = $(this).attr("data-promo-code-id");
                let alertMessage = $(this).attr("data-alert-message");
                let numberOfProductsInPromoCode = parseInt($(this).attr("data-number-of-products-in-promo-code"));

                if (numberOfProductsInPromoCode > 0) {
                    alert(alertMessage);
                    return false;
                }

                if (!confirm(alertMessage)) {
                    return false;
                }

                deletePromoCode(locationId, promoCodeId);
            });

            function deletePromoCode(locationId, promoCodeId) {

                if (!promo_code_delete_modal_lock) {
                    promo_code_delete_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_promo_code/delete",
                        data: {
                            location_id: locationId,
                            promo_code_id: promoCodeId
                        }
                    })
                        .done(function (data) {

                            if (data === true) {
                                $("#promo-code-" + promoCodeId).fadeOut(1000);
                                setTimeout(function () {
                                    $("#promo-code-" + promoCodeId).remove();
                                }, 1000);
                            }

                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            promo_code_delete_modal_lock = false;

                        })
                        .always(function () {
                            promo_code_delete_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);
