$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let event_id = $("body").attr("event_id");
  if (p === "statistic_day_report") {
    $(document).on("change", "#report-date", function () {
      let selectedDate = $(this).val();

      $("#download-excel").attr(
        "href",
        `/location/${event_id}/statistic/day_report/download_excel?selectedDate=${selectedDate}`
      );
      $("#download-pdf").attr(
        "href",
        `/location/${event_id}/statistic/day_report/download_pdf?selectedDate=${selectedDate}`
      );
    });
  }
});
