$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  const status = $("body").attr("status");
  let url = "";
  if (
    p === "product_group_unique" ||
    p === "deactivated_product_group_unique"
  ) {
    //new
    $(document).on(
      "submit",
      "#productGroupUnique-registration-form",
      function (e) {
        e.preventDefault();

        const formData = new FormData($(this)[0]);
        formData.append("location_id", locationId);
        url = "/ajax/product_group_unique/new";
        saveProductGroupUnique(formData, url);
      }
    );

    //update
    $(document).on("submit", "#productGroupUnique-update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("location_id", locationId);
      formData.append("status", status);
      url = "/ajax/product_group_unique/update";
      saveProductGroupUnique(formData, url);
    });

    //delete
    $(document).on("click", ".productGroupUnique-delete-btn", function (e) {
      e.preventDefault();
      let idToDelete = $(this).attr("data-delete-id");

      $("#delete-product_group_unique-confirm").attr(
        "data-delete-id",
        idToDelete
      );
    });
    $(document).on(
      "click",
      "#delete-product_group_unique-confirm",
      function (e) {
        e.preventDefault();
        const productGroupUniqueId = $(this).attr("data-delete-id");
        url = "/ajax/product_group_unique/delete";
        const formData = new FormData();
        formData.append("status", 0);
        formData.append("product_group_unique_id", productGroupUniqueId);
        formData.append("location_id", locationId);
        updateStatus(formData, url);
        $(".modal").modal("hide");
      }
    );
    function saveProductGroupUnique(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#productGroupUnique-registration-form").hide();
            $("#productGroupUnique-update-form").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (p === "deactivated_product_group_unique") {
              setTimeout(function () {
                document.location.href =
                  "/location/" +
                  locationId +
                  "/product_group_unique/deactivated";
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/product_group_unique";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-registration-form"
            );
          }
        });
    }

    function updateStatus(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.alert) {
            alert(
              "Kunne ikke slette Varegruppe . Varegruppe  ligger mest sannsynlig i en Vare"
            );

            return;
          }
          if (
            data.message !== undefined &&
            data.message !== null &&
            !data.alert
          ) {
            prependAlert("#message", data.message, "success", null);
            $("#product-group-unique-list-view").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (p === "deactivated_product_group_unique") {
              setTimeout(function () {
                document.location.href =
                  "/location/" +
                  locationId +
                  "/product_group_unique/deactivated";
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/product_group_unique";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-update-form"
            );
          }
        });
    }
  }
});
