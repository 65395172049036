$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  if (p === "organizer_event_ticket_category" && a === "list") {
    //filter by supplier
    $(document).on("submit", "#filtered-form", function (e) {
      e.preventDefault();
      let searchString = $("#search_string").val();
      let liveEdit = $("#event_ticket_category_table").attr("data-live-edit");
      const formData = new FormData($("#filtered-form")[0]);
      formData.append("location_id", locationId);
      formData.append("search_string", searchString);
      formData.append("page", 1);
      formData.append("status", 1);
      formData.append("product_type", 0);
      formData.append("live_edit", liveEdit);
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          $("#product-filter-list").hide();
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
