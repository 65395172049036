$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let urlParam = new URLSearchParams(window.location.search);
  let type = "";

  if (p === "inventory" && a === "list") {
    let order_by = "name";
    let order_by_direction = "asc";

    $(document).on("click", ".product-pagination .pagination a", function (e) {
      e.preventDefault();
      type = $("#event_ticket_category_table").attr("data-current-type");

      let page = parseInt($(this).attr("href").substring(6));
      let location_id = $("body").attr("event_id");
      let search_string = $("#search_string").val();
      const reportStatus = $("#current-report").attr("data-report-status");
      const reportUserId = $("#current-report").attr("data-report-user-id");
      const supplierId = $("#sort").attr("data-supplier-id");

      getProductList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        type,
        reportUserId,
        reportStatus,
        supplierId
      );
    });

    $(document).on("click", ".report-pagination .pagination a", function (e) {
      e.preventDefault();
      type = $("#event_ticket_category_table").attr("data-current-type");
      let page = parseInt($(this).attr("href").substring(6));
      let location_id = $("body").attr("event_id");
      type = $("#event_ticket_category_table").attr("data-current-type");
      getReportList(location_id, page, type);
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      type = $("#event_ticket_category_table").attr("data-current-type");
      let page = $("#event_ticket_category_table").attr("data-page");
      let location_id = $("body").attr("event_id");
      let search_string = $("#search_string").val();
      const reportStatus = $("#current-report").attr("data-report-status");
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      const reportUserId = $("#current-report").attr("data-report-user-id");
      const supplierId = $(this).attr("data-supplier-id");

      getProductList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        type,
        reportUserId,
        reportStatus,
        supplierId
      );
    });

    function getProductList(
      location_id = null,
      page = 1,
      order_by,
      order_by_direction,
      search_string = "",
      product_type,
      type,
      report_user_id,
      report_status,
      supplier_id = null
    ) {
      const report_id = $("#event_ticket_category_table").attr(
        "data-report-id"
      );

      $.ajax({
        type: "POST",
        url: "/ajax/inventory/search",
        data: {
          location_id,
          page,
          search_string,
          order_by,
          order_by_direction,
          product_type,
          type,
          report_id,
          report_user_id,
          report_status,
          supplier_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getReportList(location_id, page = 1, type) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/report_search",
        data: {
          location_id,
          page,
          type,
          enabled_report_pagination: true,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $(".report-list").empty();
            $(".report-list").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
