$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p === "inventory" && a === "list") {
    $(document).on("click", ".collapse-btn", function (e) {
      var productId = $(this).data("product-id");
      var expandMoreIcon = $(".expand-more-" + productId);
      var expandLessIcon = $(".expand-less-" + productId);

      if ($(this).attr("aria-expanded") === "false") {
        expandMoreIcon.toggle();
        expandLessIcon.toggle();
        $(`#product-${productId}`).show();
      } else {
        expandMoreIcon.hide();
        expandLessIcon.prop("hidden", false);
        expandLessIcon.show();
        $(`#product-${productId}`).hide();
      }
    });
  }
});
