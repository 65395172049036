$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let status = 1;
  if (p === "organizer_event_ticket_category" && a === "list") {
    $(document).on("submit", "#product-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#search_string").val();
      let location_id = $("body").attr("event_id");
      let product_type = $("#product_type").val();
      let supplier_id = $("#sort").attr("data-supplier-id");
      let product_group_unique_id = $("#product_group_unique_id").val();
      let live_edit = $("#event_ticket_category_table").attr("data-live-edit");
      let page = 1;

      getProductList(
        location_id,
        search_string,
        product_type,
        supplier_id,
        product_group_unique_id,
        page,
        live_edit,
        status
      );
    });

    function getProductList(
      location_id,
      search_string,
      product_type,
      supplier_id = null,
      product_group_unique_id = null,
      page,
      live_edit
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: {
          location_id,
          search_string,
          product_type,
          supplier_id,
          product_group_unique_id,
          page,
          live_edit,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-search-button").attr(
              "data-search-string",
              search_string
            );
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
