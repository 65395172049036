$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let url = "";
  let status = 1;

  if ((p === "table" || p === "deactivated_table") && a === "list") {
    $(document).on("submit", "#table-search-form", function (e) {
      e.preventDefault();

      let search_string = $("#search_string").val();
      let location_id = $("body").attr("event_id");
      if (p === "table") {
        status = 1;
      }
      if (p === "deactivated_table") {
        status = 2;
      }
      getTableList(location_id, search_string, status);
    });

    function getTableList(location_id, search_string, status) {
      $.ajax({
        type: "POST",
        url: "/ajax/table/search",
        data: {
          location_id,
          search_string,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#table-list-view").empty();
            $("#table-list-view").append(data.html);
            $("#table-search-button").attr("data-search-string", search_string);
          }
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
