$(document).ready(
    function () {

        $(document).on("click", "#standing_zone", function () {
            checkStandingZone();
        });

        checkStandingZone();

        function checkStandingZone() {
            if ($("#standing_zone").prop("checked") === true) {
                hideSeatsWrapper();
            } else {
                showSeatsWrapper();
            }
        }

        function hideSeatsWrapper() {
            if (!$("#area-zone-seat-wrapper").hasClass("d-none")) {
                $("#area-zone-seat-wrapper").addClass("d-none");
                $("#people_limit").prop("disabled", false);
                $("#people_limit").prop("placeholder", 100);
            }
        }

        function showSeatsWrapper() {
            if ($("#area-zone-seat-wrapper").hasClass("d-none")) {
                $("#area-zone-seat-wrapper").removeClass("d-none");
                $("#people_limit").prop("disabled", true);
                $("#people_limit").prop("placeholder", 0);
            }
        }

        $(document).on("click", "#show-deactivated-area-zone-button", function () {
            $('.deactivated-area-zone').removeClass('d-none');
            $(this).remove();
        });

    });


