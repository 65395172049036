$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_category_link' && a === 'list') {

            let product_category_link_delete_ajax_lock = false;

            $(document).on("click", ".product-category-link-delete-button", function () {
                let productId = $(this).attr('data-product-id');
                let productCategoryId = $(this).attr('data-product-category-id');
                let locationId = $("body").attr("event_id");

                if (!product_category_link_delete_ajax_lock) {

                    product_category_link_delete_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_category_link/delete",
                        data: {
                            product_category_id: productCategoryId,
                            product_id: productId,
                            location_id: locationId
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                $('#product-category-link-list-sortable').empty();
                                $('#product-category-link-list-sortable').append(data.html);
                                popupAlert();
                            }

                            if (data.product !== undefined && data.product !== null) {
                                $("select[name='product_id']").prepend($('<option>', {
                                    value: productId,
                                    text: data.product
                                }));
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            product_category_link_delete_ajax_lock = false;

                        }).always(function () {
                        product_category_link_delete_ajax_lock = false;
                    });

                }

            });

        }

    }
);