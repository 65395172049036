$(document).ready(function () {
  let p = $("body").attr("p");
  if (p === "events") {
    const HasposType = localStorage.getItem("postype") ? true : false;
    let posType = "";
    let deviceType = "desktop";

    if (window.matchMedia("(max-width: 767px)").matches) {
      deviceType = "mobile";
    } else if (
      window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches
    ) {
      deviceType = "tablet";
    }
    if (!HasposType) {
      if (deviceType === "desktop" || deviceType === "tablet") {
        localStorage.setItem("postype", "classic");
      } else {
        localStorage.setItem("postype", "scroll");
      }
    }

    posType = localStorage.getItem("postype");

    // Get the current URL
    var currentUrl = window.location.href;

    // Create a URL object
    var url = new URL(currentUrl);

    // Check if the "pos_type" parameter is already set
    if (!url.searchParams.has("pos_type")) {
      // Modify the query parameters
      url.searchParams.set("pos_type", posType);

      // Refresh the page with the new URL
      window.location.href = url.toString();
    }
  }
});
