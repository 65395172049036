$(document).ready(
    function () {


        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'event' && a == 'show') {

            if (!$('#scene-wrapper-order').hasClass('d-none')) {
                $('#scene-wrapper-order').addClass('d-none');
            }

            let reservation_ajax_lock = false;

            let eventId = $("#event-id").val();
            let eventTicketCategoryList = "#event-ticket-category-list";
            let orderForm = $("#order-form");
            let restrictionCodeForm = $("#restriction-code-form");
            let hideOrderForm = $("#hide-order-form");
            let orderSummary = $("#order-summary");
            let orderTerms = $("#order-terms");
            let orderButton = $("#order-register-button");
            let cancelButton = $("#order-cancel-button");
            let nextButton = $("#order-next-button");
            let countDownId = $('#order-count-down-timer');
            let expirationTime = parseInt(countDownId.attr("data-reservation-time")) * 1000;
            let expirationTimeText = countDownId.attr("data-reservation-time-text");
            let expirationText = countDownId.attr("data-reservation-text");
            let reservationCountDownInterval = null;
            let reservedEventId = null;
            let eventTicketCategoryId = null;
            let eventSubeventId = null;
            if ($("#subevent-id").length) {
                eventSubeventId = $("#subevent-id").val();
            }
            let discount;
            let event_form_html;

            let organizer_id = parseInt($("body").attr("organizer_id"));
            let event_id = parseInt($("body").attr("event_id"));

            if (typeof $("body").attr("reservation_event_id") !== typeof undefined && $("body").attr("reservation_event_id") !== false) {
                reservedEventId = $("body").attr("reservation_event_id");
            }

            if (eventId === reservedEventId) {
                continueReservation();
            } else {
                startReservation();
            }

            let numberOfSeats = 0;
            let numberOfSeatsReserved = 0;
            let seatIdArray = null;
            let reservedSeatIdArray = null;
            let areaZoneVersionId;
            let numberOfTickets;
            let isStandingZone = false;
            let chooseForMe = false;

            let isMobile = false;
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                isMobile = true;
            }

            if ($(".event-ticket-category-list")[0] && $('#scene-wrapper-order').length) {
                let numberOfEventTicketCategories = 0;
                $('.event-ticket-category-list').each(function () {
                    numberOfEventTicketCategories = numberOfEventTicketCategories + 1;
                });
                if (numberOfEventTicketCategories == 1) {
                    $('.event-ticket-category-list').each(function () {
                        eventTicketCategoryId = $(this).find('button.event-ticket-category-with-area-button').data("event-ticket-category-id");
                        if (!$(this).find('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                            $(this).find('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                        }
                        if (eventId !== reservedEventId) {
                            openAreaZone(eventTicketCategoryId);
                        }
                        return false;
                    });
                }
            }

            $(document).on("click", "#choose-seat-for-me-button", function () {
                numberOfTickets = $('.ticket-to-order').val();
                if (!(numberOfTickets > 0)) {
                    prependAlert('#div_order_buttons', 'Velg minst en billett', 'warning', null, '#order_register_form');
                    return false;
                }
                eventTicketCategoryId = $(this).data("event-ticket-category-id");
                chooseForMe = true;
                reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, null, numberOfTickets);
            });

            $(document).on("click", "#see-area-zone-button", function () {
                chooseForMe = false;
                if ($('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').removeClass('d-none');
                }
                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }
                if (!$('#choose-seat-for-me-button').hasClass('d-none')) {
                    $('#choose-seat-for-me-button').addClass('d-none');
                }
            });


            $(document).on("click", ".event-ticket-category-with-area-button", function () {
                $('.alert').remove();
                if (!$(this).closest('.row').find('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                    $(this).closest('.row').find('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                }
                eventTicketCategoryId = $(this).data("event-ticket-category-id");
                openAreaZone(eventTicketCategoryId);
            });

            function openAreaZone(eventTicketCategoryId) {
                $('.event-ticket-category-with-area').each(function () {
                        if (eventTicketCategoryId != $(this).data("event-ticket-category-id")) {
                            if (!$(this).closest('.row').hasClass("d-none")) {
                                $(this).closest('.row').addClass("d-none");
                            }
                        } else {
                            if ($(this).siblings('div.ticket-qty-container-wrapper').hasClass('d-none')) {
                                $(this).siblings('div.ticket-qty-container-wrapper').removeClass('d-none');
                            }
                            if ($(this).siblings('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                                $(this).siblings('div.event-ticket-category-with-area-button-wrapper').addClass('d-none');
                            }
                        }
                    }
                );
                getAreaZone(eventTicketCategoryId);
            }

            function getAreaZone(eventTicketCategoryId) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/get_area_zone",
                    data: {
                        event_id: event_id,
                        event_subevent_id: eventSubeventId,
                        event_ticket_category_id: eventTicketCategoryId,
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $("#event-ticket-category-area-zone").remove();
                            $("#event-ticket-category-list").after(data.html);

                            if ($(".event-ticket-category-area-zone")[0]) {
                                let numberOfEventTicketCategoryAreaZones = 0;
                                $('.event-ticket-category-area-zone').each(function () {
                                    numberOfEventTicketCategoryAreaZones = numberOfEventTicketCategoryAreaZones + 1;
                                });
                                if (numberOfEventTicketCategoryAreaZones == 1 && !isMobile) {
                                    $('.event-ticket-category-area-zone').each(function () {
                                        areaZoneVersionId = $(this).find('button.event-ticket-category-area-zone-reservation-button').data("area-zone-version-id");
                                        let areaZoneStandingZone = $(this).find('button.event-ticket-category-area-zone-reservation-button').data("standing-zone");
                                        if (areaZoneStandingZone * 1 == 1) {
                                            isStandingZone = true;
                                        }
                                    });
                                    if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                        $('#event-ticket-category-area-zone').addClass('d-none');
                                    }
                                    if ($('#one-area-zone-next-button').hasClass('d-none')) {
                                        $('#one-area-zone-next-button').removeClass('d-none');
                                    }
                                } else {
                                    $("#one-area-zone-next-button").attr("data-area-zone-version-id", areaZoneVersionId);
                                }
                                if (!isMobile) {
                                    if (!$('#see-area-zone-button').hasClass('d-none')) {
                                        $('#see-area-zone-button').addClass('d-none');
                                    }
                                }
                                if (isMobile) {
                                    if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                        $('#event-ticket-category-area-zone').addClass('d-none');
                                    }
                                    if ($('#see-area-zone-button').hasClass('d-none')) {
                                        $('#see-area-zone-button').removeClass('d-none');
                                    }
                                }
                            }
                        }

                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }
                    });
            }

            function showEventTicketCategory() {
                $('.event-ticket-category-with-area').each(function () {
                    if ($(this).closest('.row').hasClass("d-none")) {
                        $(this).closest('.row').removeClass("d-none");
                    }
                    if (!$(this).siblings('div.ticket-qty-container-wrapper').hasClass('d-none')) {
                        $(this).siblings('div.ticket-qty-container-wrapper').addClass('d-none');
                    }
                    if ($(this).siblings('div.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                        $(this).siblings('div.event-ticket-category-with-area-button-wrapper').removeClass('d-none');
                    }
                });
                $("#event-ticket-category-area-zone").remove();
                $('#div_order_buttons').find('.alert').remove();
            }

            $(document).on("click", "#order-back-button", function () {
                if (!$('#order-back-button').hasClass('d-none')) {
                    $('#order-back-button').addClass('d-none');
                }
                showEventTicketCategory();
            });

            function startReservingTicketsWithAreaZone(areaZoneVersionId) {
                numberOfTickets = $('#event-ticket-category-' + eventTicketCategoryId).val() * 1;
                if (!(numberOfTickets > 0)) {
                    prependAlert('#div_order_buttons', 'Velg minst en billett', 'warning', null, '#order_register_form');
                    return false;
                }
                $('#div_order_buttons').find('.alert').remove();
                reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, areaZoneVersionId, numberOfTickets);
            }

            $(document).on("click", "#one-area-zone-next-button", function () {
                chooseForMe = false;
                areaZoneVersionId = $(this).attr("data-area-zone-version-id");
                if ($(this).attr("data-standing-zone") * 1 == 1) {
                    isStandingZone = true;
                }
                startReservingTicketsWithAreaZone(areaZoneVersionId);
            });

            $(document).on("click", ".event-ticket-category-area-zone-reservation-button", function () {
                chooseForMe = false;
                areaZoneVersionId = $(this).data("area-zone-version-id");
                if ($(this).attr("data-standing-zone") * 1 == 1) {
                    isStandingZone = true;
                }
                startReservingTicketsWithAreaZone(areaZoneVersionId);
            });

            function reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, areaZoneVersionId, numberOfTickets, seatIds = []) {
                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/reservation_with_seat",
                    data: {
                        event_id: event_id,
                        event_subevent_id: eventSubeventId,
                        event_ticket_category_id: eventTicketCategoryId,
                        area_zone_version_id: areaZoneVersionId,
                        number_of_tickets: numberOfTickets,
                        seat_ids: seatIds
                    }
                })
                    .done(function (data) {

                        if (data.expiration !== undefined && data.expiration !== null) {
                            if (!$('#restriction-code-form').hasClass('d-none')) {
                                $('#restriction-code-form').addClass('d-none');
                            }
                            if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                                $('#restriction-code-form-button-div').addClass('d-none');
                            }
                            if (!$(eventTicketCategoryList).hasClass('d-none')) {
                                $(eventTicketCategoryList).addClass('d-none');
                            }
                            if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').addClass('d-none');
                            }
                            if (!$('#order-back-button').hasClass('d-none')) {
                                $('#order-back-button').addClass('d-none');
                            }
                            if ($('#order-next-button').hasClass('d-none')) {
                                $('#order-next-button').removeClass('d-none');
                            }
                            if ($('#order-next-button').css('display', 'none')) {
                                $('#order-next-button').css('display', 'inline-block');
                            }
                            if ($('#choose-seat-for-me-button').length && !$('#choose-seat-for-me-button').hasClass('d-none')) {
                                $('#choose-seat-for-me-button').addClass('d-none');
                            }

                            if (isStandingZone) {
                                openOrderForm();
                                return false;
                            }

                            if (seatIds.length > 0) {
                                openOrderForm();
                                return false;
                            }

                            if (chooseForMe) {
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow);

                                if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                    $('#area-zone-name').text(data.area_zone_name);
                                }

                                openOrderForm();
                                return false;
                            }

                            if (data.seat_coordinates !== undefined && data.seat_coordinates !== null) {
                                if ($('#scene-wrapper-order').hasClass('d-none')) {
                                    $('#scene-wrapper-order').removeClass('d-none');
                                }
                                if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                                    $('#one-area-zone-next-button').addClass('d-none');
                                }
                                if ($("#seats").length) {
                                    $("#seats").empty();
                                }
                                let seatCoordinates = JSON.parse(data.seat_coordinates);
                                let maxSeatsPerRow = data.max_seat_per_row;
                                let maxRows = data.max_rows;
                                numberOfSeats = data.number_of_seats;
                                numberOfSeatsReserved = data.number_of_seats;
                                seatIdArray = [...data.reserved_seat_id_array];
                                reservedSeatIdArray = data.reserved_seat_id_array;
                                setSceneHeight(maxRows);
                                generateSeats(seatCoordinates, maxSeatsPerRow);
                            }

                            if (data.discount !== undefined && data.discount !== null) {
                                discount = data.discount;
                            }

                            if (data.event_form_html !== undefined && data.event_form_html !== null) {
                                event_form_html = data.event_form_html;
                            }

                            if (data.area_zone_name !== undefined && data.area_zone_name !== null) {
                                $('#area-zone-name').text(data.area_zone_name);
                            }

                            if ($('#order-next-button').hasClass('d-none')) {
                                $('#order-next-button').removeClass('d-none');
                            }

                            if (isMobile) {
                                openOrderForm();
                                return false;
                            }

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                    });
            }

            function openOrderForm() {
                if (!$('#scene-wrapper-order').hasClass('d-none')) {
                    $('#scene-wrapper-order').addClass('d-none');
                }
                if ($('#hide-order-form').hasClass('d-none')) {
                    $('#hide-order-form').removeClass('d-none');
                }
                if ($('#hide-order-form').css('display', 'none')) {
                    $('#hide-order-form').css('display', 'inline-block');
                }
                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }

                $('#number-of-tickets-for-order').text(numberOfTickets);
                if (discount !== undefined && discount !== null) {
                    addDiscountCookie(discount);
                    reservationOk(discount);
                } else {
                    reservationOk();
                }
                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);
                if (event_form_html !== undefined && event_form_html !== null) {
                    $('#event-form').html(event_form_html);
                }
                if ($('#order-cancel-button').hasClass('d-none')) {
                    $('#order-cancel-button').removeClass('d-none');
                }
                if ($('#order-register-button').hasClass('d-none')) {
                    $('#order-register-button').removeClass('d-none');
                }
                if ($('#one-area-zone-next-button').length && !$('#one-area-zone-next-button').hasClass('d-none')) {
                    $('#one-area-zone-next-button').addClass('d-none');
                }
                if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').addClass('d-none');
                }
                if (isStandingZone && !$('#area-zone-seat-icon-wrapper').hasClass('d-none')) {
                    $('#area-zone-seat-icon-wrapper').addClass('d-none');
                }
                if (!isStandingZone && $('#area-zone-seat-icon-wrapper').hasClass('d-none')) {
                    $('#area-zone-seat-icon-wrapper').removeClass('d-none');
                }

            }

            function isArraysEqual(a1, a2) {
                return JSON.stringify(a1) == JSON.stringify(a2);
            }


            $(nextButton).click(function (e) {

                e.preventDefault();

                if (Array.isArray(reservedSeatIdArray) && !isArraysEqual(seatIdArray, reservedSeatIdArray)) {
                    $.ajax({
                        type: 'GET',
                        url: "/ajax/order/cancel_reservation"
                    })
                        .done(function (data) {
                            if (reservationCountDownInterval !== null) {
                                clearInterval(reservationCountDownInterval);
                                reservationCountDownInterval = null;
                                countDownId.text(expirationText + expirationTimeText);
                            }
                            removeDiscountCookie();
                            reserveTicketsWithAreaZone(eventId, eventSubeventId, eventTicketCategoryId, areaZoneVersionId, numberOfTickets, seatIdArray);
                        })
                    return false;
                }

                if ($('#scene-wrapper-order').length && Array.isArray(reservedSeatIdArray) && isArraysEqual(seatIdArray, reservedSeatIdArray)) {
                    openOrderForm();
                    return false;
                }


                if ($('#reservation-alert').length !== 0) {
                    $('#reservation-alert').remove();
                }

                let formDataReservation = new FormData(document.getElementById('order_register_form'));

                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/cancel_reservation"
                })

                    .done(function () {
                        removeDiscountCookie();
                        startOrder(formDataReservation);
                    })

            });

            $(cancelButton).click(function (e) {

                e.preventDefault();

                $('.ticket-to-order').val(0);

                if ($('#reservation-alert').length !== 0) {
                    $('#reservation-alert').remove();
                }

                isStandingZone = false;

                orderButton.hide();
                cancelButton.hide();
                orderForm.hide();
                orderSummary.hide();
                orderTerms.hide();

                if (!$('#see-area-zone-button').hasClass('d-none')) {
                    $('#see-area-zone-button').addClass('d-none');
                }

                if ($(eventTicketCategoryList).hasClass('d-none')) {
                    $(eventTicketCategoryList).removeClass('d-none');
                }
                $(eventTicketCategoryList).show();

                if ($('#restriction-code-form-button-div').hasClass('d-none')) {
                    $('#restriction-code-form-button-div').removeClass('d-none');
                }
                if (!$('#restriction-code-form').hasClass('d-none') && !$('#restriction_code').val()) {
                    $('#restriction-code-form').addClass('d-none');
                }


                let numberOfEventTicketCategory = 0;
                $('.event-ticket-category-list').each(function () {
                    numberOfEventTicketCategory = numberOfEventTicketCategory + 1;
                });
                if (numberOfEventTicketCategory == 1) {
                    if ($('#event-ticket-category-area-zone').length) {
                        let numberOfEventTicketCategoryAreaZone = 0;
                        $('.event-ticket-category-area-zone').each(function () {
                            numberOfEventTicketCategoryAreaZone = numberOfEventTicketCategoryAreaZone + 1;
                        });
                        if (numberOfEventTicketCategoryAreaZone == 1) {
                            if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').addClass('d-none');
                            }
                            if ($('#one-area-zone-next-button').hasClass('d-none')) {
                                $('#one-area-zone-next-button').removeClass('d-none');
                            }
                        } else {
                            if ($('#event-ticket-category-area-zone').hasClass('d-none')) {
                                $('#event-ticket-category-area-zone').removeClass('d-none');
                            }
                            if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                                $('#one-area-zone-next-button').addClass('d-none');
                            }
                        }
                        nextButton.hide();
                    } else {
                        nextButton.show();
                    }
                } else {
                    if ($('.event-ticket-category-list').hasClass('d-none')) {
                        $('.event-ticket-category-list').removeClass('d-none');
                    }
                    if ($('.event-ticket-category-with-area-button-wrapper').hasClass('d-none')) {
                        $('.event-ticket-category-with-area-button-wrapper').removeClass('d-none');
                    }
                    $('div.ticket-qty-container-wrapper').each(function () {
                        if (!$(this).hasClass('d-none')) {
                            $(this).addClass('d-none');
                        }
                    });
                }

                $.ajax({
                    type: 'GET',
                    url: "/ajax/order/cancel_reservation"
                })
                    .done(function (data) {

                        removeDiscountCookie();
                        if (data.message !== undefined && data.message !== null) {
                            prependAlert('#div_order_buttons', data.message, 'warning', null, '#order_register_form')
                        }

                    })

            });

            $(document).on("click", "#show-order-summary-toggle-button", function () {

                if ($('#ul-order-summary').hasClass('d-none')) {
                    $('#ul-order-summary').removeClass('d-none');
                    $('#show-order-summary-toggle-button').addClass('show');
                } else {
                    $('#ul-order-summary').addClass('d-none');
                    $('#show-order-summary-toggle-button').removeClass('show');
                }

                if ($('#order-summary-title').hasClass('desktop-view')) {
                    $('#order-summary-title').removeClass('desktop-view');
                } else {
                    $('#order-summary-title').addClass('desktop-view');
                }

                if ($('#show-order-summary-toggle-button').hasClass('show')) {
                    $('#show-order-summary-toggle-button').html($('#show-order-summary-toggle-button').attr("data-order-summary-hide"));
                } else {
                    $('#show-order-summary-toggle-button').html($('#show-order-summary-toggle-button').attr("data-order-summary-show"));
                }

                return false;

            });


            $(document).on("click", "#restriction-code-form-button", function () {

                if ($('#restriction-code-form').hasClass('d-none')) {
                    $('#restriction-code-form').removeClass('d-none');
                } else {
                    $('#restriction-code-form').addClass('d-none');
                }

                return false;

            });


            $('#restriction_code').on('keypress', function (e) {
                if (e.which === 13) {
                    getEventTicketCategoryWithRestictionCode()
                }
            });


            $(document).on("click", "#event-ticket-restriction-code-button", function () {
                getEventTicketCategoryWithRestictionCode()

            });


            function getEventTicketCategoryWithRestictionCode() {

                let restriction_code = $("#restriction_code").val();

                if ($('#message').length !== 0) {
                    $('#message').remove();
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/event/show",
                    data: {
                        organizer_id: organizer_id,
                        event_id: event_id,
                        restriction_code: restriction_code
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#event-ticket-category-list").remove();
                            $("#queue-count-down-div").after(data.html);

                            if (data.html.indexOf('input') !== -1) {
                                $('#div_order_buttons #order-next-button').removeClass('d-none');
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                        }

                    });
            }


            function reservationCountDown(expirationTime, countDownId, expirationText) {

                let remainingTime = expirationTime;
                let reservationExpired = $("#order-count-down-timer").attr('data-reservation-expired');

                if ($('.mobile-view').is(":visible")) {
                    $('html, body').animate({
                        scrollTop: $("#order_register_form").offset().top - 15
                    }, 100);
                }

                let x = setInterval(function () {

                    remainingTime = remainingTime - 1000;
                    let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = ('0' + Math.floor((remainingTime % (1000 * 60)) / 1000)).slice(-2);

                    countDownId.text(expirationText + " " + minutes + ":" + seconds);

                    if (remainingTime <= 0) {
                        clearInterval(x);
                        $.ajax({
                            type: 'GET',
                            url: "/ajax/order/cancel_reservation_timeout"
                        })

                            .done(function () {
                                removeDiscountCookie();
                                startReservation();
                                prependAlert('#div_order_buttons', reservationExpired, 'warning', null, '#div_order_buttons');
                                $(".ticket-to-order").each(function () {
                                    $(this).val(0);
                                });
                            })

                    }

                }, 1000);

                return x;

            }


            function reservationOk(discount = null) {

                let ticketsTotalPrice = 0;
                let ticketsTotalVat = 0;
                let ticketsToOrder = 0;
                let errorNoTicket = '';
                let tableOrderSummary = $("#table-order-summary");
                let discountSumIncVatTotal = 0;
                let discountSumVat = 0;

                tableOrderSummary.empty();
                $("#ul-order-summary").empty();

                if ($('#ul-order-summary').length && !$('#ul-order-summary').hasClass('d-none')) {
                    $('#ul-order-summary').addClass('d-none');
                }
                if (!$('#order-summary-title').hasClass('desktop-view')) {
                    $('#order-summary-title').addClass('desktop-view');
                }

                $('.ticket-to-order').each(function () {
                    ticketsToOrder += parseInt($(this).val());

                    if (parseInt($(this).val()) > 0) {

                        let eventTicketCategoryId = $(this).attr("id");
                        eventTicketCategoryId = parseInt(eventTicketCategoryId.replace("event-ticket-category-", ""));
                        let eventTicketCategoryAmount = $(this).val();
                        let eventTicketCategoryVat = parseFloat($(this).attr("data-price-vat"));
                        let eventTicketCategoryPriceIncVat = parseFloat($(this).attr("data-price-inc-vat"));
                        let eventTicketCategoryFeeVat = parseFloat($(this).attr("data-fee-vat"));
                        let eventTicketCategoryFeeIncVat = parseFloat($(this).attr("data-fee-inc-vat"));
                        let eventTicketCategoryPriceWithFeeAndVatTotal = ((eventTicketCategoryPriceIncVat + eventTicketCategoryFeeIncVat) * eventTicketCategoryAmount).toFixed(2);

                        ticketsTotalPrice += (eventTicketCategoryPriceIncVat + eventTicketCategoryFeeIncVat) * eventTicketCategoryAmount;
                        ticketsTotalVat += (eventTicketCategoryVat + eventTicketCategoryFeeVat) * eventTicketCategoryAmount;

                        eventTicketCategoryPriceIncVat = eventTicketCategoryPriceIncVat.toFixed(2);
                        eventTicketCategoryFeeIncVat = eventTicketCategoryFeeIncVat.toFixed(2);
                        eventTicketCategoryPriceIncVat = eventTicketCategoryPriceIncVat.replace(".", ",");
                        eventTicketCategoryFeeIncVat = eventTicketCategoryFeeIncVat.replace(".", ",");
                        eventTicketCategoryPriceWithFeeAndVatTotal = eventTicketCategoryPriceWithFeeAndVatTotal.replace(".", ",");

                        $('#table-order-summary').append($('<tr>')
                            .append($('<td class="text-left">').append($(this).attr("data-name")))
                            .append($('<td class="text-center">').append($(this).val()))
                            .append($('<td class="text-right">').append("kr " + eventTicketCategoryPriceIncVat))
                            .append($('<td class="text-right">').append("kr " + eventTicketCategoryFeeIncVat))
                            .append($('<td class="text-right">').append("kr " + eventTicketCategoryPriceWithFeeAndVatTotal))
                        );

                        $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                            .append($('<li class="list-group-item">').append("<strong>" + $(this).attr("data-name") + "</strong>"))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-quantity') + ': ' + $(this).val()))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price') + ': ' + "kr " + eventTicketCategoryPriceIncVat))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-fee') + ': ' + "kr " + eventTicketCategoryFeeIncVat))
                            .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price-total') + ': ' + "kr " + eventTicketCategoryPriceWithFeeAndVatTotal))
                        );

                        if (discount !== undefined && discount !== null && discount != '') {

                            let discountPercent = 0;
                            let discountSumIncVatText = 0;
                            let discountSumIncVat = 0;
                            let discountSumIncVatForEventTicketCategory = 0;

                            let eventTicketCategoryDiscount;
                            if (discount[eventTicketCategoryId] !== undefined && discount[eventTicketCategoryId] !== null && discount[eventTicketCategoryId] != '') {
                                eventTicketCategoryDiscount = JSON.parse(discount[eventTicketCategoryId]);
                            }

                            if (eventTicketCategoryDiscount !== undefined && eventTicketCategoryDiscount !== null && eventTicketCategoryDiscount != '') {
                                discountPercent = eventTicketCategoryDiscount.discount_percent;
                                discountSumIncVatText = eventTicketCategoryDiscount.discount_sum_inc_vat;
                                discountSumIncVat = discountSumIncVatText.replace(",", ".");
                                discountSumIncVat = parseFloat(discountSumIncVat.replace(" ", ""));
                                discountSumIncVatForEventTicketCategory = discountSumIncVat * eventTicketCategoryAmount;
                                discountSumIncVatForEventTicketCategory = discountSumIncVatForEventTicketCategory.toFixed(2);
                                discountSumIncVatForEventTicketCategory = discountSumIncVatForEventTicketCategory.replace(".", ",");
                                discountSumIncVatTotal += parseFloat(discountSumIncVatForEventTicketCategory);
                                discountSumVat += parseFloat(eventTicketCategoryDiscount.discount_sum_vat) * eventTicketCategoryAmount;

                                $('#table-order-summary').append($('<tr>')
                                    .append($('<td class="text-left" style="border-top:0px">').append("Rabatt " + discountPercent + "%"))
                                    .append($('<td class="text-center" style="border-top:0px">').append($(this).val()))
                                    .append($('<td class="text-right" style="border-top:0px">').append("kr " + discountSumIncVatText))
                                    .append($('<td class="text-right" style="border-top:0px">').append(" "))
                                    .append($('<td class="text-right" style="border-top:0px">').append("- kr " + discountSumIncVatForEventTicketCategory))
                                );

                                $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                                    .append($('<li class="list-group-item">').append("<strong>" + discountPercent + "% rabatt for " + $(this).attr("data-name") + "</strong>"))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-quantity') + ': ' + $(this).val()))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price') + ': ' + "kr " + discountSumIncVatText))
                                    .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-price-total') + ': ' + "- kr " + discountSumIncVatForEventTicketCategory))
                                );

                            }

                        }
                    }
                });

                if (ticketsToOrder > 0) {

                    if (ticketsTotalPrice === 0 && !$('#phone-number').hasClass('d-none')) {
                        $('#phone-number').addClass('d-none');
                    }

                    if (ticketsTotalPrice > 0 && $('#phone-number').hasClass('d-none')) {
                        $('#phone-number').removeClass('d-none');
                    }

                    $(".alert", $("#div_order_buttons")).remove();

                    orderButton.show();
                    cancelButton.show();
                    nextButton.hide();
                    $(eventTicketCategoryList).hide();
                    orderForm.show();
                    orderSummary.show();
                    orderTerms.show();

                    if (!$('#restriction-code-form').hasClass('d-none')) {
                        $('#restriction-code-form').addClass('d-none');
                    }
                    if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                        $('#restriction-code-form-button-div').addClass('d-none');
                    }

                    ticketsTotalPrice = ticketsTotalPrice - discountSumIncVatTotal;
                    ticketsTotalPrice = ticketsTotalPrice.toFixed(2);
                    ticketsTotalVat = ticketsTotalVat - discountSumVat;
                    ticketsTotalVat = ticketsTotalVat.toFixed(2);
                    ticketsTotalPrice = ticketsTotalPrice.replace(".", ",");
                    ticketsTotalVat = ticketsTotalVat.replace(".", ",");

                    let sum = tableOrderSummary.attr('data-sum');
                    let data_of_with_vat = tableOrderSummary.attr('data-of-with-vat');

                    tableOrderSummary.append($('<tr>')
                        .append($('<td>').append('<strong>' + sum + '</strong>'))
                        .append($('<td colspan="4"  class="text-right">').append('<strong>kr ' + ticketsTotalPrice + '</strong>'))
                    );
                    tableOrderSummary.append($('<tr>')
                        .append($('<td colspan="5" class="text-right">').append(data_of_with_vat + ' kr ' + ticketsTotalVat))
                    );

                    $('#ul-order-summary').append($('<ul class="list-group mb-4">')
                        .append($('<li class="list-group-item">').append("<strong>" + $("#ul-order-summary").attr('data-sum') + " kr: " + ticketsTotalPrice + "</strong>"))
                        .append($('<li class="list-group-item">').append($("#ul-order-summary").attr('data-of-with-vat') + " kr: " + ticketsTotalVat))
                    );


                } else {
                    errorNoTicket = orderForm.attr("data-error-no-ticket");
                    prependAlert('#div_order_buttons', errorNoTicket, 'danger', null, '#div_order_buttons');
                }

            }


            function startReservation() {
                removeDiscountCookie();
                orderButton.hide();
                cancelButton.hide();
                nextButton.show();
                orderForm.hide();
                hideOrderForm.hide();
                orderSummary.hide();
                orderTerms.hide();

                if ($(eventTicketCategoryList).hasClass('d-none')) {
                    $(eventTicketCategoryList).removeClass('d-none');
                }
                $(eventTicketCategoryList).show();
                if ($('#event-ticket-category-area-zone').length && $('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').removeClass('d-none');
                }

            }


            function continueReservation() {

                let expirationTime = parseInt(countDownId.attr("data-reservation-remaining-time")) * 1000;

                if (orderButton.hasClass('d-none')) {
                    orderButton.removeClass('d-none');
                }

                if (cancelButton.hasClass('d-none')) {
                    cancelButton.removeClass('d-none');
                }

                if (hideOrderForm.hasClass('d-none')) {
                    hideOrderForm.removeClass('d-none');
                }

                if (!$('#event-ticket-category-area-zone').hasClass('d-none')) {
                    $('#event-ticket-category-area-zone').addClass('d-none');
                }

                if (!$('#one-area-zone-next-button').hasClass('d-none')) {
                    $('#one-area-zone-next-button').addClass('d-none');
                }

                hideOrderForm.show();
                let discountCookie = getCookie('discount');
                if (discountCookie !== undefined && discountCookie !== null && discountCookie != '') {
                    discountCookie = JSON.parse(discountCookie);
                    reservationOk(discountCookie);
                } else {
                    reservationOk();
                }
                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);

            }


            function startOrder(formDataReservation) {
                if (orderButton.hasClass('d-none')) {
                    orderButton.removeClass('d-none');
                }

                if (cancelButton.hasClass('d-none')) {
                    cancelButton.removeClass('d-none');
                }

                if (hideOrderForm.hasClass('d-none')) {
                    hideOrderForm.removeClass('d-none');
                }

                if ($('#reservation-alert-box').length !== 0) {
                    $('#reservation-alert-box').remove();
                }

                if (reservationCountDownInterval !== null) {
                    clearInterval(reservationCountDownInterval);
                    reservationCountDownInterval = null;
                    countDownId.text(expirationText + expirationTimeText);
                }

                hideOrderForm.show();

                if (!reservation_ajax_lock) {

                    reservation_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order/reservation",
                        data: formDataReservation,
                        async: true,
                        cache: false,
                        processData: false,
                        contentType: false
                    })
                        .done(function (data) {

                            if (data.expiration !== undefined && data.expiration !== null) {
                                if (!$('#restriction-code-form').hasClass('d-none')) {
                                    $('#restriction-code-form').addClass('d-none');
                                }
                                if (!$('#restriction-code-form-button-div').hasClass('d-none')) {
                                    $('#restriction-code-form-button-div').addClass('d-none');
                                }
                                if (!$(eventTicketCategoryList).hasClass('d-none')) {
                                    $(eventTicketCategoryList).addClass('d-none');
                                }
                                if (data.discount !== undefined && data.discount !== null) {
                                    addDiscountCookie(data.discount);
                                    reservationOk(data.discount);
                                } else {
                                    reservationOk();
                                }
                                reservationCountDownInterval = reservationCountDown(expirationTime, countDownId, expirationText);

                                if (data.event_form_html !== undefined && data.event_form_html !== null) {
                                    $('#event-form').html(data.event_form_html);
                                }

                            }

                        })
                        .fail(function (data) {

                            reservation_ajax_lock = false;

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_order_buttons', data.responseJSON.error_message, 'danger', null, '#order_register_form')
                            }

                        })
                        .always(function () {
                            reservation_ajax_lock = false;
                        });

                }
            }

            function removeDiscountCookie() {
                let daysForCookieToExpire = 0;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "discount = ;expires=" + cookieExpires + "; path=/";
            }

            function addDiscountCookie(discount) {
                let daysForCookieToExpire = 1;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "discount = " + JSON.stringify(discount) + ";expires=" + cookieExpires + "; path=/";
            }

            function getCookie(cname) {
                let name = cname + "=";
                let decodedCookie = decodeURIComponent(document.cookie);
                let ca = decodedCookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }

            $('div.event-form-question-wrapper select').select2({
                theme: 'bootstrap4',
            });

            function setSceneHeight(maxRows) {
                $("#scene-wrapper-order").css("height", 200 + (30 * maxRows));
                $("#scene-wrapper-order #seats").css("height", 100 + (30 * maxRows));
            }

            function generateSeats(seatCoordinates, maxSeatsPerRow = 0) {

                let svgContainer = d3.select("#seats").append("svg").attr("width", Math.max(((maxSeatsPerRow * 30) - 10), 0) + "px").attr("height", "100%").attr("class", "generated-seats");
                let circles = svgContainer.selectAll("circle").data(seatCoordinates).enter().append("circle");

                let circleAttributes = circles.attr("cx", function (d) {
                    return d.coordinate_x;
                }).attr("cy", function (d) {
                    return d.coordinate_y;
                }).attr("r", function (d) {
                    return d.radius;
                }).style("fill", function (d) {
                    return d.color;
                }).attr("id", function (d) {
                    return d.id;
                }).attr("rating", function (d) {
                    return d.rating;
                }).attr("status", function (d) {
                    return d.status;
                }).attr("reserved", function (d) {
                    return d.reserved;
                }).attr("row_info", function (d) {
                    return d.row_info;
                }).attr("seat_info", function (d) {
                    return d.seat_info;
                }).attr("class", function (d) {
                    return d.class;
                });

            }

            function removeValueFromArray(array, value) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i] === value) {
                        array.splice(i, 1);
                    }
                }
                return array;
            }

            $(document).on("click", ".generated-seat", function () {
                if ($(this).attr('status') == 0) {
                    return false;
                }
                let color, reserved;
                if ($(this).attr('reserved') == 1) {
                    $(".alert", $("#order_register_form")).remove();
                    if (numberOfSeatsReserved > 0) {
                        numberOfSeatsReserved = numberOfSeatsReserved - 1;
                    }
                    reserved = 0;
                    color = generateColor(reserved);
                    $(this).attr("reserved", reserved);
                    $(this).attr("style", "fill:" + color);
                    console.log(seatIdArray);
                    seatIdArray = removeValueFromArray(seatIdArray, $(this).attr('id') * 1);
                    console.log(seatIdArray);
                } else if ($(this).attr('reserved') == 0) {
                    if (numberOfSeatsReserved < numberOfSeats) {
                        numberOfSeatsReserved = numberOfSeatsReserved + 1;
                    } else {
                        let removeSeatMessage = $('#div_order_buttons').attr('data-remove-seat-message');
                        prependAlert('#div_order_buttons', removeSeatMessage, 'warning', null, '#order_register_form');
                        return false;
                    }
                    reserved = 1;
                    color = generateColor(reserved);
                    $(this).attr("reserved", reserved);
                    $(this).attr("style", "fill:" + color);
                    console.log(seatIdArray);
                    seatIdArray.push($(this).attr('id') * 1);
                    console.log(seatIdArray);
                }

            });

            function generateColor(status) {
                let color;
                switch (status) {
                    case 0:
                        color = "#28a745";
                        break;
                    case 1:
                        color = "#007bff";
                        break;
                    default:
                        color = "#28a745";
                }
                return color;
            }

            $(document).on("click", "#area-zone-seat-icon", function () {
                reopenAreaZone();
            });

            $(document).on("click", "#area-zone-seat-link", function () {
                reopenAreaZone();
            });

            function reopenAreaZone() {
                if ($('#scene-wrapper-order').hasClass('d-none')) {
                    $('#scene-wrapper-order').removeClass('d-none');
                }
                if (!$('#hide-order-form').hasClass('d-none')) {
                    $('#hide-order-form').addClass('d-none');
                }
                if (!$('#order-cancel-button').hasClass('d-none')) {
                    $('#order-cancel-button').addClass('d-none');
                }
                if (!$('#order-register-button').hasClass('d-none')) {
                    $('#order-register-button').addClass('d-none');
                }
                if ($('#order-next-button').hasClass('d-none')) {
                    $('#order-next-button').removeClass('d-none');
                }
                if ($('#order-next-button').css('display', 'none')) {
                    $('#order-next-button').css('display', 'inline-block');
                }
            }


        }

    }
);

