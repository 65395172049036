$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_extra_template_item' && a === 'list') {

            $(document).on("click", ".product-extra-template-item-delete-button", function () {
                let productExtraTemplateItemId = $(this).attr('data-product-extra-template-item-id');
                let productExtraTemplateId = $(this).attr('data-product-extra-template-id');
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra_template_item/delete",
                    data: {
                        product_extra_template_item_id: productExtraTemplateItemId,
                        product_extra_template_id: productExtraTemplateId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#product-extra-template-item-list-sortable').empty();
                            $('#product-extra-template-item-list-sortable').append(data.html);
                            popupAlert();
                        }

                        if (data.product !== undefined && data.product !== null && data.product_id !== undefined && data.product_id !== null) {
                            $("select[name='product_id']").prepend($('<option>', {
                                value: data.product_id,
                                text: data.product
                            }));
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);