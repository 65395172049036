$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p == "organizer_product_category_self_service" && a == "list") {
    let product_category_modal_lock = false;

    $(document).on("click", "#product-category-new-button", function () {
      let locationId = $("body").attr("event_id");
      getProductCategoryModal(locationId);
    });

    $(document).on("click", ".product-category-update-button", function () {
      let locationId = $("body").attr("event_id");
      let productCategoryId = $(this).attr("data-product-category-id");
      getProductCategoryModal(locationId, productCategoryId);
    });

    function getProductCategoryModal(locationId, productCategoryId = null) {
      if ($("#product-category-self-service-registration-form-modal").length) {
        $("#product-category-self-service-registration-form-modal").remove();
      }

      if (!product_category_modal_lock) {
        product_category_modal_lock = true;
        $.ajax({
          type: "POST",
          url: "/ajax/organizer_product_category_self_service/get_modal",
          data: {
            location_id: locationId,
            product_category_id: productCategoryId,
          },
        })
          .done(function (data) {
            if (data.html !== undefined && data.html !== null) {
              $("#content").append(data.html);
              $("#product-category-self-service-registration-form-modal").modal(
                "show"
              );
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }
            product_category_modal_lock = false;
          })
          .always(function () {
            product_category_modal_lock = false;
          });
      }
      return false;
    }
  }
});
