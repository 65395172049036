$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let orderId = $("body").attr("order_id") || null;
  const event_id = $("body").attr("event_id");

  if (p == "customer") {
    $(document).on("input", "input[type='number']", function () {
      if (this.value.length > 1 && this.value.indexOf(0) == 0) {
        $(this).val(Math.abs(this.value));
      }
    });

    $(document).on("keydown", "input[type='number']", function (event) {
      if (event.key === "-") {
        event.preventDefault();
      }
    });
  }

  if (p == "customer" && a == "new") {
    $(document).on("change", ".customer-type", function () {
      if (this.value == 0) {
        $(".company-customer").addClass("d-none");
        $("#company-name").removeAttr("required");
        $(".private-customer").removeClass("d-none");
        $(".private-name").attr("required", true);
      }

      if (this.value == 1) {
        $(".private-customer").addClass("d-none");
        $(".private-name").removeAttr("required");
        $(".company-customer").removeClass("d-none");
        $("#company-name").attr("required", true);
      }
    });

    $(document).on("submit", "#customer_form", function (e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      formData.append("location_id", event_id);

      $.ajax({
        type: "POST",
        url: `/ajax/customer/new${orderId ? `?order_id=${orderId}` : ""}`,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (
            data.message !== undefined &&
            data.message !== null &&
            data.customer_id !== undefined &&
            data.customer_id !== null
          ) {
            prependAlert(
              "#div_customer_form_button",
              data.message,
              "success",
              null,
              "#customer_form"
            );

            $(".card-box-to-hide").hide();
            $("#customer_form_button").hide();

            if (data.redirect_url) {
              setTimeout(function () {
                document.location.href = data.redirect_url;
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href = "/location/" + event_id + "/customer/";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_customer_form_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#customer_form"
            );
          }
        });
    });
  }

  // UPDATE
  if (p == "customer" && a == "update") {
    $(document).on("change", ".customer-type", function () {
      if (this.value == 0) {
        $(".company-customer").addClass("d-none");
        $("#company-name").removeAttr("required");
        $(".private-customer").removeClass("d-none");
        $(".private-name").attr("required", true);
      }

      if (this.value == 1) {
        $(".private-customer").addClass("d-none");
        $(".private-name").removeAttr("required");
        $(".company-customer").removeClass("d-none");
        $("#company-name").attr("required", true);
      }
    });

    $(document).on("submit", "#customer_form", function (e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      formData.append("location_id", event_id);

      $.ajax({
        type: "POST",
        url: "/ajax/customer/update",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert(
              "#div_customer_form_button",
              data.message,
              "success",
              null,
              "#customer_form"
            );
            $(".card-box-to-hide").hide();
            $("#customer_form_button").hide();

            setTimeout(function () {
              document.location.href = "/location/" + event_id + "/customer/";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_customer_form_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#customer_form"
            );
          }

          event_ticket_category_update_ajax_lock = false;
        });
    });
  }

  if (p == "customer" && a == "list") {
    $(document).on("click", ".customer-delete", function () {
      $(".alert").remove();

      let customer_id = $(this).attr("data-delete-id");

      $.ajax({
        type: "POST",
        url: "/ajax/customer/delete",
        data: {
          customer_id,
          location_id: event_id,
        },
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            alert(data.message);
          } else {
            $("tr#customer-" + customer_id).fadeOut(1000);
            setTimeout(function () {
              $("tr#customer-" + customer_id).remove();
            }, 1000);

            // Mobile fadeOut.
            $("#mobile-customer-" + customer_id).fadeOut(1000);
            setTimeout(function () {
              $("#mobile-customer-" + customer_id).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#customer-" + customer_id).after(
              '<tr><td colspan="6"><div class="alert alert-danger alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>' +
                data.responseJSON.error_message +
                "</div></td></tr>"
            );
          }
        });

      return false;
    });
  }
});
