$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_event_customers' || p == 'organizer_event_subevent_customers') {
            $(document).on("submit", "#ticket_search_form", function () {

                let employer_token_param = '';
                if ($("body").attr("employer_token") != undefined) {
                    let employer_token = $("body").attr("employer_token");
                    employer_token_param = '&employer_token=' + employer_token
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_customers/search",
                    data: {
                        serialized_post: $(this).serialize() + employer_token_param
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("#ticket-list").remove();
                            $(".pagination").remove();
                            $("#ticket-list-search").after(data.html);
                        }

                        $('[data-toggle="popover"]').popover();
                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });

                return false;

            });

        }
    }
);