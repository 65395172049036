$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_booking_reservation' && (a === 'embed' || a === 'new' || a === 'list')) {

            $(document).on("click", ".booking-reservation-get-seats-button", function () {
                let numberOfCustomers = parseInt($("#seats").text());
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_seats",
                    data: {
                        seats: numberOfCustomers
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-wrapper').empty();
                            $('#booking-reservation-wrapper').append(data.html);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })

                return false;
            });

            $(document).on("click", ".add-booking-reservation", function () {
                let numberOfCustomers = parseInt($("#seats").val()) + 1;
                $("#seats").val(numberOfCustomers);
            });

            $(document).on("click", ".remove-booking-reservation", function () {
                let numberOfCustomers = parseInt($("#seats").val()) - 1;
                if (numberOfCustomers < 0) {
                    numberOfCustomers = 0;
                }
                $("#seats").val(numberOfCustomers);
            });

            $(document).on("click", ".booking-reservation-get-calendar-button", function () {
                let numberOfCustomers = parseInt($("#seats").text());
                let reservationDate = $(this).attr('data-reservation-date');
                getCalendar(numberOfCustomers, reservationDate);
            });

            $(document).on("click", "#get-booking-reservation-calender-button", function () {
                let numberOfCustomers = parseInt($("#seats").val());
                getCalendar(numberOfCustomers);
            });

            function getCalendar(numberOfCustomers, reservationDate = null) {
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_calender",
                    data: {
                        seats: numberOfCustomers,
                        location_id: locationId,
                        reservation_date: reservationDate
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-wrapper').empty();
                            $('#booking-reservation-wrapper').append(data.html);
                            addCalendar();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null && $('#booking-reservation-form-button-wrapper').length) {
                            prependAlert('#booking-reservation-form-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-reservation-form');
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null && $('#get-booking-reservation-calender-button-wrapper').length) {
                            prependAlert('#get-booking-reservation-calender-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-reservation-choose-number-of-seats');
                        }
                        get - booking - reservation - calender - button

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            }

            $(document).on("change", "#reservation-date", function () {
                let numberOfCustomers = parseInt($("#seats").text());
                let reservationDate = $(this).val();
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_time",
                    data: {
                        seats: numberOfCustomers,
                        location_id: locationId,
                        reservation_date: reservationDate
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-time-wrapper').empty();
                            $('#booking-reservation-time-wrapper').append(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });

            $(document).on("click", ".reservation-time", function () {
                let numberOfCustomers = parseInt($("#seats").text());
                let locationId = $("body").attr("event_id");
                //let reservationDate = $("#reservation-date").val();
                let reservationDate = $('#booking-reservation-calendar').attr('data-reservation-time');
                let reservationTime = $(this).attr('data-reservation-time');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/reservation",
                    data: {
                        seats: numberOfCustomers,
                        location_id: locationId,
                        reservation_date: reservationDate,
                        reservation_time: reservationTime
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-wrapper').empty();
                            $('#booking-reservation-wrapper').append(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('div.reservation-time-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-reservation-time-wrapper');
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });

            $(document).on("submit", "#booking-reservation-form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/new",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-wrapper').empty();
                            $('#booking-reservation-wrapper').append(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#booking-reservation-form-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-reservation-form');
                        }

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });


            function addCalendar(openingHoursExceptionDates) {
                let todayDate = $('#booking-reservation-calendar').attr('data-date');
                let reservationDate = $('#booking-reservation-calendar').attr('data-reservation-time');
                let calendarEl2 = document.getElementById('booking-reservation-calendar');
                let calendar2 = new FullCalendar.Calendar(calendarEl2, {
                    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                    themeSystem: 'standard',
                    initialView: 'dayGridMonth',
                    initialDate: todayDate,
                    firstDay: 1,
                    height: 'auto',
                    headerToolbar: {
                        left: null,
                        center: 'title',
                        right: 'prev,next'
                    },
                    buttonText: {
                        today: 'i dag',
                        month: 'måned',
                        week: 'uke',
                        day: 'dag',
                        list: 'list'
                    },
                    dateClick: function (info) {

                        $('td.fc-day').each(function () {
                            $(this).css({'background-color': ''});
                        });
                        info.dayEl.style.backgroundColor = '#DADFE4';

                        let classList = info.dayEl.classList;
                        for (let i = 0; i < classList.length; i++) {
                            if (classList[i] == 'fc-day-past') {
                                if (!$('#booking-reservation-time-wrapper').hasClass('d-none')) {
                                    $('#booking-reservation-time-wrapper').addClass('d-none');
                                }
                                return false;
                            }
                        }

                        if ($('#booking-reservation-time-wrapper').hasClass('d-none')) {
                            $('#booking-reservation-time-wrapper').removeClass('d-none');
                        }

                        $('#booking-reservation-calendar').attr('data-reservation-time', info.dateStr)
                        getTime(info.dateStr);
                    }
                });
                calendar2.setOption('locale', 'NO-nb');
                calendar2.render();

                $('td.fc-day-past').each(function () {
                    if (!$(this).hasClass('fc-day-other')) {
                        $(this).addClass('fc-day-other');
                    }
                });

                if (reservationDate != todayDate) {
                    calendar2.select(reservationDate);
                    $('.fc-day').each(function () {
                        if ($(this).attr('data-date') == reservationDate && reservationDate != todayDate) {
                            $(this).css({'background-color': '#DADFE4'});
                        }
                    });
                }

            }

            function getTime(reservationDate) {
                let numberOfCustomers = parseInt($("#seats").text());
                let locationId = $("body").attr("event_id");
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_time",
                    data: {
                        seats: numberOfCustomers,
                        location_id: locationId,
                        reservation_date: reservationDate
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-time-wrapper').empty();
                            $('#booking-reservation-time-wrapper').append(data.html);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
                return false;
            }


        }

        if (p === 'organizer_booking_reservation' && a === 'list') {

            $('div#content-body div#admin-content-frame #admin-content.collapsed').css('padding-left', 0);
            $('div#content-body div#admin-content-frame #admin-content').css('max-width', '100%');


            $(document).on("click", "#new-booking-reservation-button", function () {

                if ($('#modal-booking-reservation').length) {
                    $('#modal-booking-reservation').remove();
                }

                let locationId = $(this).attr('data-location-id');
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_modal",
                    data: {
                        location_id: locationId

                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#content').append(data.html);
                            $('#modal-booking-reservation').modal('show');
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            });


            $(document).on("change", "#reservation-date", function () {
                let locationId = $("body").attr("event_id");
                let reservationDate = $(this).val();
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/list",
                    data: {
                        location_id: locationId,
                        reservation_date: reservationDate
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#booking-reservation-list').empty();
                            $('#booking-reservation-list').append(data.html);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;
            });


        }


    }
);