$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let filterBy = $("body").attr("filter_by") || null;
  let startDateQuery = $("body").attr("start_date") || null;
  let endDateQuery = $("body").attr("end_date") || null;
  let customPeriodQuery = $("body").attr("custom_period") || null;
  let prevSelectedPeriodQuery = $("body").attr("prev_selected_period") || null;

  let locationId = $("body").attr("event_id");

  moment.locale("nb");
  moment.tz.setDefault("Europe/Oslo");
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the start of the week
    },
    months:
      "Januar_Februar_Mars_April_Mai_Juni_Juli_August_September_Oktober_November_Desember".split(
        "_"
      ),
    monthsShort: "Jan_Feb_Mars_Apr_Mai_Juni_Juli_Aug_Sep_Okt_Nov_Des".split(
      "_"
    ),
    monthsParseExact: true,
    weekdays: "Søndag_Mandag_Tirsdag_Onsdag_Torsdag_Fredag_Lørdag".split("_"),
    weekdaysShort: "Søn_Man_Tir_Ons_Tor_Fre_Lør".split("_"),
    weekdaysMin: "sø_ma_ti_on_to_fr_lø".split("_"),
  });
  Highcharts.setOptions({
    lang: {
      weekdays: [
        "Søndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "Lørdag",
      ],
      shortWeekdays: ["Søn", "Man", "Tir", "Ons", "Tor", "Fre", "Lør"],
      months: [
        "Januar",
        "Februar",
        "Mars",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ],
    },
  });
  $("#period-title").text(moment().format("ddd - DD.MM.YYYY"));
  if (p === "statistic_order") {
    let startDate = moment().format("YYYY-MM-DD") + "T00:00";
    let endDate = moment().format("YYYY-MM-DD") + "T23:59";
    let period = "day";
    let supplierId = $("#supplier-id").val();
    let productGroupUniqueId = $("#product-group-id").val();
    let topProductGroupUniqueId = $("#top-product-group-id").val();
    let userId = $("#user-id").val();
    let platform = "all";
    let prevSelectedPeriod = "day";
    let isDateChanged = false;
    let durationOfstartAndEndDate = 0;
    let customPeriod = "";
    let isMobile = false;
    let isTablet = false;
    let isDesktop = false;
    var deviceWidth = $(window).width();

    if (deviceWidth < 768) {
      isMobile = true;
    } else if (deviceWidth >= 768 && deviceWidth < 992) {
      isTablet = true;
    } else {
      isDesktop = true;
    }

    if (filterBy) {
      let periodTitleOfFilterBy = "";
      prevSelectedPeriod = prevSelectedPeriodQuery
        ? prevSelectedPeriodQuery
        : prevSelectedPeriod;
      if (filterBy === "week") {
        $("#period-week").prop("checked", true);
        period = "week";
        prevSelectedPeriod = "week";
        customPeriod = customPeriodQuery ? customPeriodQuery : "day";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
        periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
      } else if (filterBy === "day") {
        $("#period-day").prop("checked", true);
        customPeriod = customPeriodQuery ? customPeriodQuery : "time";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
        $(`#custom-period option[value="time"]`).prop("selected", true);

        periodTitleOfFilterBy = moment
          .utc(startDate)
          .format("ddd - DD.MM.YYYY");
      } else if (filterBy === "month") {
        $("#period-month").prop("checked", true);
        period = "month";
        prevSelectedPeriod = "month";
        startDate = startDateQuery
          ? startDateQuery
          : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
        endDate = endDateQuery
          ? endDateQuery
          : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        periodTitleOfFilterBy = moment(startDate).format("MMMM");
      } else {
        period = "custom";
        customPeriod = customPeriodQuery ? customPeriodQuery : customPeriod;
        prevSelectedPeriod = prevSelectedPeriodQuery
          ? prevSelectedPeriodQuery
          : prevSelectedPeriod;
        startDate = startDateQuery;
        endDate = endDateQuery;
        $("#period-custom").prop("checked", true);
        $(`#custom-period option[value=${customPeriod}]`).prop(
          "selected",
          true
        );
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        if (prevSelectedPeriod === "day") {
          periodTitleOfFilterBy = moment
            .utc(startDate)
            .format("ddd - DD.MM.YYYY");
        } else if (prevSelectedPeriod === "week") {
          periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
        } else if (prevSelectedPeriod === "month") {
          periodTitleOfFilterBy = moment(startDate).format("MMMM");
        } else {
          if (startDate.split("T")[0] === endDate.split("T")[0]) {
            $("#custom-period").prop("disabled", true);
          } else {
            $("#custom-period option[value='time']").addClass("disabledOption");
            $("#custom-period option[value='time']").prop("disabled", true);
          }

          periodTitleOfFilterBy =
            moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY");
        }
      }
      setTimeout(() => $("#period-title").text(periodTitleOfFilterBy), 50);
    } else {
      $("#period-day").prop("checked", true);
    }

    $(document).on("change", "#custom-period", function (e) {
      e.preventDefault();
      customPeriod = $(this).val();

      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod
      );
    });

    $(document).on("change", "#top-product-group-id", function (e) {
      e.preventDefault();
      $("#product-group-id").val(0);
      $("select[name=product-group-id]").select2({
        theme: "bootstrap4",
      });
      topProductGroupUniqueId = $(this).val();
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });

    $(document).on("change", "#supplier-id", function (e) {
      e.preventDefault();
      supplierId = $(this).val();
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });
    $(document).on("change", "#product-group-id", function (e) {
      e.preventDefault();
      $("#top-product-group-id").val(0);
      $("select[name=top-product-group-id]").select2({
        theme: "bootstrap4",
      });
      productGroupUniqueId = $(this).val();
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });
    $(document).on("change", "#user-id", function (e) {
      e.preventDefault();
      userId = $(this).val();
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });
    $(document).on("change", "#platform", function (e) {
      e.preventDefault();
      platform = $(this).val();

      if (platform === "self-service") {
        userId = 0;
        $("#user-id").val(userId);
        $("#user-id").prop("disabled", true);
      } else {
        $("#user-id").prop("disabled", false);
      }
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });
    $(document).on("change", 'input[type="radio"]', function (e) {
      period = $('input[type="radio"]:checked').val();
      prevSelectedPeriod = period !== "custom" ? period : prevSelectedPeriod;
      if (period === "day") {
        startDate = moment().format("YYYY-MM-DD") + "T00:00";
        endDate = moment().format("YYYY-MM-DD") + "T23:59";
        const currentDayFormatted = moment().format("ddd - DD.MM.YYYY");
        customPeriod = "time";
        $("#period-title").text(currentDayFormatted);
      } else if (period === "week") {
        startDate = moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
        endDate = moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
        const currentWeekNumber = moment().utc().isoWeek();
        customPeriod = "day";
        $(`#custom-period option[value="day"]`).prop("selected", true);
        $("#period-title").text("Uke nr" + " " + currentWeekNumber);
      } else if (period === "month") {
        startDate = moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
        endDate = moment().endOf("month").format("YYYY-MM-DD") + "T23:59";
        durationOfstartAndEndDate = 30;
        displayGraphDynamically(durationOfstartAndEndDate);
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        const currentMonth = moment().format("MMMM");
        $("#period-title").text(currentMonth);
      } else {
        $("#period-title").text("Custom");
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period").prop("disabled", false);
        }
      }
      customPeriod = $("#custom-period").val();
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      toggleCustomDatePicker();
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });

    $(document).on("change", "#end-date", function (e) {
      e.preventDefault();
      let currentendDate = $(this).val();
      if (dateAndPeriodValidation(startDate, currentendDate)) {
        isDateChanged = true;
        endDate = currentendDate;
        period = $('input[name="period"]:checked').val();
        if (startDate.split("T")[0] == endDate.split("T")[0]) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          durationOfstartAndEndDate = moment
            .utc(endDate)
            .diff(moment(startDate.split("T")[0]), "days");
          displayGraphDynamically(durationOfstartAndEndDate);

          $("#custom-period").prop("disabled", false);
        }

        getAmountOfOrderAndSale(
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId
        );
        prevSelectedPeriod = "custom";
        $("#period-title").text(
          moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY")
        );
      }
    });

    $(document).on("change", "#start-date", function (e) {
      e.preventDefault();
      let choosenDate = $(this).val();
      if (dateAndPeriodValidation(choosenDate, endDate)) {
        startDate = choosenDate;
        isDateChanged = true;
        period = $('input[name="period"]:checked').val();
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          durationOfstartAndEndDate = moment
            .utc(endDate.split("T")[0])
            .diff(moment(startDate), "days");
          displayGraphDynamically(durationOfstartAndEndDate);
          $("#custom-period").prop("disabled", false);
        }
        getAmountOfOrderAndSale(
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId
        );
        prevSelectedPeriod = "custom";
        $("#period-title").text(
          moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY")
        );
      }
    });

    $(document).on("click", "#previous-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "day").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment.utc(endDate).subtract(1, "day").format("YYYY-MM-DD") +
          "T23:59";
        periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "week").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment(endDate).subtract(1, "week").format("YYYY-MM-DD") + "T23:59";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "month").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment(endDate).subtract(1, "month").format("YYYY-MM-DD") + "T23:59";
        periodTitle = moment(startDate).format("MMMM");
      }
      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        endDate =
          moment(startDate).subtract(1, "day").format("YYYY-MM-DD") + "T23:59";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        startDate =
          moment
            .utc(endDate)
            .subtract(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + "T00:00";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate.split(" ")[0]);
      $("#end-date").val(endDate.split(" ")[0]);
      $("#period-title").text(periodTitle);
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });

    $(document).on("click", "#next-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment.utc(endDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";
        periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "week").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment(endDate).add(1, "week").format("YYYY-MM-DD") + "T00:00";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "month").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment(endDate).add(1, "month").format("YYYY-MM-DD") + "T00:00";
        periodTitle = moment(startDate).format("MMMM");
      }

      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        startDate =
          moment(endDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        endDate =
          moment
            .utc(startDate)
            .add(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + "T23:59";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      getAmountOfOrderAndSale(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId
      );
    });

    function eventLineChart(seriesData, interval = null) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column",
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 4, // Adjust line width as needed
          },
        },
        xAxis: {
          type: "datetime",
          tickInterval: interval,
          labels: {
            formatter: function () {
              if (startDate.split("T")[0] == endDate.split("T")[0]) {
                return Highcharts.dateFormat("%H:%M", this.value);
              } else if (customPeriod === "week") {
                return "Uke" + " " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat("%B", this.value);
              } else {
                return Highcharts.dateFormat("%e / %b", this.value);
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          title: {
            text: " ",
          },
        },
        series: [
          {
            name: "Ordrebeløp",
            data: seriesData,
          },
        ],
        tooltip: {
          formatter: function () {
            var tooltipText;
            if (
              period === "day" &&
              startDate.split("T")[0] == endDate.split("T")[0]
            ) {
              // Default formatting for datetime xAxis
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "Ordrebeløp: " +
                this.y;
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                this.x +
                "</b><br/>" +
                "Ordrebeløp: " +
                this.y;
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "Ordrebeløp: " +
                this.y;
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "Ordrebeløp: " +
                this.y;
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate.split("T")[0]);
        $("#end-date").val(endDate.split("T")[0]);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }
    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }
    function getAmountOfOrderAndSale(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_order/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
        },
      })
        .done(function (data) {
          if (data && data.length > 0) {
            let orderAmount = 0;
            $.map(data, function (item) {
              orderAmount += item.order_amount;
            });
            $("#key-figure-sale").text(orderAmount);
            $("#key-figure-order").text(orderAmount);
          } else {
            $("#key-figure-sale").text("0");
            $("#key-figure-order").text("0");
          }
          if (startDate.split("T")[0] == endDate.split("T")[0]) {
            getHourGraph(data);
          } else if (customPeriod === "week") {
            getWeekGraph(data);
          } else if (customPeriod === "month") {
            getMonthGraph(data);
          } else {
            getDayGraph(data);
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
    function getHourGraph(data) {
      let hourArray = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      let seriesData = [];
      $.map(hourArray, function (hour) {
        let found = $.grep(data, function (item) {
          return item.order_date === hour.toString();
        });

        let timestamp = moment
          .utc(startDate)
          .startOf("day")
          .add(hour, "hours")
          .valueOf();
        let totalOrderAmount = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalOrderAmount += ele.order_amount;
          });
        }
        let dataSet = [timestamp, totalOrderAmount];
        seriesData.push(dataSet);
      });
      seriesData.sort((a, b) => a[0] - b[0]);
      let interval = 3600 * 1000;
      eventLineChart(seriesData, interval);
    }
    function getDayGraph(data) {
      data.sort((a, b) => new Date(a.order_date) - new Date(b.order_date));
      let dailyTotals = {};
      data.forEach(function (item) {
        let date = item.order_date.split(" ")[0];
        if (dailyTotals[date]) {
          dailyTotals[date] += item.order_amount;
        } else {
          dailyTotals[date] = item.order_amount;
        }
      });

      // Prepare data for Highcharts
      let seriesData = [];
      let startedDate = new Date(startDate.split("T")[0]);
      let endedDate = new Date(endDate.split("T")[0]);
      endedDate.setHours(23, 59, 59, 0);

      while (startedDate <= endedDate) {
        let dateStr = startedDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
        let timestamp = startedDate.getTime();
        let total = dailyTotals[dateStr] || 0; // Use total from dailyTotals or 0 if not available
        seriesData.push([timestamp, total]);
        startedDate.setDate(startedDate.getDate() + 1); // Move to the next day
      }

      // Sort the data by timestamp
      seriesData.sort((a, b) => a[0] - b[0]);

      let interval = 24 * 3600 * 1000;
      eventLineChart(seriesData, interval);
    }

    function getWeekGraph(data) {
      data.sort(
        (a, b) => new Date(a.order_confirmed) - new Date(b.order_confirmed)
      );

      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let weekNumbers = [];

      while (startDay.isBefore(endDay)) {
        let weekNumber = startDay.isoWeek();
        weekNumbers.push(weekNumber);
        startDay.add(1, "day");
      }
      let seriesData = [];
      $.map(weekNumbers, function (week_number) {
        let found = $.grep(data, function (item) {
          return item.order_date === week_number.toString();
        });
        let totalOrderAmount = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalOrderAmount += ele.order_amount;
          });
        }
        let dataSet = [week_number, totalOrderAmount];
        seriesData.push(dataSet);
      });
      eventLineChart(seriesData);
    }

    function getMonthGraph(data) {
      data.sort(
        (a, b) => new Date(a.order_confirmed) - new Date(b.order_confirmed)
      );
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate = moment()
          .startOf("year")
          .add(month_number - 1, "month")
          .format("YYYY-MM-DD");
        let totalOrderAmount = 0;
        if (found.length > 0) {
          $.map(found, function (ele) {
            totalOrderAmount += ele.order_amount;
          });
        }

        let formatedDate = new Date(monthNumberToDate);
        let timestamp = formatedDate.getTime();
        let dataSet = [timestamp, totalOrderAmount];
        seriesData.push(dataSet);
      });
      eventLineChart(seriesData);
    }

    function displayGraphDynamically(durationOfstartAndEndDate) {
      if (isMobile) {
        if (durationOfstartAndEndDate <= 7) {
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 8 &&
          durationOfstartAndEndDate < 49
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else if (isTablet) {
        if (durationOfstartAndEndDate <= 14) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 15 &&
          durationOfstartAndEndDate < 98
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else {
        if (durationOfstartAndEndDate <= 31) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 32 &&
          durationOfstartAndEndDate < 217
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      }
    }

    getAmountOfOrderAndSale(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId
    );
    toggleCustomDatePicker();
  }
});
