$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && a === 'update') {

            $(document).on("click", ".delete-product-image", function () {

                let location_id = $(this).attr("data-location-id");
                let product_id = $(this).attr("data-product-id");
                let confirm_message = $(this).data("confirm-message");

                if (!confirm(confirm_message)) {
                    return false;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_ticket_category/delete_image",
                    data: {
                        event_id: location_id,
                        event_ticket_category_id: product_id
                    }
                })
                    .done(function (data) {

                        if (data.message !== undefined && data.message !== null) {
                            popupAlert();
                            setTimeout(function () {
                                document.location.href = "/location/" + location_id + "/product/" + product_id + "/update";
                            }, 1500);
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

                return false;

            });
        }
    }
);