$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_category' && a == 'list') {

            $(document.body).on('click', ".location_type", function () {
                let product_category_id = $(this).attr('data-product-category-id');
                let location_type = $(this).attr('data-location-type');
                let location_type_status = $(this).attr('data-location-type-status');

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_category/update_location_type",
                    data: {
                        product_category_id,
                        location_type,
                        location_type_status
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            let status = data.html.location_type_status;
                            let button = $('#location-type-button-product-category-id-' + data.html.product_category_id + ' button[data-location-type="' + data.html.location_type + '"]');
                            if (status == 1 && button.hasClass('btn-secondary')) {
                                button.removeClass('btn-secondary');
                            }
                            if (status == 1 && !button.hasClass('btn-primary')) {
                                button.addClass('btn-primary');
                            }
                            if (status == 0 && button.hasClass('btn-primary')) {
                                button.removeClass('btn-primary');
                            }
                            if (status == 0 && !button.hasClass('btn-secondary')) {
                                button.addClass('btn-secondary');
                            }
                            button.attr('data-location-type-status', status);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    });