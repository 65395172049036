$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_extra' && a == 'list') {

            let product_extra_modal_lock = false;

            $(document).on("click", "#product-extra-text-new-button", function () {

                let locationId = $("body").attr("event_id");
                let productId = $(this).attr("data-product-id");

                if ($('#product-extra-registration-form-modal').length) {
                    $('#product-extra-registration-form-modal').remove();
                }

                if (!product_extra_modal_lock) {
                    product_extra_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_extra/get_modal",
                        data: {
                            location_id: locationId,
                            product_id: productId
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#content').append(data.html);
                                $('#product-extra-registration-form-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            product_extra_modal_lock = false;

                        })
                        .always(function () {
                            product_extra_modal_lock = false;
                        });
                }
                return false;
            });

        }
    }
);