$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let locationId = $("body").attr("event_id");
  let isReportview = true;

  if (p === "product_order" && a === "list" && isReportview) {
    let reportId = null;
    let reportStatus = null;
    let reportUserId = null;
    let page = 1;
    let currentType = "order";
    let location_id = $("body").attr("event_id");
    let search_string = "";
    let order_by = "name";
    let order_by_direction = "asc";
    let supplierId = $("#order-report-supplier-selector").val();

    $(document).on("click", ".order-report-btn", function (e) {
      reportId = $(this).attr("data-report-id");
      reportStatus = $(this).attr("data-report-status");
      reportUserId = $(this).attr("data-report-user-id");

      $("#filtered-form")
        .attr("data-report-id", reportId)
        .attr("data-report-user-id", reportUserId)
        .attr("data-report-status", reportStatus);

      getReportHistory(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        currentType,
        reportId,
        reportUserId,
        reportStatus,
        supplierId,
        p
      );
    });

    $(document).on("submit", "#product-order-report-search-form", function (e) {
      e.preventDefault();
      page = 1;
      search_string = $("#report_search_string").val();

      getReportHistory(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        currentType,
        reportId,
        reportUserId,
        reportStatus,
        supplierId,
        p
      );
    });

    $(document).on("change", "#order-report-supplier-selector", function (e) {
      e.preventDefault();
      supplierId = $(this).val();
      search_string = $("#report_search_string").val();
      page = 1;
      getReportHistory(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        currentType,
        reportId,
        reportUserId,
        reportStatus,
        supplierId,
        p
      );
    });

    $(document).on("click", "#save-inventory-order-confirm", function (e) {
      e.preventDefault();
      const reportId = $("#product-order-table").attr("data-report-id");
      const formData = new FormData();
      formData.append("type", "order");
      formData.append("current_page", p);
      formData.append("location_id", locationId);
      formData.append("report_id", reportId);
      saveStockTakingAdd(formData);
      $(".modal").modal("hide");
    });

    $(document).on(
      "click",
      ".product-order-report-pagination .pagination a",
      function (e) {
        e.preventDefault();
        page = parseInt($(this).attr("href").substring(6));
        getReportHistory(
          location_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          0,
          currentType,
          reportId,
          reportUserId,
          reportStatus,
          supplierId,
          p
        );
      }
    );

    $(document).on("click", ".product-order-report-sort", function (e) {
      e.preventDefault();
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      getReportHistory(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        currentType,
        reportId,
        reportUserId,
        reportStatus,
        supplierId,
        p
      );
    });

    function saveStockTakingAdd(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/save_stock_taking",
        data: formData,
        enctype: "application/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message && data.message.title && data.message.description) {
            prependAlert(
              "#message",
              data.message.description,
              "success",
              data.message.title
            );
            $("#message:hidden").show();
            $("#product-list").hide();
            $("html,body").scrollTop(0);

            setTimeout(function () {
              document.location.href =
                "/location/" + locationId + "/inventory/product_order";
            }, 1000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getReportHistory(
      location_id,
      page = 1,
      order_by,
      order_by_direction,
      search_string = "",
      product_type,
      type,
      report_id,
      report_user_id,
      report_status,
      supplier_id = null,
      current_page
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/search",
        data: {
          location_id,
          page,
          search_string,
          order_by,
          order_by_direction,
          product_type,
          type,
          report_id,
          report_user_id,
          report_status,
          supplier_id,
          current_page,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#search_string").val("");
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
