$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_settings_term' && a === 'new') {

            let term_new_ajax_lock = false;

            $(document).on("submit", "#term_registration_form", function (e) {

                e.preventDefault();

                if (!term_new_ajax_lock) {

                    term_new_ajax_lock = true;

                    let organizer_id = $("#organizer_id").val();
                    let editor = tinymce.get('term-tinymce');
                    let term = editor.getContent();

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_settings_term/new",
                        data: {
                            organizer_id: organizer_id,
                            term: term
                        }
                    })
                        .done(function (data) {

                            if (data.message !== undefined && data.message !== null) {
                                prependAlert('#div_term_registration_button', data.message, 'success', null, '#term_registration_form')
                            }
                            setTimeout(function () {
                                document.location.href = "/settings/term";
                            }, 1500);

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url != null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#div_term_registration_button', data.responseJSON.error_message, 'danger', null, '#term_registration_form')
                            }

                            term_new_ajax_lock = false;

                        })
                        .always(function () {
                            term_new_ajax_lock = false;
                        });

                }

            });

        }

    }
);