$(document).ready(function () {
  let p = $("body").attr("p");

  if (p === "organizer_settings_users") {
    let organizer_new_ajax_lock = false;
    let currentModuleIds = [];
    let currentModuleIdLock = false;

    $(document).on("show.bs.select", ".modules-selector", function () {
      if (!currentModuleIdLock) {
        currentModuleIdLock = true;
        currentModuleIds = $(this).val() || [];
      }
    });

    $(document).on("hidden.bs.select", ".modules-selector", function () {
      if (!organizer_new_ajax_lock) {
        organizer_new_ajax_lock = true;
        let moduleIds = $(this).val() || [];
        let userId = $(this).attr("data-user-id");

        if (arraysEqual(currentModuleIds, moduleIds)) {
          setTimeout(function () {
            organizer_new_ajax_lock = false; // Reset the lock if no changes were made
          }, 1000);

          return;
        }

        $.ajax({
          type: "POST",
          url: "/ajax/organizer_settings_users/update_module",
          data: {
            userId: userId,
            moduleIds: moduleIds,
          },
        })
          .done(function (data) {
            popupAlert();

            setTimeout(function () {
              currentModuleIdLock = false;
            }, 500);
          })

          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }

            if (
              data.responseJSON.error_message !== undefined &&
              data.responseJSON.error_message !== null
            ) {
              prependAlert(
                "#div_organizer_settings_button",
                data.responseJSON.error_message,
                "danger",
                null,
                "#organizer_settings_form"
              );
            }
          })
          .always(function () {
            organizer_new_ajax_lock = false; // Reset the lock after the request completes
          });
      }
    });

    function arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      // Sort arrays to ensure comparison is not order-dependent
      a = a.slice().sort();
      b = b.slice().sort();

      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  }
});
