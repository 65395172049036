$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  if (p === "product_order" && a === "list") {
    let isAutoFill = false;

    //filter by supplier
    $(document).on("change", "#supplier-selector", function (e) {
      e.preventDefault();
      let isChecked = $("#auto-fill-switch").prop("checked");
      isAutoFill = isChecked ? true : false;
      let searchString = $("#search_string").val();
      let supplierId = $(this).val();
      let productOrderStatus = $("#product_order_status").val();
      const formData = new FormData($("#filtered-form")[0]);
      formData.append("location_id", locationId);
      formData.append("supplier_id", supplierId);
      formData.append("search_string", searchString);
      formData.append("page", 1);
      formData.append("current_page", p);
      formData.append("product_type", 0);
      formData.append("type", "order");
      formData.append("product_order_status", productOrderStatus);
      getproductList(formData);
    });

    //filter by order
    $(document).on("change", "#product_order_status", function (e) {
      e.preventDefault();
      let isChecked = $("#auto-fill-switch").prop("checked");
      isAutoFill = isChecked ? true : false;
      let searchString = $("#search_string").val();
      let supplierId = $("#supplier-selector").val();
      let productOrderStatus = $(this).val();
      const formData = new FormData($("#filtered-form")[0]);
      formData.append("location_id", locationId);
      formData.append("supplier_id", supplierId);
      formData.append("search_string", searchString);
      formData.append("page", 1);
      formData.append("current_page", p);
      formData.append("product_type", 0);
      formData.append("type", "order");
      formData.append("product_order_status", productOrderStatus);
      getproductList(formData);
    });
    function getproductList(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/search",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          if (isAutoFill) {
            $("#auto-fill-switch").prop("checked", true);
          } else {
            $("#auto-fill-switch").prop("checked", false);
          }
          $("#product-filter-list").hide();
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
