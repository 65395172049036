$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_extra_template_item' && a === 'list') {

            $(document).on("click", "#product-extra-template-item-register-button", function () {
                let productExtraTemplateId = $(this).attr('data-product-extra-template-id');
                let productId = $("select[name='product_id'] option:selected").val();
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra_template_item/new",
                    data: {
                        product_extra_template_id: productExtraTemplateId,
                        product_id: productId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("select[name='product_id'] option:selected").remove();
                            $('#product-extra-template-item-list-sortable').empty();
                            $('#product-extra-template-item-list-sortable').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

            $(document).on("submit", "#product-extra-template-item-text-registration-form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra_template_item/new",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#product-extra-template-item-text-registration-form-modal').modal('hide');
                            $('#product-extra-template-item-list-sortable').empty();
                            $('#product-extra-template-item-list-sortable').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });

        }

    }
);