$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "product_order" && a === "list") {
    $(document).on("change", ".product-order-amount-input", function (e) {
      e.preventDefault();
      const productId = $(this).attr("data-product-id");
      let list_type = $("#search-and-filter-container").attr("data-list-type");
      const value = $(this).val();
      const min = $(this).attr("min");
      const max = $(this).attr("max");
      const form = $(this).closest("form");
      const formData = new FormData(form[0]);
      formData.append("location_id", locationId);
      formData.append("product_id", productId);
      formData.append("list_type", list_type);

      $(`.product-order-amount-${productId}`).val(value);

      if (list_type === "order_list") {
        saveTempProductOrder(formData, productId);
      } else {
        const updateFormData = new FormData();
        const reportId = $("#search-and-filter-container").attr(
          "data-report-id"
        );
        updateFormData.append("amount", value);
        updateFormData.append("product_id", productId);
        updateFormData.append("location_id", locationId);
        updateFormData.append("report_id", reportId);
        updateTempProductAmount(updateFormData);
      }
    });

    $(document).on("click", "#save-product-order-confirm", function (e) {
      e.preventDefault();
      const choosenSupplierId = $("#product-order-table").attr(
        "data-supplier-id"
      );
      const supplierId =
        choosenSupplierId != null && choosenSupplierId != ""
          ? choosenSupplierId
          : -1;
      const referenceNumber =
        $("#reference-number").val() != "" ? $("#reference-number").val() : -1;
      const formData = new FormData();
      formData.append("location_id", locationId);
      formData.append("supplier_id", supplierId);
      formData.append("status", 0);
      formData.append("reference_number", referenceNumber);
      formData.append("type", "order");
      saveProductOrder(formData);
      $(".modal").modal("hide");
    });

    $(document).on("click", "#save-product-order-cancel", function (e) {
      e.preventDefault();
      $(this).modal("hide");
    });

    $(document).on("click", ".collapse-btn", function (e) {
      var productId = $(this).data("product-id");
      var expandMoreIcon = $(".expand-more-" + productId);
      var expandLessIcon = $(".expand-less-" + productId);

      if ($(this).attr("aria-expanded") === "false") {
        expandMoreIcon.toggle();
        expandLessIcon.toggle();
        $(`#product-${productId}`).show();
      } else {
        expandMoreIcon.hide();
        expandLessIcon.prop("hidden", false);
        expandLessIcon.show();
        $(`#product-${productId}`).hide();
      }
    });

    function updateTempProductAmount(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/product_order/update_temporary_product_order_amount",
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          return true;
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
    function saveTempProductOrder(formData, productId) {
      $.ajax({
        type: "POST",
        url: "/ajax/product_order/save_temporary_product_order",
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (formData.get("amount") == 0) {
            $(`.event-ticket-category-id-${productId}`).removeClass(
              "temp-save-row-highlight"
            );
            $(`#product-${productId}`).removeClass("temp-save-row-highlight");
          } else {
            $(`.event-ticket-category-id-${productId}`).addClass(
              "temp-save-row-highlight"
            );
            $(`#product-${productId}`).addClass("temp-save-row-highlight");
            $("#save-product-order").prop("disabled", false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function saveProductOrder(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/product_order/save_product_order",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#product-list").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            setTimeout(function () {
              document.location.href =
                "/location/" + locationId + "/inventory/product_order";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    // function getReportHistory(
    //   location_id,
    //   page = 1,
    //   order_by,
    //   order_by_direction,
    //   search_string = "",
    //   product_type,
    //   type,
    //   report_id,
    //   report_user_id,
    //   report_status,
    //   supplier_id = null,
    //   current_page = p
    // ) {
    //   $.ajax({
    //     type: "POST",
    //     url: "/ajax/organizer_event_ticket_category/search",
    //     data: {
    //       location_id,
    //       page,
    //       search_string,
    //       order_by,
    //       order_by_direction,
    //       product_type,
    //       type,
    //       report_id,
    //       report_user_id,
    //       report_status,
    //       supplier_id,
    //       current_page,
    //     },
    //   })
    //     .done(function (data) {
    //       if (data.html !== undefined && data.html !== null) {
    //         $("#search_string").val("");
    //         $("#product-list").empty();
    //         $("#product-list").append(data.html);
    //       }
    //       $("html,body").scrollTop(0);
    //     })
    //     .fail(function (data) {
    //       if (
    //         data.responseJSON.redirect_url !== undefined &&
    //         data.responseJSON.redirect_url !== null
    //       ) {
    //         document.location.href = data.responseJSON.redirect_url;
    //       }
    //     });
    // }
  }
});
