$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p == "organizer_product_category" && a == "list") {
    let product_category_new_ajax_lock = false;

    $(document).on(
      "click",
      "#product-category-registration-button",
      function (e) {
        e.preventDefault();
        if (
          $(
            "#product-category-registration-form input[name=product_category_id]"
          ).length
        ) {
          let currentColor = $("#product-category-registration-form").attr(
            "data-current-color"
          );
          let updatedColor = $(".product-category-color-picker").val();

          if (currentColor == updatedColor) {
            $("#product-category-registration-form").submit();
          } else {
            //show warning

            $("#background-color-warning").modal("show");
          }
        } else {
          $("#product-category-registration-form").submit();
        }
      }
    );

    $(document).on("click", "#background-color-warning-confirm", function () {
      $("#product-category-registration-form").submit();
    });

    $(document).on(
      "submit",
      "#product-category-registration-form",
      function () {
        if (!product_category_new_ajax_lock) {
          product_category_new_ajax_lock = true;

          let url;
          if (
            $(
              "#product-category-registration-form input[name=product_category_id]"
            ).length
          ) {
            url = "/ajax/organizer_product_category/update";
          } else {
            url = "/ajax/organizer_product_category/new";
          }

          $.ajax({
            type: "POST",
            url: url,
            data: {
              serialized_post: $(this).serialize(),
            },
          })
            .done(function (data) {
              if (data.html !== undefined && data.html !== null) {
                popupAlert();
                $("#product-category-list").empty();
                $("#product-category-list").append(data.html);
                if (
                  $("#show-deactivated-product-category-button").hasClass(
                    "d-none"
                  )
                ) {
                  $("#show-deactivated-product-category-button").removeClass(
                    "d-none"
                  );
                }
                $("#product-category-registration-form-modal").modal("hide");
                $("#background-color-warning").modal("hide");

                let productCategory = document.getElementById(
                  "product-category-list-sortable"
                );
                Sortable.create(productCategory, {
                  handle: ".product-category-reorder",
                  animation: 150,
                  sort: true,
                  delay: 0,
                  touchStartThreshold: 0,
                  disabled: false,
                  draggable: ".product-category",
                  direction: "horizontal",
                  ghostClass: "sortable-ghost",
                  dataIdAttr: "data-product-category-id",
                  removeCloneOnHide: true,
                  store: {
                    set: function (sortable) {
                      let order = sortable.toArray();
                      ajaxListOrder(order);
                    },
                  },
                });
              }
            })
            .fail(function (data) {
              if (
                data.responseJSON.redirect_url !== undefined &&
                data.responseJSON.redirect_url !== null
              ) {
                document.location.href = data.responseJSON.redirect_url;
              }

              if (
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                prependAlert(
                  "#product-category-registration-button-wrapper",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#product-category-registration-form"
                );
              }

              product_category_new_ajax_lock = false;
            })
            .always(function () {
              product_category_new_ajax_lock = false;
            });
        }

        return false;
      }
    );
  }
});
