$(document).ready(function () {
  let p = $('body').attr('p');
  let a = $('body').attr('a');
  const locationId = $('body').attr('event_id');

  if (p === 'supplier') {
    //add supplier
    $(document).on('click', '.supplier-unpublish', function (e) {
      e.preventDefault();
      let supplierId = $(this).attr('data-supplier-id');
      let formData = new FormData();
      let page = $(this).attr('data-page');
      formData.append('supplier_id', supplierId);
      formData.append('location_id', locationId);
      formData.append('status', 2);
      formData.append('page', page);

      $.ajax({
        type: 'POST',
        url: '/ajax/supplier/unpublish_supplier',
        data: formData,
        enctype: 'multipart/form-data',
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data && data['alert']) {
            alert(
              'Kunne ikke deaktiver leverandør. Leverandør ligger mest sannsynlig i en vare.'
            );
          }
          if (data && data['message'] && !data['alert']) {
            $('tr#supplier-' + supplierId).fadeOut(1000);
            setTimeout(function () {
              $('tr#supplier-' + supplierId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
