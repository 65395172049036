$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_booking_area_table' && a === 'list') {

            $('select[name=booking_table_id]').select2({
                theme: 'bootstrap4',
            });

            $(document).on("click", "#booking-area-table-register-button", function () {
                let bookingAreaId = $(this).attr('data-booking-area-id');
                let tableId = $("select[name='booking_table_id'] option:selected").val();
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_area_table/new",
                    data: {
                        booking_area_id: bookingAreaId,
                        table_id: tableId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $("select[name='booking_table_id'] option:selected").remove();
                            $('#booking-area-table-list-wrapper').empty();
                            $('#booking-area-table-list-wrapper').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }
    }
);