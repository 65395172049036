$(document).ready(
    function () {

        function addOrRemove(event_ticket_category_id, amount, add_or_remove, max = null) {

            if (add_or_remove == "add") {
                if (amount !== undefined && amount !== null) {
                    if (amount < max) {
                        amount = amount + 1;
                    }
                } else {
                    amount = 1;
                }
            }

            if (add_or_remove == "remove") {
                if (amount > 0) {
                    amount = amount - 1;
                }
            }

            return amount;
        }

        $(document).on("click", ".add-ticket", function (e) {

            e.preventDefault();

            let event_ticket_category_id = $(this).attr("data-event-ticket-category-id");
            let amount = parseInt($("#event-ticket-category-" + event_ticket_category_id).val());
            let max = parseInt($("#event-ticket-category-" + event_ticket_category_id).attr("max"));

            let new_amount = addOrRemove(event_ticket_category_id, amount, "add", max);
            $("#event-ticket-category-" + event_ticket_category_id).val(new_amount);

        });

        $(document).on("click", ".remove-ticket", function (e) {

            e.preventDefault()

            let event_ticket_category_id = $(this).attr("data-event-ticket-category-id");
            let amount = parseInt($("#event-ticket-category-" + event_ticket_category_id).val());

            let new_amount = addOrRemove(event_ticket_category_id, amount, "remove");
            $("#event-ticket-category-" + event_ticket_category_id).val(new_amount);

        });

    }
);