$(document).ready(
    function () {

        $(document).on("submit", "#admin_organizer_fee_search_form", function () {

            console.log($(this).serialize());

            $.ajax({
                type: 'POST',
                url: "/ajax/admin_fees/search",
                data: {
                    serialized_post: $(this).serialize()
                }
            })
                .done(function (data) {

                    if (data.html !== undefined && data.html !== null) {
                        $("#admin-organizer-fee").remove();
                        $("#admin-organizer-fee-search").after(data.html);
                    }

                })
                .fail(function (data) {

                    if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                        document.location.href = data.responseJSON.redirect_url;
                    }

                });

            return false;

        });

    }
);