$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const event_id = $("body").attr("event_id");

  if (p == "customer_group") {
    $(document).on("input", "input[type='number']", function () {
      if (this.value.length > 1 && this.value.indexOf(0) == 0) {
        $(this).val(Math.abs(this.value));
      }
    });

    $(document).on("keydown", "input[type='number']", function (event) {
      if (event.key === "-") {
        event.preventDefault();
      }
    });
  }

  if (p == "customer_group" && a == "new") {
    $(document).on("submit", "#customer_group_form", function (e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      formData.append("location_id", event_id);

      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (
            data.message !== undefined &&
            data.message !== null &&
            data.customer_group_id !== undefined &&
            data.customer_group_id !== null
          ) {
            prependAlert(
              "#div_customer_group_form_button",
              data.message,
              "success",
              null,
              "#customer_group_form"
            );

            $(".card-box-to-hide").hide();
            $("#customer_group_form_button").hide();

            setTimeout(function () {
              document.location.href =
                "/location/" + event_id + "/customer_group/";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_customer_group_form_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#customer_group_form"
            );
          }
        });
    });
  }

  // UPDATE
  if (p == "customer_group" && a == "update") {
    $(document).on("submit", "#customer_group_form", function (e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      formData.append("location_id", event_id);

      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/update",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert(
              "#div_customer_group_form_button",
              data.message,
              "success",
              null,
              "#customer_group_form"
            );
            $(".card-box-to-hide").hide();
            $("#customer_group_form_button").hide();

            setTimeout(function () {
              document.location.href =
                "/location/" + event_id + "/customer_group/";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#div_customer_group_form_button",
              data.responseJSON.error_message,
              "danger",
              null,
              "#customer_group_form"
            );
          }
        });
    });

    $(document).on("change", ".product-discount", function () {
      const discount_percent = Math.floor(parseFloat(this.value) * 100) / 100;
      const product_id = parseFloat($(this).attr("data-product-id"));
      const customer_group_id = parseInt($("#customer-group").val());
      const orgPrice = parseFloat($(this).attr("data-original-price"));
      const priceInput = $(this)
        .closest(".product-container")
        .find(".customer-discount-price");
      const type = "product";

      const discount_price =
        Math.floor(orgPrice * (1 - discount_percent / 100) * 100) / 100;

      priceInput.val(discount_price);
      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/update",
        data: {
          type,
          product_id,
          customer_group_id,
          discount_percent,
          location_id: event_id,
        },
      })
        .done(function (data) {
          if (data === true) {
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#product-" + product_id).after(
              '<tr><td colspan="6"><div class="alert alert-danger alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>' +
                data.responseJSON.error_message +
                "</div></td></tr>"
            );
          }
        });
    });
    $(document).on("change", ".customer-discount-price", function () {
      const discount_price =
        Math.floor(parseFloat(this.value.replace(",", ".")) * 100) / 100;
      const product_id = parseFloat($(this).attr("data-product-id"));
      const orgPrice = parseFloat($(this).attr("data-original-price"));
      const percentInput = $(this)
        .closest(".product-container")
        .find(".product-discount");

      const discount_percent = +((1 - discount_price / orgPrice) * 100).toFixed(
        2
      );
      const customer_group_id = parseInt($("#customer-group").val());
      const type = "product";

      percentInput.val(discount_percent);
      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/update",
        data: {
          type,
          product_id,
          customer_group_id,
          discount_percent,
          location_id: event_id,
        },
      })
        .done(function (data) {
          if (data === true) {
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#product-" + product_id).after(
              '<tr><td colspan="6"><div class="alert alert-danger alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>' +
                data.responseJSON.error_message +
                "</div></td></tr>"
            );
          }
        });
    });

    $(document).on("change", ".product-group-discount", function () {
      const discount_percent = Math.floor(parseFloat(this.value) * 100) / 100;
      const product_group_id = parseFloat(
        $(this).attr("data-product-group-id")
      );
      const customer_group_id = parseInt($("#customer-group").val());
      const type = "product-group";

      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/update",
        data: {
          type,
          product_group_id,
          customer_group_id,
          discount_percent,
          location_id: event_id,
        },
      })
        .done(function (data) {
          if (data === true) {
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#product-group-" + product_id).after(
              '<tr><td colspan="6"><div class="alert alert-danger alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>' +
                data.responseJSON.error_message +
                "</div></td></tr>"
            );
          }
        });
    });

    let order_by = "name";
    let order_by_direction = "asc";

    const type = $("#customer-group-discount-list").data("type");

    if (type == "product") {
      $(document).on("click", ".pagination a", function (e) {
        e.preventDefault();

        let page = parseInt($(this).attr("href").substring(6));
        let search_string = $("#search_string").val();
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          "product",
          customer_group_id
        );
      });

      $(document).on("click", ".sort", function (e) {
        e.preventDefault();
        let page = $("#list-table").attr("data-current-page");

        let search_string = $("#search_string").val();
        order_by = $(this).attr("data-order-by");
        order_by_direction = $(this).attr("data-order-by-direction");
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          "product",
          customer_group_id
        );
      });

      $(document).on("submit", "#list-search-form", function (e) {
        e.preventDefault();
        let search_string = $("#search_string").val();
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          1,
          "",
          "",
          search_string,
          "product",
          customer_group_id
        );
      });
    }

    if (type == "product-group") {
      $(document).on("click", ".pagination a", function (e) {
        e.preventDefault();

        let page = parseInt($(this).attr("href").substring(6));
        let search_string = $("#search_string").val();
        const type = "product-group";
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          type,
          customer_group_id
        );
      });

      $(document).on("click", ".sort", function (e) {
        e.preventDefault();
        let page = $("#list-table").attr("data-current-page");

        let search_string = $("#search_string").val();
        order_by = $(this).attr("data-order-by");
        order_by_direction = $(this).attr("data-order-by-direction");
        const type = "product-group";
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          type,
          customer_group_id
        );
      });

      $(document).on("submit", "#list-search-form", function (e) {
        e.preventDefault();
        let search_string = $("#search_string").val();
        let customer_group_id = $("#search_string").attr(
          "data-customer-group-id"
        );

        getList(
          event_id,
          1,
          "",
          "",
          search_string,
          "product-group",
          customer_group_id
        );
      });
    }
  }

  if (p == "customer_group" && a == "list") {
    $(document).on("click", ".customer-group-delete", function () {
      $(".alert").remove();

      let customer_group_id = $(this).attr("data-delete-id");

      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/delete",
        data: {
          customer_group_id,
          location_id: event_id,
        },
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            alert(data.message);
          } else if (data === true) {
            $("tr#customer-group-" + customer_group_id).fadeOut(1000);
            setTimeout(function () {
              $("tr#customer-group-" + customer_group_id).remove();
            }, 1000);

            $("ul#customer-group-" + customer_group_id).fadeOut(1000);
            setTimeout(function () {
              $("ul#customer-group-" + customer_group_id).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            $("#customer-group-" + customer_group_id).after(
              '<tr><td colspan="6"><div class="alert alert-danger alert-dismissible" id="message"><a href="#" class="close" data-dismiss="alert" aria-label="close">×</a>' +
                data.responseJSON.error_message +
                "</div></td></tr>"
            );
          }
        });

      return false;
    });
  }

  function getList(
    location_id = null,
    page = 1,
    order_by,
    order_by_direction,
    search_string = "",
    type = "",
    customer_group_id = ""
  ) {
    $.ajax({
      type: "POST",
      url: "/ajax/customer_group/search",
      data: {
        location_id,
        page,
        search_string,
        order_by,
        order_by_direction,
        type,
        customer_group_id,
      },
    })
      .done(function (data) {
        if (data.html !== undefined && data.html !== null) {
          $("#customer-group-discount-list").empty();
          $("#customer-group-discount-list").append(data.html);
        }

        $("html,body").scrollTop(0);
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url !== null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });
  }
});
