$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  const status = $("body").attr("status");

  if (p === "supplier" || p === "deactivated_suppliers") {
    //add supplier
    $(document).on("submit", "#supplier-registration-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);

      formData.append("location_id", locationId);
      saveSupplier(formData);
    });

    function saveSupplier(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/supplier/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#supplier-registration-form").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            setTimeout(function () {
              document.location.href = "/location/" + locationId + "/supplier";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-registration-form"
            );
          }
        });
    }

    //update supplier
    $(document).on("submit", "#supplier-update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("location_id", locationId);
      formData.append("status", status);
      updateSupplier(formData);
    });
    function updateSupplier(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/supplier/update",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#supplier-update-form").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (p === "deactivated_suppliers") {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/supplier/deactivated_suppliers";
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/supplier";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-update-form"
            );
          }
        });
    }

    //delete supplier
    $(document).on("click", "#supplier-delete-btn", function (e) {
      e.preventDefault();
      const supplierId = $(this).attr("data-delete-id");
      $("#confirmationModal").show();
      $("#delete-supplier-confirm").data("delete-id", supplierId);
    });

    $(document).on("click", "#delete-supplier-cancel", function (e) {
      e.preventDefault();
      $(".modal").modal("show");
    });

    $(document).on("click", "#delete-supplier-confirm", function (e) {
      e.preventDefault();
      const supplierId = $(this).data("delete-id");
      const formData = new FormData();
      formData.append("status", 0);
      formData.append("supplierId", supplierId);
      formData.append("location_id", locationId);
      updateSupplierStatus(formData);
      $(".modal").modal("hide");
    });

    function updateSupplierStatus(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/supplier/delete",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#supplier-list-view").hide();
            $("#message").show();

            $("html,body").scrollTop(0);

            if (p === "deactivated_suppliers") {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/supplier/deactivated_suppliers";
              }, 2000);
            } else {
              setTimeout(function () {
                document.location.href =
                  "/location/" + locationId + "/supplier";
              }, 2000);
            }
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-update-form"
            );
          }
        });
    }
  }
});
