$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_booking_reservation' && a === 'list') {

            let locationId = $("body").attr("event_id");
            let todayDate = $('#calendar').attr('data-date');

            function getBookingReservations(events, update = false) {
                let lastUpdatedDate = $('#calendar').attr('data-last-updated-date');

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_booking_reservations",
                    data: {
                        location_id: locationId,
                        reservation_date: lastUpdatedDate,
                        is_update: update,
                        employer_token: employer_token
                    }
                })
                    .done(function (data) {
                        if (data.last_updated_booking_reservations_date !== undefined && data.last_updated_booking_reservations_date !== null) {
                            $('#calendar').attr('data-last-updated-date', data.last_updated_booking_reservations_date);
                        }
                        if (data.booking_reservations !== undefined && data.booking_reservations !== null) {
                            events(JSON.parse(data.booking_reservations));
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function getBookingTables(resources) {

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_booking_tables",
                    data: {
                        location_id: locationId,
                        employer_token: employer_token
                    }
                })
                    .done(function (data) {
                        resources(JSON.parse(data));
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function getOpeningHours(date, hours) {

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_opening_time_for_date",
                    data: {
                        location_id: locationId,
                        reservation_date: date,
                        employer_token: employer_token
                    }
                })
                    .done(function (data) {
                        hours(JSON.parse(data));
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }

            function updateBookingReservation(event, table = null, response) {
                let tableId = null;
                if (table !== null) {
                    tableId = table.id;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/update",
                    data: {
                        location_id: locationId,
                        booking_reservation_id: event.id,
                        reservation_date: event.start.toISOString(),
                        reservation_date_end: event.end.toISOString(),
                        table_id: tableId

                    }
                })
                    .done(function (data) {
                        response(data);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        response(data.responseJSON);
                    })
            }

            function getBookingReservationModal(info, modal) {
                if ($('#modal-booking-reservation').length) {
                    $('#modal-booking-reservation').remove();
                }
                let bookingReservationId = null;
                let reservationDate = null;
                let reservationDateEnd = null;
                let tableId = null;
                if (info.event !== undefined && info.event !== null) {
                    bookingReservationId = info.event.id;
                }
                if (info.startStr !== undefined && info.startStr !== null) {
                    reservationDate = info.startStr;
                }
                if (info.endStr !== undefined && info.endStr !== null) {
                    reservationDateEnd = info.endStr;
                }
                if (info.resource !== undefined && info.resource !== null) {
                    tableId = info.resource.id;
                }

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/get_modal",
                    data: {
                        location_id: locationId,
                        booking_reservation_id: bookingReservationId,
                        reservation_date: reservationDate,
                        reservation_date_end: reservationDateEnd,
                        table_id: tableId,
                        employer_token: employer_token
                    }
                })
                    .done(function (data) {
                        modal(data.html);
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    })
            }


            $(document).on("submit", "#booking-reservation-from-admin-form", function () {
                //let bookingReservationId = $("#booking-reservation-from-admin-form input[name=booking_reservation_id]").val();
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_booking_reservation/update",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {
                        if (data === true) {
                            //let calendarEvent = calendar.getEventById(bookingReservationId);
                            //calendarEvent.setProp('color', '#DC3545');
                            //popupAlert();;
                            getBookingReservations(function (events) {
                                calendar.addEventSource(events);
                            }, true);
                            $('#modal-booking-reservation').modal('hide');
                            setTimeout(function () {
                                $('#modal-booking-reservation').remove();
                            }, 2000);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#booking-reservation-form-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-reservation-from-admin-form');
                        }
                    })
                return false;
            });


            let calendarEl = document.getElementById('calendar');
            let calendar = new FullCalendar.Calendar(calendarEl, {
                schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                themeSystem: 'standard',
                initialView: 'resourceTimeline',
                initialDate: todayDate,
                editable: true,
                headerToolbar: {
                    left: null,
                    center: 'title',
                    right: 'prev,next today'
                },
                buttonText: {
                    today: 'i dag',
                    month: 'måned',
                    week: 'uke',
                    day: 'dag',
                    list: 'list'
                },
                slotDuration: '00:15:00',
                resourceAreaHeaderContent: 'Bord',
                resources: function (fetchInfo, successCallback) {
                    getBookingTables(function (resources) {
                        successCallback(resources);
                    });
                },
                eventSources: function (eventInfo, successCallback) {
                    getBookingReservations(function (events) {
                        successCallback(events);
                    });
                },
                datesSet: function (dateInfo) {
                    getOpeningHours(dateInfo.startStr, function (hours) {
                        calendar.setOption('slotMinTime', hours.slotMinTime);
                        calendar.setOption('slotMaxTime', hours.slotMaxTime);
                    });
                },
                eventDrop: function (info) {
                    updateBookingReservation(info.event, info.newResource, function (response) {
                        if (response.error_message !== undefined) {
                            alert(response.error_message);
                            info.revert();
                        }
                    });
                },
                eventResize: function (info) {
                    updateBookingReservation(info.event, null, function (response) {
                        if (response.error_message !== undefined) {
                            alert(response.error_message);
                            info.revert();
                        }
                    });
                },
                eventClick: function (info) {
                    getBookingReservationModal(info, function (modal) {
                        $('#content').append(modal);
                        $('#modal-booking-reservation').modal('show');
                    });
                },
                selectable: true,
                select: function (info) {
                    if (info.resource.extendedProps.notSelectable !== undefined) {
                        return false;
                    }
                    getBookingReservationModal(info, function (modal) {
                        $('#content').append(modal);
                        $('#modal-booking-reservation').modal('show');
                    });
                }
            });
            calendar.setOption('locale', 'NO-nb');
            calendar.render();

            function getNewBookingReservations() {
                getBookingReservations(function (events) {
                    calendar.addEventSource(events);
                }, true);
                setTimeout(getNewBookingReservations, 30000);
            }

            getNewBookingReservations();


        }

    }
);
