$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let locationId = $("body").attr("event_id");

  if (p === "product_order" && a === "list") {
    let isAutofill = false;
    let isExternalClick = false;
    $(document).on("click", "#auto-fill-switch", function (e) {
      let isChecked = $(this).prop("checked");
      isAutofill = isChecked ? true : false;
      if (isAutofill) {
        $("#autofill-percent-model").modal("show");
      } else {
        let searchString = $("#search_string").val();
        let supplierId = $("#supplier-selector").val();
        let productOrderStatus = $("#product_order_status").val();
        const formData = new FormData();
        formData.append("location_id", locationId);
        formData.append("supplier_id", supplierId);
        formData.append("search_string", searchString);
        formData.append("page", 1);
        formData.append("current_page", p);
        formData.append("product_type", 0);
        formData.append("type", "order");
        formData.append("isAutoFill", isAutofill);
        formData.append("product_order_status", productOrderStatus);
        getproductList(formData);
      }
    });

    $(document).on("click", "#autofill-confirm", function (e) {
      let autoFillPercent = $('input[name="options"]:checked').val();
      let searchString = $("#search_string").val();
      let supplierId = $("#supplier-selector").val();
      let productOrderStatus = $("#product_order_status").val();
      let userId = $(this).attr("data-user-id");
      const formData = new FormData();
      formData.append("location_id", locationId);
      formData.append("supplier_id", supplierId);
      formData.append("search_string", searchString);
      formData.append("page", 1);
      formData.append("current_page", p);
      formData.append("product_type", 0);
      formData.append("user_id", userId);
      formData.append("type", "order");
      formData.append("isAutoFill", isAutofill);
      formData.append("autoFillPercent", autoFillPercent);
      formData.append("product_order_status", productOrderStatus);

      // Hide the modal
      $("#autofill-percent-model").modal("hide");

      // Remove any remaining modal backdrop
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
      $("body").removeAttr("style");
      getproductList(formData);
    });
    $(document).on("click", "#autofill-cancel", function (e) {
      $("#auto-fill-switch").prop("checked", false);
      $("#autofill-percent-model").modal("hide");
    });

    $(document).on("mousedown", function (event) {
      let target = $(event.target);
      if (
        !target.closest(".modal-dialog").length &&
        !target.closest("#auto-fill-switch").length
      ) {
        isExternalClick = true;
      } else {
        isExternalClick = false;
      }
    });

    // Listen for the modal close event
    $("#autofill-percent-model").on("hidden.bs.modal", function (e) {
      if (isExternalClick) {
        $("#auto-fill-switch").prop("checked", false);
      }
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
      $("body").removeAttr("style");
    });

    function getproductList(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/product_order/autofill",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          $("#product-filter-list").hide();
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
