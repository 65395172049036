$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "z_report") {
    $(document).on(
      "change",
      "#bankakspet, #visa, #mastercard, #othercard, #cash",
      function (e) {
        e.preventDefault();
        let totalAmount = 0;
        let posAmount = $("#total-pos-amount").attr("data-amount");

        const ids = [
          "#bankakspet",
          "#visa",
          "#mastercard",
          "#othercard",
          "#cash",
        ];
        ids.forEach(function (id) {
          let value = $(id).val();
          if (value === "") {
            totalAmount += 0;
          } else if (typeof value === "string" && value.includes(",")) {
            totalAmount += parseFloat(value.replace(",", "."));
          } else {
            totalAmount += parseFloat(value);
          }
        });

        let diffAmount = totalAmount - parseFloat(posAmount);

        let totalAmountStr = totalAmount.toString();
        if (totalAmountStr.indexOf(".") === -1) {
          totalAmountStr += ",00";
        } else {
          const decimalPart = totalAmountStr.split(".")[1];
          if (decimalPart.length === 1) {
            totalAmountStr += "0";
          } else if (decimalPart.length > 2) {
            totalAmountStr = totalAmountStr.substring(
              0,
              totalAmountStr.indexOf(".") + 3
            );
          }
        }
        let diffAmountStr = diffAmount.toString().replace("-", "");
        if (diffAmountStr.indexOf(".") === -1) {
          diffAmountStr += ",00";
        } else {
          const decimalPart = diffAmountStr.split(".")[1];
          if (decimalPart.length === 1) {
            diffAmountStr += "0";
          } else if (decimalPart.length > 2) {
            diffAmountStr = diffAmountStr.substring(
              0,
              diffAmountStr.indexOf(".") + 3
            );
          }
        }
        $("#total-amount").text(totalAmountStr.replace(".", ","));
        $("#diff-amount").text(diffAmountStr.replace(".", ","));
      }
    );

    $(document).on("click", "#register-confirm", function (e) {
      $("#z-report-form").submit();
    });

    $(document).on("submit", "#z-report-form", function (e) {
      e.preventDefault();
      const formData = new FormData($(this)[0]);

      for (let [key, value] of formData.entries()) {
        // Check if the value is empty
        if (value === "") {
          formData.set(key, 0);
        } else if (typeof value === "string" && value.includes(",")) {
          formData.set(key, value.replace(",", "."));
        }
      }

      formData.append("location_id", locationId);

      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/new",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
        xhrFields: {
          responseType: "blob", // Important to handle binary data
        },
      })
        .done(function (data) {
          try {
            downloadPDF(data);
            setTimeout(function () {
              popupAlert();
            }, 500);
            setTimeout(function () {
              location.href = `/location/${locationId}/z-report`;
            }, 2000);
          } catch (error) {
            alert(error);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });

      function downloadPDF(data) {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Z-report.pdf";
        link.click();
        URL.revokeObjectURL(url);
      }
    });

    $(document).on("click", "#z-report-download-pdf", function () {
      const zReportId = $(this).attr("data-z-report-id");

      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/download_pdf",
        data: { z_report_id: zReportId, location_id: locationId },
        xhrFields: {
          responseType: "blob", // Important to handle binary data
        },
      })
        .done(function (data) {
          downloadPDF(data);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });

      function downloadPDF(data) {
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Z-report.pdf";
        link.click();
        URL.revokeObjectURL(url);
      }
    });
    //start-end date filter
    $(document).on("change", "#start-date", function (e) {
      const startDate = $(this).val();
      const endDate = $("#end-date").val();
      getZreport(locationId, startDate, endDate);
    });
    $(document).on("change", "#end-date", function (e) {
      const endDate = $(this).val();
      const startDate = $("#start-date").val();
      getZreport(locationId, startDate, endDate);
    });

    function getZreport(locationId, startDate, endDate) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/z-report/search",
        data: {
          location_id: locationId,
          start_date: startDate,
          end_date: endDate,
        },
      })
        .done(function (data) {
          if (data.html && data.html !== null && data.html !== "") {
            $("#z-report-list-view").empty();
            $("#z-report-list-view").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });
    }
  }
});
