$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  if (p === "inventory") {
    //filter by supplier
    $(document).on("change", "#supplier-selector", function (e) {
      e.preventDefault();
      let searchString = $("#search_string").val();
      let supplierId = $(this).val();
      let report_user_id = $(this).attr("data-report-user-id") ?? "";
      let report_id = $(this).attr("data-report-id") ?? "";
      let report_status = $(this).attr("data-report-status") ?? "";
      const type = $("#event_ticket_category_table").attr("data-current-type");
      const formData = new FormData();
      formData.append("location_id", locationId);
      formData.append("supplier_id", supplierId);
      formData.append("search_string", searchString);
      formData.append("page", 1);
      formData.append("product_type", 0);
      formData.append("type", type);
      formData.append("report_id", report_id);
      formData.append("report_user_id", report_user_id);
      formData.append("report_status", report_status);
      $("#product-search-form").attr(
        "data-supplier-id",
        formData.get("supplier_id")
      );
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/search",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          $("#product-filter-list").hide();
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
