$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_extra_template' && a == 'list') {

            let product_extra_template_delete_modal_lock = false;


            $(document).on("click", ".product-extra-template-delete-button", function () {
                let locationId = $("body").attr("event_id");
                let productExtraTemplateId = $(this).attr("data-product-extra-template-id");
                let alertMessage = $(this).attr("data-alert-message");
                let numberOfProductsInProductExtraTemplate = parseInt($(this).attr("data-number-of-products-in-product-extra-template"));


                if (numberOfProductsInProductExtraTemplate > 0) {
                    alert(alertMessage);
                    return false;
                }

                if (!confirm(alertMessage)) {
                    return false;
                }

                deleteProductExtraTemplateModal(locationId, productExtraTemplateId);
            });

            function deleteProductExtraTemplateModal(locationId, productExtraTemplateId) {


                if (!product_extra_template_delete_modal_lock) {
                    product_extra_template_delete_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_extra_template/delete",
                        data: {
                            location_id: locationId,
                            product_extra_template_id: productExtraTemplateId
                        }
                    })
                        .done(function (data) {

                            if (data === true) {
                                $("#product-extra-template-" + productExtraTemplateId).fadeOut(1000);
                                setTimeout(function () {
                                    $("#product-extra-template-" + productExtraTemplateId).remove();
                                }, 1000);
                            }

                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            product_extra_template_delete_modal_lock = false;

                        })
                        .always(function () {
                            product_extra_template_delete_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);
