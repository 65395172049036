$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_table' && a == 'list') {

            let booking_table_new_ajax_lock = false;
            $(document).on("submit", "#booking-table-registration-form", function () {

                if (!booking_table_new_ajax_lock) {
                    booking_table_new_ajax_lock = true;
                    let url;
                    if ($("#booking-table-registration-form input[name=booking_table_id]").length) {
                        url = "/ajax/organizer_booking_table/update";
                    } else {
                        url = "/ajax/organizer_booking_table/new";
                    }
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#booking-table-list').empty();
                                $('#booking-table-list').append(data.html);
                                $("#booking-table-registration-form-modal").modal('hide');
                            }

                            if (data.has_deactivated_booking_tables !== undefined && data.has_deactivated_booking_tables !== null && data.has_deactivated_booking_tables == true) {
                                $('#show-deactivated-booking-table-button').removeClass('d-none');
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#booking-table-registration-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-table-registration-form')
                            }

                            booking_table_new_ajax_lock = false;

                        })
                        .always(function () {
                            booking_table_new_ajax_lock = false;
                        });

                }
                return false;
            });

        }

    }
);