$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers') {

            setInterval(function () {
                $('span.order-date').each(function () {
                    let minutesSinceOrder = parseInt($(this).attr('data-minutes-since-order'));
                    if (minutesSinceOrder >= 30) {
                        $(this).text($(this).attr('data-reg-date'));
                    }
                    if (minutesSinceOrder < 30) {
                        minutesSinceOrder++;
                        $(this).attr('data-minutes-since-order', minutesSinceOrder);
                        $(this).text(minutesSinceOrder + ' min siden');
                    }
                });
            }, 60000);

        }

    }
);

