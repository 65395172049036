$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_event_customers') {

            $(document).on("click", ".change-order-status", function (e) {
                e.preventDefault();
                let location_id = $("#location_id").val();
                let order_id = $(this).attr("data-order-id");
                let status = $(this).val();

                let employer_token = null;
                if ($("body").attr("employer_token") != undefined) {
                    employer_token = $("body").attr("employer_token");
                }

                if (status == 0){
                    let confirmText = $(this).attr("data-cancel-order-confirm-message");
                    if (!confirm(confirmText)) {
                        return false;
                    }
                }


                $.ajax({
                    type: 'POST',
                    url: "/ajax/order/update_order_status",
                    data: {
                        location_id,
                        order_id,
                        status,
                        employer_token
                    }
                })
                    .done(function (data) {

                        if (data.order_id !== undefined && data.order_id !== null && data.status !== undefined && data.status !== null) {

                            let status = $("#status option:selected").val();

                            if (($.isNumeric(status) && data.status != status) || data.status == 0) {
                                $('tr#order-product-order-id-' + data.order_id).fadeOut(1000);
                                setTimeout(function () {
                                    $('tr#order-product-order-id-' + data.order_id).remove();
                                }, 1000);
                            }

                            if (!$.isNumeric(status)) {
                                let buttonsForOrder = $('tr#order-product-order-id-' + data.order_id).find('button.change-order-status');
                                buttonsForOrder.each(function () {
                                    if ($(this).val() != data.status && $(this).hasClass('btn-primary')) {
                                        $(this).removeClass('btn-primary');
                                        $(this).addClass('btn-secondary');
                                    }
                                    if ($(this).val() == data.status && !$(this).hasClass('btn-primary')) {
                                        $(this).removeClass('btn-secondary');
                                        $(this).addClass('btn-primary');
                                    }
                                });
                                let buttonsForOrderProducts = $('tr#order-product-order-id-' + data.order_id).find('button.change-order-product-status');
                                buttonsForOrderProducts.each(function () {
                                    if ($(this).val() != data.status && $(this).hasClass('btn-primary')) {
                                        $(this).removeClass('btn-primary');
                                        $(this).addClass('btn-secondary');
                                    }
                                    if ($(this).val() == data.status && !$(this).hasClass('btn-primary')) {
                                        $(this).removeClass('btn-secondary');
                                        $(this).addClass('btn-primary');
                                    }
                                });
                            }

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#order-product-order-id-' + order_id + ' .order-status', data.responseJSON.error_message, 'danger', null, '#order-list-table')
                        }

                    })

            });

        }

    }
);

