$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let location_id = $("body").attr("event_id");

  if (p === "inventory_stock_history") {
    $(document).on("change", "#start-date", function (e) {
      e.preventDefault();
      const regDate = $(this).val() + " 00:00:00";
      const orderBy = "name";
      const orderByDirection = "asc";

      $.ajax({
        type: "POST",
        url: "/api/v1/private/inventory_stock_history/search",
        data: {
          regDate,
          orderBy,
          orderByDirection,
          location_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#inventroy-history-container").empty();
            $("#inventroy-history-container").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      const regDate = $("#start-date").val() + " 00:00:00";
      const orderBy = $(this).attr("data-order-by");
      const orderByDirection = $(this).attr("data-order-by-direction");
      const product_group = $(this).attr("data-product-group");

      $.ajax({
        type: "POST",
        url: "/api/v1/private/inventory_stock_history/search",
        data: {
          regDate,
          orderBy,
          orderByDirection,
          location_id,
          product_group,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#inventroy-history-container").empty();
            $("#inventroy-history-container").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
