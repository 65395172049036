$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "credit_order_products") {
    $("select[name=customer-select]").select2({
      theme: "bootstrap4",
    });
    let event_id = $("body").attr("event_id");
    let orderBy = "product_name";
    let orderByDirection = "asc";
    let previousCustomerId = null;

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();
      orderBy = $(this).attr("data-order-by");
      orderByDirection = $(this).attr("data-order-by-direction");
      let orderId = $(this).closest("tr").attr("data-order-id");
      getCreditOrder(orderId, event_id, orderBy, orderByDirection);
    });

    $(document).on("click", "#credit-order-prodcut-confirm", function (e) {
      e.preventDefault();
      const orderId = $("#credit-order-table").attr("data-order-id");
      const orderProductId = $(
        "#creditOrderEditForm input[name='order_product_id']"
      ).val();
      const quantity = $("#creditOrderEditForm input[name='quantity']").val();
      const discountPercent = $(
        "#creditOrderEditForm input[name='discount']"
      ).val();
      $.ajax({
        type: "POST",
        url: "/api/v1/private/order_product/update",
        data: {
          order_product_id: orderProductId,
          quantity,
          discount_percent: discountPercent,
          credit_order_id: orderId,
          event_id,
          order_by: orderBy,
          order_by_direction: orderByDirection,
        },
      })
        .done(function (data) {
          if (data.html) {
            popupAlert();
            $("#credit-order-list-view").empty();
            $("#credit-order-list-view").html(data.html);
            $("#creditOrderProductModal").modal("hide");
            $(".modal-backdrop").remove();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
    $(document).on("click", "#order-product-edit-btn", function (e) {
      e.preventDefault();
      const orderProductId = $(this).attr("data-order-product-id");
      getCreditOrderProdcutModel(orderProductId);
    });

    $(document).on("click", ".order-product-edit-btn", function (e) {
      e.preventDefault();
      const orderProductId = $(this)
        .closest("tr")
        .attr("data-order-product-id");
      getCreditOrderProdcutModel(orderProductId);
    });

    $(document).on("click", "#update-customer-btn", function (e) {
      e.preventDefault();
      $("#comfirm-update-customer").prop("hidden", false);
      $("#comfirm-update-customer").prop("disabled", true);
      $("#customer-select").prop("disabled", false);
      $(this).prop("hidden", true);
      previousCustomerId = $("#customer-select").val();

      setTimeout(() => {
        $("#customer-select").select2("open");
      }, 500);
    });

    $(document).on("change", "#customer-select", function (e) {
      e.preventDefault();
      $("#comfirm-update-customer").prop("disabled", false);
      $("#customer-select").prop("disabled", false);
    });

    $(document).on("click", ".order-product-delete-btn", function (e) {
      e.preventDefault();
      let orderProductId = $(this).attr("data-delete-id");
      $("#delete-order-prodcut-confirm").attr("data-delete-id", orderProductId);
    });

    $(document).on("click", "#comfirm-update-customer", function (e) {
      e.preventDefault();
      const orderId = $(this).attr("data-order-id");
      const customerId = $("#customer-select").val();
      $.ajax({
        type: "POST",
        url: "/api/v1/private/order/update",
        data: { event_id, order_id: orderId, customer_id: customerId },
        dataType: "json", // Expect JSON response
        success: function (data) {
          if (data.success) {
            popupAlert();
            setTimeout(() => location.reload(), 500);
          }
        },
        error: function (xhr, status, error) {
          if (xhr.responseJSON && xhr.responseJSON.error) {
            alert(xhr.responseJSON.error);
          } else {
            alert("An error occurred. Please try again later.");
          }
          setTimeout(function () {
            location.reload();
          }, 200);
        },
      });
    });

    $(document).on("click", "#delete-order-prodcut-confirm", function (e) {
      e.preventDefault();
      const parentRow = $(this).closest("tr");
      let orderProductId = $(this).attr("data-delete-id");
      $.ajax({
        type: "DELETE",
        url: `/api/v1/private/order_product/delete/${orderProductId}`,
        data: { event_id },
      })
        .done(function (data) {
          if (data.success && data.redirect_url) {
            popupAlert();
            setTimeout(() => {
              document.location.href = data.redirect_url;
            }, 500);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    function getCreditOrder(orderId, event_id, orderBy, orderByDirection) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/order_product/search",
        data: {
          credit_order_id: orderId,
          event_id,
          order_by: orderBy,
          order_by_direction: orderByDirection,
        },
      })
        .done(function (data) {
          if (data.html) {
            $("#credit-order-list-view").empty();
            $("#credit-order-list-view").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getCreditOrderProdcutModel(orderProductId = null) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/order_product/getCreditOrderProductModel",
        data: {
          order_product_id: orderProductId,
        },
      })
        .done(function (data) {
          if (data.html) {
            $("#credit-order-list-view").append(data.html);
            $("#creditOrderProductModal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
