$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_area' && a == 'list') {


            let booking_area_new_ajax_lock = false;

            $(document).on("submit", "#booking-area-registration-form", function () {

                if (!booking_area_new_ajax_lock) {

                    booking_area_new_ajax_lock = true;

                    let url;
                    if ($("#booking-area-registration-form input[name=booking_area_id]").length) {
                        url = "/ajax/organizer_booking_area/update";
                    } else {
                        url = "/ajax/organizer_booking_area/new";
                    }
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data.html !== undefined && data.html !== null) {
                                popupAlert();
                                $('#booking-area-list').empty();
                                $('#booking-area-list').append(data.html);
                                if ($('#show-deactivated-booking-area-button').hasClass('d-none')) {
                                    $('#show-deactivated-booking-area-button').removeClass('d-none');
                                }
                                $("#booking-area-registration-form-modal").modal('hide');

                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#booking-area-registration-button-wrapper', data.responseJSON.error_message, 'danger', null, '#booking-area-registration-form')
                            }

                            booking_area_new_ajax_lock = false;

                        })
                        .always(function () {
                            booking_area_new_ajax_lock = false;
                        });

                }

                return false;

            });


        }

    }
);