$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p == "frontpage") {
    $("#more-info").click(function () {
      $("html, body").animate(
        {
          scrollTop: $("#card-box-container").offset().top,
        },
        500
      );
    });
  }
});
