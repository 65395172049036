$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "table") {
    //add supplier
    $(document).on("click", ".table-unpublish", function (e) {
      e.preventDefault();
      let tableId = $(this).attr("data-table-id");
      let formData = new FormData();
      let page = $(this).attr("data-page");
      formData.append("table_id", tableId);
      formData.append("location_id", locationId);
      formData.append("status", 2);
      formData.append("page", page);

      $.ajax({
        type: "POST",
        url: "/ajax/table/unpublish",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data && data["message"] && !data["alert"]) {
            $("tr#table-" + tableId).fadeOut(1000);
            setTimeout(function () {
              $("tr#table-" + tableId).remove();
            }, 1000);
            $("#table-mobile-list-" + tableId).fadeOut(1000);
            setTimeout(function () {
              $("#table-mobile-list-" + tableId).remove();
            }, 1000);
            popupAlert();
          }

          if (data && data["message"] && data["alert"]) {
            alert(
              "Kunne ikke deaktiver Varegruppe . Varegruppe  ligger mest sannsynlig i en Vare"
            );
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
