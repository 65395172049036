$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p === 'events') {

            $(document).on("click", ".product-lightbox-wrapper", function (e) {
                closeImageLightboxAndBackdrop();
            });

            $(document).on("click", ".product-lightbox-wrapper .line", function (e) {
                closeImageLightboxAndBackdrop();
            });

            $(document).on("click", ".product-lightbox-backdrop", function (e) {
                closeImageLightboxAndBackdrop();
            });

            function closeImageLightboxAndBackdrop() {
                if ($(".product-lightbox-backdrop").length) {
                    $(".product-lightbox-backdrop").remove();
                }
                if ($(".product-lightbox-wrapper").length) {
                    $(".product-lightbox-wrapper").remove();
                }
                $('body').removeClass("blur-background");
            }

            $(document).on("click", ".product-image-wrapper img", function (e) {
                e.preventDefault();
                if ($(".product-lightbox-backdrop").length) {
                    $(".product-lightbox-backdrop").remove();
                }
                if ($(".product-lightbox-wrapper").length) {
                    $(".product-lightbox-wrapper").remove();
                }
                $('body').after('<div class="product-lightbox-backdrop"></div>');
                let imageUrl = $(this).attr('data-image-url');
                $('body').after('<div class="product-lightbox-wrapper"><div class="line line-1"></div><div class="line line-2"></div><div class="product-lightbox"><img src="' + imageUrl + '"></div></div>');
                $('body').addClass("blur-background");
            });


        }
    }
);