$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const location_id = $("body").attr("event_id");

  if (p === "customer_group" && a === "list") {
    $(document).on("submit", "#customer-group-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#search_string").val();
      let page = 1;

      getCustomerGroupList(location_id, search_string, page);
    });

    function getCustomerGroupList(location_id, search_string, page) {
      $.ajax({
        type: "POST",
        url: "/ajax/customer_group/search",
        data: {
          location_id,
          search_string,
          page,
          type: "customer-group",
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#customer-group-list-container").empty();
            $("#customer-group-list-container").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
