$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p == "organizer_product_category_self_service_link" && a == "list") {
    if ($("#product-category-link-list-sortable").length) {
      let productCategoryLink = document.getElementById(
        "product-category-link-list-sortable"
      );

      Sortable.create(productCategoryLink, {
        handle: ".product-category-link-reorder",
        animation: 150,
        sort: true,
        delay: 0,
        touchStartThreshold: 0,
        disabled: false,
        draggable: ".product-category-link",
        direction: "horizontal",
        ghostClass: "sortable-ghost",
        dataIdAttr: "data-product-category-link-id",
        removeCloneOnHide: true,
        store: {
          set: function (sortable) {
            let order = sortable.toArray();
            ajaxListOrder(order);
          },
        },
      });
    }

    function ajaxListOrder($order) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_product_category_link/list_sort",
        data: {
          serialized_post: JSON.stringify($order),
        },
      })
        .done(function (data) {
          if (data === true) {
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
