$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let urlParam = new URLSearchParams(window.location.search);
  const typeFromParam = urlParam.get("type");

  if (p === "inventory" && a === "list") {
    $(document).on("submit", "#product-search-form", function (e) {
      e.preventDefault();
      const search_string = $("#search_string").val();
      const location_id = $("body").attr("event_id");
      const product_type = 0;
      const report_id =
        $("#current-report").attr("data-report-id") ??
        $("#filtered-form").attr("data-report-id");

      const reportUserId =
        $("#current-report").attr("data-report-user-id") ??
        $("#filtered-form").attr("data-report-user-id");
      const reportStatus =
        $("#current-report").attr("data-report-status") ??
        $("#filtered-form").attr("data-report-status");
      const supplierId = $("#supplier-selector").val();
      const type = $("#event_ticket_category_table").attr("data-current-type");
      getProductList(
        location_id,
        search_string,
        product_type,
        type,
        report_id,
        reportUserId,
        reportStatus,
        supplierId
      );
    });

    function getProductList(
      location_id,
      search_string,
      product_type,
      type,
      report_id,
      report_user_id,
      report_status,
      supplier_id = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/search",
        data: {
          location_id,
          search_string,
          product_type,
          type,
          report_id,
          report_user_id,
          report_status,
          supplier_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-search-button").attr(
              "data-search-string",
              search_string
            );
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
