$(document).ready(function () {
  $(".organizer").on("click", function () {
    let selected_organizer = $(this).attr("data-organizer-id");
    let params = {
      organizer_id: selected_organizer,
    };
    if (
      $(this).attr("data-menu") != undefined &&
      $(this).attr("data-menu") != null
    ) {
      params.menu = $(this).attr("data-menu");
    }

    params.is_android = !!window.ReactNativeWebView;

    $.ajax({
      type: "POST",
      url: "/ajax/user/select_organizer",
      data: params,
    })
      .done(function (data) {
        if (data.redirect_url !== undefined && data.redirect_url != null) {
          document.location.href = data.redirect_url;
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.redirect_url !== undefined &&
          data.responseJSON.redirect_url != null
        ) {
          document.location.href = data.responseJSON.redirect_url;
        }
      });

    return false;
  });
});
