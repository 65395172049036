$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_booking_table' && a == 'list') {

            let booking_table_modal_lock = false;

            $(document).on("click", "#booking-table-new-button", function () {
                let locationId = $("body").attr("event_id");
                getBookingTableModal(locationId);
            });

            $(document).on("click", ".booking-table-update-button", function () {
                let locationId = $("body").attr("event_id");
                let bookingTableId = $(this).attr("data-booking-table-id");
                getBookingTableModal(locationId, bookingTableId);
            });

            function getBookingTableModal(locationId, bookingTableId = null) {

                if ($('#booking-table-registration-form-modal').length) {
                    $('#booking-table-registration-form-modal').remove();
                }

                if (!booking_table_modal_lock) {
                    booking_table_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_booking_table/get_modal",
                        data: {
                            location_id: locationId,
                            booking_table_id: bookingTableId
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#content').append(data.html);
                                $('#booking-table-registration-form-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            booking_table_modal_lock = false;

                        })
                        .always(function () {
                            booking_table_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);