$(document).ready(function () {
  $(document).on("submit", "#pin_login_form", function (e) {
    e.preventDefault();
    const redirect_url =
      !!location.search && location.search.indexOf("?redirect_url=") === 0
        ? location.search.replace("?redirect_url=", "")
        : "";

    const formData = $(this).serialize();
    const serializedData = `${formData}&is_android=${!!window.ReactNativeWebView}${
      redirect_url !== ""
        ? `&redirect_url=${decodeURIComponent(redirect_url)}`
        : ""
    }`;

    $.ajax({
      type: "POST",
      url: "/ajax/user/organizer_login",
      data: serializedData,
    })
      .done(function (data) {
        if (data.message !== undefined && data.message !== null) {
          prependAlert(
            "#error-container",
            data.message,
            "danger",
            null,
            "#pin_login_form"
          );
        } else if (
          data.success === true &&
          data.redirect_url !== undefined &&
          data.redirect_url != null
        ) {
          document.location.href = data.redirect_url;
        }
      })
      .fail(function (data) {
        if (
          data.responseJSON.error_message !== undefined &&
          data.responseJSON.error_message !== null
        ) {
          prependAlert(
            "#error-container",
            data.responseJSON.error_message,
            "danger",
            null,
            "#pin_login_form"
          );
        }
      });

    return false;
  });
});
