$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_group' && a == 'list') {

            let product_group_delete_modal_lock = false;


            $(document).on("click", ".product-group-delete-button", function () {
                let locationId = $("body").attr("event_id");
                let productGroupId = $(this).attr("data-product-group-id");
                let alertMessage = $(this).attr("data-alert-message");
                let numberOfProductsInProductGroup = parseInt($(this).attr("data-number-of-products-in-product-group"));


                if (numberOfProductsInProductGroup > 0) {
                    alert(alertMessage);
                    return false;
                }

                if (!confirm(alertMessage)) {
                    return false;
                }

                deleteProductGroupModal(locationId, productGroupId);
            });

            function deleteProductGroupModal(locationId, productGroupId) {


                if (!product_group_delete_modal_lock) {
                    product_group_delete_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_group/delete",
                        data: {
                            location_id: locationId,
                            product_group_id: productGroupId
                        }
                    })
                        .done(function (data) {

                            if (data === true) {
                                $("#product-group-" + productGroupId).fadeOut(1000);
                                setTimeout(function () {
                                    $("#product-group-" + productGroupId).remove();
                                }, 1000);
                            }

                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            product_group_delete_modal_lock = false;

                        })
                        .always(function () {
                            product_group_delete_modal_lock = false;
                        });
                }
                return false;
            }


        }
    }
);
