$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");

  if (p === "deactivated_product_group_unique") {
    //add supplier
    $(document).on("click", ".product-group-unique-publish", function (e) {
      e.preventDefault();
      let productGroupUniqueId = $(this).attr("data-product-group-unique-id");
      let formData = new FormData();
      let page = $(this).attr("data-page");
      formData.append("product_group_unique_id", productGroupUniqueId);
      formData.append("location_id", locationId);
      formData.append("status", 1);
      formData.append("page", page);

      $.ajax({
        type: "POST",
        url: "/ajax/product_group_unique/unpublish",
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data && data["message"] && !data["alert"]) {
            $("tr#productGroupUnique-" + productGroupUniqueId).fadeOut(1000);
            setTimeout(function () {
              $("tr#productGroupUnique-" + productGroupUniqueId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
