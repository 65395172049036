$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  let urlParam = new URLSearchParams(window.location.search);
  const typeFromParam = urlParam.get("type");
  const type = typeFromParam ? typeFromParam : "add";
  let tempFormData;
  let input;

  if (p === "inventory" && a === "list" && type === "count") {
    $(document).on("submit", function (e) {
      e.preventDefault();
    });

    $(document).on("change", ".amount-after", function (e) {
      input = $(this);
      const productId = $(this).data("product-id");
      const value = parseFloat(input.val());
      $(`.amount-after-${productId}`).val(value);
      const form = $(this).closest("form");
      let formData = new FormData(form[0]);
      formData.append("location_id", locationId);
      formData.append("type", type);
      tempFormData = formData;

      saveTemporaryProductStockCount(formData);
    });

    $(document).on("click", "#save-stock-taking", function (e) {
      e.preventDefault();
      $("#confirmationModal").show();
    });

    $(document).on("click", "#save-stock-taking-cancel", function (e) {
      e.preventDefault();
      $(".modal").modal("hide");
    });

    $(document).on("click", "#save-stock-taking-confirm", function (e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("type", "count");
      formData.append("location_id", locationId);
      saveStockTakingCount(formData);
      $(".modal").modal("hide");
    });

    $(document).on("click", "#temp-save-conflict-confirm", function (e) {
      e.preventDefault();
      tempFormData.append("force", true);
      saveTemporaryProductStockCount(tempFormData);
      $(".modal").modal("hide");
    });

    $(document).on("click", "#temp-save-conflict-cancel", function (e) {
      input.val("");
      e.preventDefault();
      tempFormData = undefined;
      $(this).modal("hide");
    });

    $(document).on("click", ".report-btn", function (e) {
      const reportId = $(this).attr("data-report-id");
      const reportUserId = $(this).attr("data-report-user-id");
      const reportStatus = $(this).attr("data-report-status");
      const page = 1;
      const location_id = $("body").attr("event_id");
      const search_string = "";
      const order_by = $(this).attr("data-order-by");
      const order_by_direction = $(this).attr("data-order-by-direction");
      const supplierId = $("#product-search-form").data("supplier-id");
      $("#filtered-form")
        .attr("data-report-id", reportId)
        .attr("data-report-user-id", reportUserId)
        .attr("data-report-status", reportStatus);

      getReportHistory(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        0,
        type,
        reportId,
        reportUserId,
        reportStatus,
        supplierId
      );
    });

    function saveTemporaryProductStockCount(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/save_temporary_product_stock",
        data: formData,
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.isSavedByOtherUser) {
            $(".conflict-user").html(`<div>${data.userFirstName}</div>`);
            $(".conflict-amount-after").html(`<div>${data.amountAfter}</div>`);
            $("#tempSaveConflictModal").modal({
              keyboard: true,
              show: true,
            });
          }
          if (data.html !== undefined && data.html !== null) {
            $(`.event-ticket-category-id-${data.product_id}`).addClass(
              "temp-save-row-highlight"
            );

            $(`#product-${data.product_id}`).addClass(
              "temp-save-row-highlight"
            );

            $("#report-list").empty();
            $("#report-list").append(data.html);
            $("#save-stock-taking").prop("disabled", false);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function saveStockTakingCount(formData) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/save_stock_taking",
        data: formData,
        enctype: "application/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message && data.message.title && data.message.description) {
            prependAlert(
              "#message",
              data.message.description,
              "success",
              data.message.title
            );
            $("#message:hidden").show();
            $("#product-list").hide();

            setTimeout(function () {
              document.location.href =
                "/location/" + locationId + "/inventory?type=count";
            }, 1000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getReportHistory(
      location_id,
      page = 1,
      order_by,
      order_by_direction,
      search_string = "",
      product_type,
      type,
      report_id,
      report_user_id,
      report_status,
      supplier_id = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/inventory/search",
        data: {
          location_id,
          page,
          search_string,
          order_by,
          order_by_direction,
          product_type,
          type,
          report_id,
          report_user_id,
          report_status,
          supplier_id,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#search_string").val("");
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
