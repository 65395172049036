$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_extra' && a === 'list') {

            $(document).on("click", "#product-extra-register-button", function () {
                let productId = $(this).attr('data-product-id');
                let extraProductId = $("select[name='extra_product_id'] option:selected").val();
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra/new",
                    data: {
                        product_id: productId,
                        extra_product_id: extraProductId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            if (data.extra_product_count > 0 && $('#product-extra-wrapper').hasClass('d-none')) {
                                $('#product-extra-wrapper').removeClass('d-none');
                            }
                            $("select[name='extra_product_id'] option:selected").remove();
                            $('#product-extra-list-sortable').empty();
                            $('#product-extra-list-sortable').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

            $(document).on("click", "#product-extra-from-template-register-button", function () {
                let productId = $(this).attr('data-product-id');
                let productExtraTemplateId = $("select[name='product_extra_template_id'] option:selected").val();
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra/new_from_template",
                    data: {
                        product_id: productId,
                        product_extra_template_id: productExtraTemplateId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            if (data.extra_product_count > 0 && $('#product-extra-wrapper').hasClass('d-none')) {
                                $('#product-extra-wrapper').removeClass('d-none');
                            }
                            $("select[name='product_extra_template_id'] option:selected").remove();
                            $('#product-extra-list-sortable').empty();
                            $('#product-extra-list-sortable').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

            $(document).on("submit", "#product-extra-text-registration-form", function () {

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra/new",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            if (data.extra_product_count > 0 && $('#product-extra-wrapper').hasClass('d-none')) {
                                $('#product-extra-wrapper').removeClass('d-none');
                            }
                            $('#product-extra-registration-form-modal').modal('hide');
                            $('#product-extra-list-sortable').empty();
                            $('#product-extra-list-sortable').append(data.html);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

                return false;

            });

        }

    }
);