$(document).ready(
    function () {
        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && a == 'list') {

            $(document).on("click", ".event-ticket-category-publish", function () {

                let selected_event_ticket_category = $(this).attr("data-publish-id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event_ticket_category/publish",
                    data: {
                        event_ticket_category_id: selected_event_ticket_category
                    }
                })
                    .done(function (data) {

                        if (data === true) {

                            $('tr#event-ticket-category-id-' + selected_event_ticket_category).fadeOut(1000);
                            setTimeout(function () {
                                $('tr#event-ticket-category-id-' + selected_event_ticket_category).remove();
                            }, 1000);
                            popupAlert();
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

                return false;

            });
        }

    }
);