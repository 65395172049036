$(document).ready(function () {
  const body = $("body");
  const p = body.attr("p");
  const a = body.attr("a");

  if (p === "organizer_product_category_self_service_link" && a === "list") {
    let product_category_link_color_ajax_lock = false;
    $(document).on("change", ".product-color-picker", function () {
      if (!product_category_link_color_ajax_lock) {
        const productCategorLinkContainer = $(this).closest(
          "li.product-category-link"
        );
        const productCategoryLinkId = productCategorLinkContainer.data(
          "product-category-link-id"
        );
        const locationId = $("body").attr("event_id");
        const backgroundColor = $(this).val();
        product_category_link_color_ajax_lock = true;

        $.ajax({
          type: "POST",
          url: "/ajax/organizer_product_category_self_service_link/change_color",
          data: {
            product_category_link_id: productCategoryLinkId,
            location_id: locationId,
            background_color: backgroundColor,
          },
        })
          .done(function () {
            popupAlert();
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }

            product_category_link_color_ajax_lock = false;
          })
          .always(function () {
            product_category_link_color_ajax_lock = false;
          });
      }
    });
  }
});
