$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "events") {
    $(document).on(
      "shown.bs.modal",
      "#split-payment-cart-modal",
      function () {}
    );

    // function softProductTableList(){
    //   if ($("#split-product-table-body").length) {
    //     console.log("Modal content loaded");
    //     const splitProductTableBody = document.getElementById(
    //       "split-product-table-body"
    //     );
    //     console.log("Initializing Sortable");

    //     Sortable.create(splitProductTableBody, {
    //       handle: ".product-table-reorder",
    //       animation: 150,
    //       sort: true,
    //       delay: 0,
    //       touchStartThreshold: 0,
    //       disabled: false,
    //       draggable: ".product-table-group",
    //       direction: "vertical", // Set to vertical for row sorting
    //       ghostClass: "sortable-ghost",
    //       dataIdAttr: "data-product-id",
    //       removeCloneOnHide: true,
    //       store: {
    //         set: function (sortable) {
    //           console.log("Sorted order changed");
    //           let order = sortable.toArray();
    //           ajaxListOrder(order);
    //         },
    //       },
    //     });

    //     function ajaxListOrder($order) {
    //       $.ajax({
    //         type: "POST",
    //         url: "/ajax/organizer_product_extra/list_sort",
    //         data: {
    //           serialized_post: JSON.stringify($order),
    //         },
    //       })
    //         .done(function (data) {
    //           if (data === true) {
    //             popupAlert();
    //           }
    //         })
    //         .fail(function (data) {
    //           if (
    //             data.responseJSON.redirect_url !== undefined &&
    //             data.responseJSON.redirect_url !== null
    //           ) {
    //             document.location.href = data.responseJSON.redirect_url;
    //           }
    //         });
    //     }
    //   }
    // }
    // function ajaxListOrder($order) {
    //   $.ajax({
    //     type: "POST",
    //     url: "/ajax/organizer_product_extra/list_sort",
    //     data: {
    //       serialized_post: JSON.stringify($order),
    //     },
    //   })
    //     .done(function (data) {
    //       if (data === true) {
    //         popupAlert();
    //       }
    //     })
    //     .fail(function (data) {
    //       if (
    //         data.responseJSON.redirect_url !== undefined &&
    //         data.responseJSON.redirect_url !== null
    //       ) {
    //         document.location.href = data.responseJSON.redirect_url;
    //       }
    //     });
    // }
  }
});
