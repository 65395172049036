$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  const locationId = $("body").attr("event_id");
  if (p === "product_management" && a === "list") {
    $(document).on("submit", "#product-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#search_string").val();
      let product_type = $("#product_type").val();
      let supplier_id = $("#supplier-selector").val();
      getProductList(locationId, search_string, product_type, supplier_id, p);
    });

    function getProductList(
      location_id,
      search_string,
      product_type,
      supplier_id = null,
      current_page = ""
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/product_management/search",
        data: {
          location_id,
          search_string,
          product_type,
          supplier_id,
          current_page,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#product-list").empty();
            $("#product-list").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
