$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");

        function addMaxTicketPerOrder() {
            let maxTicketsPerOrder = $('#max_tickets_per_order').attr('max') * 1;
            if ($('#max_tickets_per_order').val() == '' && typeof ($('#max_tickets').val() * 1) == 'number' && ($('#max_tickets').val() * 1) > 0) {
                if ($('#max_tickets').val() >= maxTicketsPerOrder) {
                    $('#max_tickets_per_order').val(maxTicketsPerOrder);
                } else {
                    $('#max_tickets_per_order').val($('#max_tickets').val());
                }
            }
        }

        if (p == 'organizer_event' && a == 'new') {
            $(document).on("focusout ", '#max_tickets', function () {
                addMaxTicketPerOrder();
            });
        }

        if (p == 'organizer_event' && (a == 'new' || a == 'update')) {

            $(document).on("change", '#no_end_date', function () {
                if ($('#no_end_date').prop("checked") === true) {
                    hideEndDateInput();
                } else {
                    showEndDateInput();
                }
            });

            function hideEndDateInput() {
                $('#end').removeAttr('required');
                $('#end').val('');
                $('#end').addClass('d-none');
            }

            function showEndDateInput() {
                $('#end').removeClass('d-none');
            }

            $(document).on("change", '#recurring', function () {
                if ($(this).prop("checked") === true) {
                    let eventDateAndTimeLabelText = $('#event-data-and-time-label').attr('data-event-subevent-data-and-time-label-text');
                    $('#event-data-and-time-label').text(eventDateAndTimeLabelText);
                    if ($('#start_date').val().length == 0) {
                        $('#start_date').val($('#start_date').attr('data-todays-date'));
                    }
                    getSubEventRegistrationForm();
                    if ($('#area').prop("checked") == true && $('.event-area-zone-date-wrapper').hasClass('d-none')) {
                        $('.event-area-zone-date-wrapper').removeClass('d-none');
                    }
                } else {
                    let eventDateAndTimeLabelText = $('#event-data-and-time-label').attr('data-event-data-and-time-label-text');
                    $('#event-data-and-time-label').text(eventDateAndTimeLabelText);
                    $('#subevent').remove();
                    if ($('#area').prop("checked") == true && !$('.event-area-zone-date-wrapper').hasClass('d-none')) {
                        $('.event-area-zone-date-wrapper').addClass('d-none');
                    }
                }
            });

            $(document).on("change", '#area', function () {
                if ($(this).prop("checked") === true) {
                    getAreaZoneRegistrationForm();
                    $('#max_tickets').attr("disabled", true);
                    $('#max_tickets').val(null);
                } else {
                    $('#area_zone').remove();
                    $('#max_tickets').removeAttr('disabled');
                }
            });

            $(document.body).on("change", '#start_date', function () {
                if ($('#recurring').prop("checked") === true) {
                    let subeventEndDate = $('#end').val();
                    let activity = $("#activity").val();
                    let custom_days = false;
                    if (activity == 'custom') {
                        custom_days = [];
                        $.each($("input[name='custom_weekly[]']:checked"), function () {
                            custom_days.push($(this).val());
                        });
                    }
                    let noEndDate = false;
                    if ($('#no_end_date').prop("checked") === true) {
                        noEndDate = true;
                    }
                    getSubEventRegistrationForm(subeventEndDate, noEndDate, activity, custom_days);
                }
            });

            $(document.body).on('change', "#activity", function () {
                if ($(this).val() == 'custom') {
                    $('#custom_activity').removeClass('d-none');
                } else {
                    $('#custom_activity').addClass('d-none');
                }
            });

            if ($('#recurring').prop("checked") === true) {
                getSubEventRegistrationForm();
            }

            if ($('#area').prop("checked") === true) {
                getAreaZoneRegistrationForm();
            }

            function getSubEventRegistrationForm(subeventEndDate = null, noEndDate = false, activity = false, custom_days = false) {
                let eventId = null;
                let startDate = $('#start_date').val();
                if (typeof $('#event_id') !== 'undefined' && typeof $('#event_id') != false && typeof ($('#event_id').val() * 1) == 'number' && ($('#event_id').val() * 1) > 0) {
                    eventId = $('#event_id').val() * 1;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/get_subevent",
                    data: {
                        start_date: startDate,
                        event_id: eventId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#subevent').remove();
                            $('#event-start-and-end-date').after(data.html);

                            if (subeventEndDate != null) {
                                $('#end').val(subeventEndDate);
                            }

                            if (noEndDate) {
                                hideEndDateInput();
                                $("#no_end_date").prop("checked", true);
                            }

                            if (activity && $("#activity option[value='" + activity + "']").length > 0) {
                                $("#activity").val(activity).change();
                            }

                            if (custom_days) {
                                $('input[name="custom_weekly[]"]').val(custom_days)
                            }

                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });
            }


            function getAreaZoneRegistrationForm() {
                let eventId = null;
                if (typeof $('#event_id') !== 'undefined' && typeof $('#event_id') != false && typeof ($('#event_id').val() * 1) == 'number' && ($('#event_id').val() * 1) > 0) {
                    eventId = $('#event_id').val() * 1;
                }

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_event/get_area_zone",
                    data: {
                        event_id: eventId
                    }
                })
                    .done(function (data) {
                        if (data.html !== undefined && data.html !== null) {
                            $('#area_zone').remove();
                            $('#discount').after(data.html);
                            if ($('#recurring').prop("checked") != true && !$('.event-area-zone-date-wrapper').hasClass('d-none')) {
                                $('.event-area-zone-date-wrapper').addClass('d-none');
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    });

            }

        }

        $(document.body).on('click', ".event-area-zone", function () {
            if ($('#area').prop("checked") == true) {
                let max_tickets = 0;
                $('.event-area-zone').each(function () {
                    if ($(this).prop("checked") == true) {
                        max_tickets += $(this).attr('data-people-limit') * 1;
                    }
                });
                $('#max_tickets').val(max_tickets);
                addMaxTicketPerOrder();
            }
        });


    });