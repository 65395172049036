$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let locationId = $("body").attr("event_id");
  let status = $("body").attr("status");
  let url = "";
  let tableId = null;
  if (p === "table" || p === "organizer_settings") {
    $(document).on("submit", "#table-registration-form", function (e) {
      e.preventDefault();

      const formData = new FormData($(this)[0]);
      formData.append("location_id", locationId);
      url = "/ajax/table/new";
      saveTable(formData, url);
    });

    //update
    $(document).on("submit", "#table-update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("location_id", locationId);
      formData.append("status", status);
      url = "/ajax/table/update";
      saveTable(formData, url);
    });

    //delete
    $(document).on("click", ".table-delete-btn", function (e) {
      e.preventDefault();
      let idToDelete = $(this).attr("data-delete-id");
      $("#delete-table-confirm").attr("data-delete-id", idToDelete);
    });
    $(document).on("click", "#delete-table-confirm", function (e) {
      e.preventDefault();
      locationId = $(this).data("location-id");
      tableId = $(this).attr("data-delete-id");
      url = "/ajax/table/delete";
      const formData = new FormData();
      formData.append("status", 0);
      formData.append("table_id", tableId);
      formData.append("location_id", locationId);
      updateStatus(formData, url);
      $(".modal").modal("hide");
    });
    function saveTable(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#table-registration-form").hide();
            $("#table-update-form").hide();
            $("#message").show();
            $("html,body").scrollTop(0);

            setTimeout(function () {
              document.location.href = "/settings#table-setting";
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-registration-form"
            );
          }
        });
    }

    function updateStatus(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.alert) {
            alert(
              "Kunne ikke slette bord . Bord  ligger mest sannsynlig i en Vare"
            );

            return;
          }
          if (
            data.message !== undefined &&
            data.message !== null &&
            !data.alert
          ) {
            $("tr#table-" + tableId).fadeOut(1000);
            setTimeout(function () {
              $("tr#table-" + tableId).remove();
            }, 1000);
            $("#table-mobile-list-" + tableId).fadeOut(1000);
            setTimeout(function () {
              $("#table-mobile-list-" + tableId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#table-update-form"
            );
          }
        });
    }
  }
});
