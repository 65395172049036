$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_product_extra' && a === 'list') {

            $(document).on("click", ".product-extra-delete-button", function () {
                let productId = $(this).attr('data-product-id');
                let productExtraId = $(this).attr('data-product-extra-id');
                let locationId = $("body").attr("event_id");

                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_product_extra/delete",
                    data: {
                        product_id: productId,
                        product_extra_id: productExtraId,
                        location_id: locationId
                    }
                })
                    .done(function (data) {

                        if (data.html !== undefined && data.html !== null) {
                            $('#product-extra-list-sortable').empty();
                            $('#product-extra-list-sortable').append(data.html);
                            popupAlert();
                        }

                        if (data.extra_product_count == 0 && !$('#product-extra-wrapper').hasClass('d-none')) {
                            $('#product-extra-wrapper').addClass('d-none');
                        }

                        if (data.product !== undefined && data.product !== null && data.product_id !== undefined && data.product_id !== null) {
                            if (!$("select[name='extra_product_id'] option[value=" + data.product_id + "]").length) {
                                $("select[name='extra_product_id']").prepend($('<option>', {
                                    value: data.product_id,
                                    text: data.product
                                }));
                            }
                        }

                    })
                    .fail(function (data) {

                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }

                    })

            });

        }

    }
);