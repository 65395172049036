$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'organizer_location_statistic') {

            let order_pos_registration_ajax_lock = false;

            $(document).on("click", "#order-pos-registration-register-button", function () {

                if (!order_pos_registration_ajax_lock) {

                    let confirmText = $(this).attr("data-order-pos-registration-confirm-message");
                    if (!confirm(confirmText)) {
                        return false;
                    }

                    order_pos_registration_ajax_lock = true;

                    let regDate = $(this).attr('data-date-for-order-pos-registration');

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/order_pos_registration/new",
                        data: {
                            reg_date: regDate
                        }
                    })
                        .done(function (data) {

                            if (data === true){
                                popupAlert();
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#order-pos-registration-register-button', data.responseJSON.error_message, 'danger', null, '#statistic-filter-wrapper')
                            }

                            order_pos_registration_ajax_lock = false;

                        })
                        .always(function () {
                            order_pos_registration_ajax_lock = false;
                        });

                }

                return false;

            });

        }
    });