$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p === 'organizer_event_ticket_category' && (a === 'new' || a === 'update')) {

            let isPriceInclVat = false;
            let priceExVat = parseFloat($('#ticket_price_ex_vat').val());
            let vatPercent = parseInt($('#vat_code option:selected').text());
            if (!isNaN(priceExVat)) {
                calculatePriceInclVat(priceExVat, vatPercent);
            }

            $(document).on("change", "#ticket_price_ex_vat", function () {
                isPriceInclVat = false;
                let priceExVat = $(this).val().replace(",", ".");
                priceExVat = parseFloat(priceExVat);
                let vatPercent = parseInt($('#vat_code option:selected').text());
                calculatePriceInclVat(priceExVat, vatPercent);
                addAltPriceExVat(priceExVat);
            });

            $(document).on("change", "#ticket_price_incl_vat", function () {
                isPriceInclVat = true;
                let priceInclVat = $(this).val().replace(",", ".");
                priceInclVat = parseFloat(priceInclVat);
                let vatPercent = parseInt($('#vat_code option:selected').text());
                calculatePriceExVat(priceInclVat, vatPercent);
                addAltPriceInclVat(priceInclVat);
            });

            $(document).on("change", "#vat_code", function () {
                let vatPercent = parseInt($('#vat_code option:selected').text());
                if (isPriceInclVat) {
                    let priceInclVat = $('#ticket_price_incl_vat').val().replace(",", ".");
                    priceInclVat = parseFloat(priceInclVat);
                    calculatePriceExVat(priceInclVat, vatPercent);
                } else {
                    let priceExVat = $('#ticket_price_ex_vat').val().replace(",", ".");
                    priceExVat = parseFloat(priceExVat);
                    calculatePriceInclVat(priceExVat, vatPercent);
                }
            });

            function calculatePriceExVat(priceInclVat, vatPercent) {
                let priceExVat = priceInclVat / (1 + (vatPercent / 100));
                $('#ticket_price_ex_vat').val(priceExVat.toFixed(2));
            }

            function calculatePriceInclVat(priceExVat, vatPercent) {
                let priceInclVat = priceExVat * (1 + (vatPercent / 100));
                $('#ticket_price_incl_vat').val(priceInclVat.toFixed(2));
            }

            function addAltPriceExVat(priceExVat) {
                let altPriceExVat = parseFloat($('#alt_price_ex_vat').val());
                let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
                if (isNaN(altPriceExVat)) {
                    $('#alt_price_ex_vat').val(priceExVat.toFixed(2));
                    let altPriceInclVat = priceExVat * (1 + (altVatPercent / 100));
                    $('#alt_price_incl_vat').val(altPriceInclVat.toFixed(2));
                }
            }

            function addAltPriceInclVat(priceInclVat) {
                let altPriceInclVat = parseFloat($('#alt_price_incl_vat').val());
                let altVatPercent = parseInt($('#alt_vat_code option:selected').text());
                if (isNaN(altPriceInclVat)) {
                    $('#alt_price_incl_vat').val(priceInclVat.toFixed(2));
                    let altPriceExVat = priceInclVat / (1 + (altVatPercent / 100));
                    $('#alt_price_ex_vat').val(altPriceExVat.toFixed(2));
                }
            }

        }

    });