$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let location_id = $("body").attr("event_id");
  let url = "";
  let status = 1;

  if (p === "organizer_settings") {
    let order_by = "name";
    let order_by_direction = "asc";

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      let page = parseInt($(this).attr("href").substring(6));
      let search_string = $("#search_string").val();

      getPrinterList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        status
      );
    });

    $(document).on("click", "#printerSoft", function (e) {
      e.preventDefault();
      location_id = $("#delete-table-confirm").data("location-id");
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      getPrinterList(location_id, order_by, order_by_direction, status);
    });

    function getPrinterList(
      location_id = null,
      order_by,
      order_by_direction,
      status
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/printer/search",
        data: {
          location_id,
          order_by,
          order_by_direction,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#printer-list-view").empty();
            $("#printer-list-view").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
