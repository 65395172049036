$(document).ready(
    function () {

        let p = $("body").attr("p");

        if (p == 'events') {

            let windowInnerHeight = window.innerHeight;
            setInterval(function () {
                if (windowInnerHeight != window.innerHeight) {
                    windowInnerHeight = window.innerHeight;
                    $(window).trigger('resize');
                }
            }, 500);
        }
    }
);

