$(document).ready(
    function () {

        let p = $("body").attr("p");
        let a = $("body").attr("a");
        if (p == 'organizer_product_group' && a == 'list') {

            let product_group_modal_lock = false;

            $(document).on("click", "#product-group-new-button", function () {
                let locationId = $("body").attr("event_id");
                getProductGroupModal(locationId);
            });

            $(document).on("click", ".product-group-update-button", function () {
                let locationId = $("body").attr("event_id");
                let productGroupId = $(this).attr("data-product-group-id");
                getProductGroupModal(locationId, productGroupId);
            });

            function getProductGroupModal(locationId, productGroupId = null) {

                if ($('#product-group-registration-form-modal').length) {
                    $('#product-group-registration-form-modal').remove();
                }

                if (!product_group_modal_lock) {
                    product_group_modal_lock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_product_group/get_modal",
                        data: {
                            location_id: locationId,
                            product_group_id: productGroupId
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $('#content').append(data.html);
                                $('#product-group-registration-form-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            product_group_modal_lock = false;

                        })
                        .always(function () {
                            product_group_modal_lock = false;
                        });
                }
                return false;
            }



        }
    }
);