$(document).ready(function () {
  let p = $("body").attr("p");
  if (p === "events_self_service") {
    $(document).on("click", "#custom-pickup-time-button", function () {
      let location_id = $("#location_id").val();
      if (!$("#standard-pickup-time-wrapper").hasClass("d-none")) {
        $("#standard-pickup-time-wrapper").addClass("d-none");
      }
      if ($("#custom-pickup-time-wrapper").hasClass("d-none")) {
        $("#custom-pickup-time-wrapper").removeClass("d-none");
      }
      $("#custom_pickup_time").val(1);

      $.ajax({
        type: "POST",
        url: "/ajax/event/get_opening_hours_exception_dates",
        data: {
          location_id,
        },
      })
        .done(function (data) {
          if (
            data.opening_hours_exception_dates !== undefined &&
            data.opening_hours_exception_dates !== null
          ) {
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });

    $(document).on("click", "#standard-pickup-time-button", function () {
      $("#custom_pickup_time").val(0);
      if (!$("#custom-pickup-time-wrapper").hasClass("d-none")) {
        $("#custom-pickup-time-wrapper").addClass("d-none");
      }
      if ($("#standard-pickup-time-wrapper").hasClass("d-none")) {
        $("#standard-pickup-time-wrapper").removeClass("d-none");
      }
    });

    $(document).on("change", "#pickup_date", function () {
      let location_id = $("#location_id").val();
      let pickup_date = $(this).val();

      $.ajax({
        type: "POST",
        url: "/ajax/event/get_opening_time",
        data: {
          location_id,
          pickup_date,
        },
      })
        .done(function (data) {
          if (
            data.min !== undefined &&
            data.min !== null &&
            data.max !== undefined &&
            data.max !== null
          ) {
            $(".datepicker-time-pickup").prop("disabled", false);
            $(".datepicker-time-pickup").val(null);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }
});
