$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "organizer_product_category_self_service_link" && a === "list") {
    let product_category_link_register_ajax_lock = false;

    $(document).on(
      "click",
      "#product-category-link-register-button",
      function () {
        let productCategoryId = $(this).attr("data-product-category-id");
        let productId = $("select[name='product_id'] option:selected").val();
        let locationId = $("body").attr("event_id");

        if (!product_category_link_register_ajax_lock) {
          product_category_link_register_ajax_lock = true;

          $.ajax({
            type: "POST",
            url: "/ajax/organizer_product_category_self_service_link/new",
            data: {
              product_category_id: productCategoryId,
              product_id: productId,
              location_id: locationId,
            },
          })
            .done(function (data) {
              if (data.html !== undefined && data.html !== null) {
                $("select[name='product_id'] option:selected").remove();
                $("#product-category-link-list-sortable").empty();
                $("#product-category-link-list-sortable").append(data.html);
                popupAlert();
              }
            })
            .fail(function (data) {
              if (
                data.responseJSON.redirect_url !== undefined &&
                data.responseJSON.redirect_url !== null
              ) {
                document.location.href = data.responseJSON.redirect_url;
              }

              product_category_link_register_ajax_lock = false;
            })
            .always(function () {
              product_category_link_register_ajax_lock = false;
            });
        }
      }
    );
  }
});
