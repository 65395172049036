$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let location_id = $("body").attr("event_id");
  let status = 1;

  if (
    (p === "top_product_group" || p === "deactivated_top_product_group") &&
    a === "list"
  ) {
    let order_by = "name";
    let order_by_direction = "asc";

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      let page = parseInt($(this).attr("href").substring(6));
      let search_string = $("#search_string").val();
      if (p === "top_product_group") {
        status = 1;
      }
      if (p === "deactivated_top_product_group") {
        status = 2;
      }
      getSupplierList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        status
      );
    });

    $(document).on("click", ".sort", function (e) {
      e.preventDefault();

      let page = $("#top-product-group-table").attr("data-page");
      let search_string = $("#search_string").val();
      order_by = $(this).attr("data-order-by");
      order_by_direction = $(this).attr("data-order-by-direction");
      if (p === "top_product_group") {
        status = 1;
      }
      if (p === "deactivated_top_product_group") {
        status = 2;
      }
      getSupplierList(
        location_id,
        page,
        order_by,
        order_by_direction,
        search_string,
        status
      );
    });

    function getSupplierList(
      location_id = null,
      page = 1,
      order_by,
      order_by_direction,
      search_string = "",
      status
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/top_product_group/search",
        data: {
          location_id,
          page,
          order_by,
          order_by_direction,
          search_string,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#search-button").attr("data-search-string", search_string);
            $("#top-product-group-list-view").empty();
            $("#top-product-group-list-view").append(data.html);
          }

          $("html,body").scrollTop(0);
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
